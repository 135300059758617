<template>
  <div class="owner_container ownermain_body">
    <div class="owner_payment wrapper_owner_have_button">
      <h1 class="title_owner mb_medium">支払管理</h1>
      <h1 class="title_owner mb_small">
        クレジットカード情報入力完了
      </h1>
      <p class="note_sp" style="padding-bottom: 8px;">
        クレジットカード情報の入力が完了しました。
      </p>
      <p class="note_sp">クレジットカード決済は、PCI DSS（カード業界の情報セキュリティ基準）に準拠しているGMOイプシロン株式会社が当社に代行して行っております。
      またご登録いただくクレジットカード情報は、GMOイプシロン株式会社が提供する「fincode byGMO」のカード情報保持機能にてお預かりします。</p>
      <div class="btn_area_owner three_items owner_btn_padding btn_area_wrapper">
        <button
          @click="backToPayment"
          class="btn btn_owner primary_border ml_medium cursor-pointer"
        >
          支払管理画面へ
      </button>
      </div>
    </div>
  </div>
</template>

<script>
import { PaymentsService } from '../../../services/Owner/payments.service';
import { OwnersService } from '../../../services/Owner/owners.service';

export default {
  data() {
    return {
      slug: '',
      owner: {}
    }
  },
  async mounted() {
    await this.getOwners();
    await this.deleteCard();
  },
  methods: {
    async getOwners() {
      try {
        const { response } = await OwnersService.index();
        this.owner = response.data;
        if (this.owner.media_name != null) { this.slug = this.owner.media_name };
      } catch (error) {
        console.log(error);
      }
    },
    async deleteCard() {
      try {
        this.$loading(true);
        await PaymentsService.deleteCustomterCard();
        this.$loading(false);
      } catch (error) {
        this.$loading(false);
        console.log(error);
      }
    },
    backToPayment() {
      return this.$router.push({ path: `/${this.slug}/owner/payment`});
    }
  }
};
</script>

<style scoped>
a {
  cursor: pointer;
}
.owner_payment {
  margin: 40px;
}

#body_wrapper_owner .wrapper_owner_have_button {
  position: relative;
  height: calc(100% - 80px);
  padding-bottom: unset;
}

.btn_area_wrapper {
  position: absolute;
  bottom: 0px;
  left: 24px;
  right: 24px;
  width: auto;
}

@media screen and (max-width: 480px) {
  .owner_payment .btn_area_owner.three_items {
    margin: 0;
  }

  .btn_area_owner.three_items .btn {
    width: 90%;
  }

  .owner_payment {
    margin: 20px;
  }
}
</style>