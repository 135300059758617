<template>
<div class="ml-2 d-block text-center" style="font-size: 24px; line-height: 24px; min-width: 80px;">
<div v-if="displayWorkingTimes.length === 0" class="error">休業日</div>
<div v-else-if="displayWorkingTimes.length && displayWorkingTimes[0].isHoliday" class="error">休業日</div>
<div v-else v-for="(wt, i) in displayWorkingTimes" :key="i">
  {{ wt.start }}-{{ wt.end }}
</div>
</div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    workingTimes: {
      type: Array,
    },
  },
  computed: {
    displayWorkingTimes() {
      return this.workingTimes.map(wt => ({
        start: moment(wt.start_time).format('HH:mm'),
        end: moment(wt.end_time).format('HH:mm'),
        isHoliday: wt.is_holiday,
      }));
    },
  },
};
</script>