<template>
  <main class="owner_container">
    <div class="owner_main_area">
      <h1 class="owner_text_title">支払管理</h1>
      <p class="note_sp mt_medium">
        情報の変更後、反映にお時間がかかる場合がございます。ご注意ください。
      </p>
      <div class="input_owner">
        <label for="orderType" class="owner_form_label">お支払い方法<span class="require">必須</span></label>
          <div class="radio_position radio_position_change">
            <template v-for="paymentType in paymentTypes" v-key="payment_type.id">
              <label class="radio_btn_owner width_radio"
                     :class="owner.payment_type_id == typeCredit && paymentType.id != typeCredit ? 'disable-option' : ''">
                <input
                  v-model="paymentTypeId"
                  type="radio"
                  :checked="true"
                  :value="paymentType.id"
                  class="owner_padding_checkbox owner_border_input_radius"
                >
                <span class="checkmark_owner" />
                <label class="owner_padding_label owner_form_label">{{ paymentType.name }}</label>
              </label>
            </template>
          </div>
        <span
          v-for="error in errors['use_owner_info']"
          :key="error"
          class="error-message owner"
        >
          {{ error }}
        </span>
      </div>
      <div class="input_owner" v-if="owner.payment_type_id == 1 && !payment.flg_card_create">
        <label for="orderType" class="owner_form_label">クレジットカード番号</label>
        <div class="owner_margin_inline_10 owner_line_down owner_color_black" v-for="card in payment.list_cards" :key="card.id">
          <p>{{ card.card_no }}</p>
        </div>
      </div>
      <div class="input_owner" v-if="owner.payment_type_id == 1 && !payment.flg_card_create">
        <label for="orderType" class="owner_form_label">クレジットカードブランド</label>
        <div class="owner_margin_inline_10 owner_line_down owner_color_black"  v-for="card in payment.list_cards" :key="card.id">
          {{ card.brand }}
        </div>
      </div>
      <div class="input_owner" v-if="owner.payment_type_id == 1 && !payment.flg_card_create">
        <label for="orderType" class="owner_form_label">クレジットカード　有効期限</label>
        <div class="owner_margin_inline_10 owner_line_down owner_color_black"  v-for="card in payment.list_cards" :key="card.id">
          {{ convertStringToDate(card.expire) }}
        </div>
      </div>
      <div class="input_owner" v-if="owner.payment_type_id == 1" >
        <a :href="payment.link_url" target="_blank">
          {{ !payment.flg_card_create ? 'クレジットカードの情報を変更する' : 'クレジットカードの情報を入力する' }}
        </a>
      </div>
      <div v-if="paymentTypeId == typeCredit" id="description-payment" class="note_sp padding-top-6px">
        <p class="owner_text_note">クレジットカード決済は、PCI DSS（カード業界の情報セキュリティ基準）に準拠しているGMOイプシロン株式会社が当社に代行して行っております。
        またご登録いただくクレジットカード情報は、GMOイプシロン株式会社が提供する「fincode byGMO」のカード情報保持機能にてお預かりします。</p>
      </div>
      <div v-else-if="paymentTypeId == '3'">
        <p class="owner_text_note form_text_note_margin">口座振替適用までは、口座振替依頼書受け付け後から約2か月程お時間が掛かります。</p>
        <p class="owner_text_note form_text_note_margin">口座振替適用までの間は、請求書払いにてお支払いをお願いいたします。</p>
      </div>
      <div class="input_owner" v-if="owner.payment_type_id == typeCredit && (fincodeOwnerSubscriptionsPresent.length > 0 ||
                                                                                 fincodeOwnerSubscriptionsFuture.length > 0)">
        <label for="orderType" class="owner_form_label">ご契約中のプラン</label>
        <div v-for="fincodeOwnerSubscription in fincodeOwnerSubscriptionsPresent" :key="fincodeOwnerSubscription.id">
          <div class="owner_margin_inline_10 owner_line_down owner_color_black">
            {{ fincodeOwnerSubscription.plan_name }}
          </div>
          <div class="owner_margin_inline_10 owner_line_down owner_color_black">
            ご請求開始月： {{ formatDateJapan(fincodeOwnerSubscription.start_date) }}
          </div>
          <div class="owner_margin_inline_10 owner_line_down owner_color_black">
            ご請求額： {{ fincodeOwnerSubscription.amount }}円
          </div>
        </div>
      </div>
      <div class="input_owner" v-if="owner.payment_type_id == typeCredit && (fincodeOwnerSubscriptionsPresent.length > 0 ||
                                                                              fincodeOwnerSubscriptionsFuture.length > 0)">
        <label for="orderType" class="owner_form_label">変更申請中のプラン</label>
        <div v-for="fincodeOwnerSubscription in fincodeOwnerSubscriptionsFuture" :key="fincodeOwnerSubscription.id">
          <div class="owner_margin_inline_10 owner_line_down owner_color_black">
            {{ fincodeOwnerSubscription.plan_name }}
          </div>
          <div class="owner_margin_inline_10 owner_line_down owner_color_black">
            ご請求開始月： {{ formatDateJapan(fincodeOwnerSubscription.start_date) }}
          </div>
          <div class="owner_margin_inline_10 owner_line_down owner_color_black">
            ご請求額： {{ fincodeOwnerSubscription.amount }}円
          </div>
        </div>
      </div>
      <div class="input_owner" v-if="owner.payment_type_id == typeCredit && (fincodeOwnerSubscriptionsPresent.length == 0 &&
                                                                              fincodeOwnerSubscriptionsFuture.length == 0) && owner.payment_type_id == 1 && !payment.flg_card_create">
        <label for="orderType" class="owner_form_label">ご契約のプラン</label>
        <div class="owner_margin_inline_10 owner_line_down owner_color_black">
          契約プラン情報を取得しています・・・
        </div>
      </div>
      <div>
        <div class="input_owner" v-if="paymentTypeId != typeCredit">
          <label for="invoice_send_type" class="owner_form_label">請求書受け取り方法</label>
          <div class="radio_position">
            <label class="radio_btn_owner">
              <input
                v-model="invoiceSendType"
                type="radio"
                :checked="true"
                value="0"
                class="owner_padding_checkbox owner_border_input_radius"
              >
              <span class="checkmark_owner" />
              <label class="owner_padding_label owner_form_label">メール</label>
            </label>
            <label class="radio_btn_owner">
              <input
                v-model="invoiceSendType"
                type="radio"
                value="1"
                class="owner_padding_checkbox owner_border_input_radius"
              >
              <span class="checkmark_owner" />
              <label class="owner_padding_label owner_form_label">郵送</label>
            </label>
          </div>
          <span
            v-for="error in errors['invoice_send_type']"
            :key="error"
            class="error-message owner"
          >
            {{ error }}
          </span>
        </div>

        <div class="input_owner">
          <label for="orderType" class="owner_form_label">請求書受け取り先情報<span class="require">必須</span></label>
          <div class="radio_position radio_position_change">
            <label class="radio_btn_owner width_radio">
              <input
                v-model="useOwnerInfo"
                type="radio"
                :checked="true"
                value="0"
                class="owner_padding_checkbox owner_border_input_radius"
                @click="setFromInputPayment"
              >
              <span class="checkmark_owner" />
              <label class="owner_padding_label owner_form_label">法人情報を使用する</label>
            </label>
            <label class="radio_btn_owner width_radio">
              <input
                v-model="useOwnerInfo"
                type="radio"
                value="1"
                class="owner_padding_checkbox owner_border_input_radius"
                @click="cleanFromInputPayment"
              >
              <span class="checkmark_owner" />
              <label class="owner_padding_label owner_form_label">別の情報を設定する</label>
            </label>
          </div>
          <span
            v-for="error in errors['use_owner_info']"
            :key="error"
            class="error-message owner"
          >
            {{ error }}
          </span>
        </div>

        <div>
          <div class="input_owner width_input">
            <label for="name" class="owner_form_label color_owner_text">
              {{ paymentTypeId === 2 ? '請求書受け取り先名（会社名）' : '法人名' }}
              <span v-if="!useCorporateInfo()" class="require">必須</span>
            </label>
            <input
              v-model="name"
              type="text"
              placeholder="請求書受け取り先名（会社名）を入力"
              class="owner_display_text"
              :class="useCorporateInfo() ? 'owner_disable_input' : ''"
            >
            <span
              v-for="error in errors['name']"
              :key="error"
              class="error-message owner"
            >
              {{ error }}
            </span>
          </div>

          <div class="input_owner">
            <label for="staffName" class="owner_form_label color_owner_text">
              担当者名
              <span v-if="!useCorporateInfo()" class="require">必須</span>
            </label>
            <input
              v-model="staffName"
              type="text"
              placeholder="担当者名を入力"
              class="owner_display_text"
              :class="useCorporateInfo() ? 'owner_disable_input' : ''"
            >
            <span
              v-for="error in errors['staffName']"
              :key="error"
              class="error-message owner"
            >
              {{ error }}
            </span>
          </div>

          <div class="input_owner">
            <label for="staffNameKana" class="owner_form_label color_owner_text">
              担当者名（カナ）
              <span v-if="!useCorporateInfo()" class="require">必須</span>
            </label>
            <input
              v-model="staffNameKana"
              type="text"
              placeholder="担当者名（カナ）を入力"
              class="owner_display_text"
              :class="useCorporateInfo() ? 'owner_disable_input' : ''"
            >
            <span
              v-for="error in errors['staffNameKana']"
              :key="error"
              class="error-message owner"
            >
              {{ error }}
            </span>
          </div>

          <div class="input_owner">
            <label for="departmentName" class="owner_form_label color_owner_text">
              部署名
            </label>
            <input
              v-model="departmentName"
              type="text"
              :placeholder="!useCorporateInfo() ? '部署名を入力' : ''"
              class="owner_display_text"
              :class="useCorporateInfo() ? 'owner_disable_input' : ''"
            >
            <span
              v-for="error in errors['departmentName']"
              :key="error"
              class="error-message owner"
            >
              {{ error }}
            </span>
          </div>

          <div class="input_owner width_input">
            <label for="postcode" class="owner_form_label color_owner_text">
              郵便番号
              <span v-if="!useCorporateInfo()" class="require">必須</span>
            </label>
            <input
              v-model="postcode"
              type="text"
              placeholder="郵便番号（ハイフン不要、半角のみ）を入力"
              class="owner_display_text"
              :class="useCorporateInfo() ? 'owner_disable_input' : ''"
            >
            <span
              v-for="error in errors['postcode']"
              :key="error"
              class="error-message owner"
            >
              {{ error }}
            </span>
          </div>

          <div class="input_owner">
            <label for="prefectureId" class="owner_form_label color_owner_text">
              都道府県
              <span v-if="!useCorporateInfo()" class="require">必須</span>
            </label>
            <div class="owner_select_wrapper arrow_down medium select_boder">
              <select
                v-model="prefectureId"
                class="select_box_padding owner_select_shevron_down_none"
                :class="useCorporateInfo() ? 'owner_disable_input' : ''"
              >
                <option
                  v-for="(prefecture, index) in prefectures"
                  :key="index"
                  :value="prefecture.code"
                >
                  {{ prefecture.name }}
                </option>
              </select>
            </div>
            <span
              v-for="error in errors['prefectureId']"
              :key="error"
              class="error-message owner"
            >
              {{ error }}
            </span>
          </div>

          <div class="input_owner">
            <label for="city" class="owner_form_label color_owner_text">
              市区町村
              <span v-if="!useCorporateInfo()" class="require">必須</span>
            </label>
            <input
              v-model="city"
              type="text"
              placeholder="例：港区北青山"
              class="owner_display_text"
              :class="useCorporateInfo() ? 'owner_disable_input' : ''"
            >
            <span
              v-for="error in errors['city']"
              :key="error"
              class="error-message owner"
            >
              {{ error }}
            </span>
          </div>
          <div class="input_owner">
            <label for="address" class="owner_form_label color_owner_text">
              丁目・番地・号
              <span v-if="!useCorporateInfo()" class="require">必須</span>
            </label>
            <input
              v-model="address"
              type="text"
              placeholder="例：2-14-4"
              class="owner_display_text"
              :class="useCorporateInfo() ? 'owner_disable_input' : ''"
            >
            <span
              v-for="error in errors['address']"
              :key="error"
              class="error-message owner"
            >
              {{ error }}
            </span>
          </div>
          <div class="input_owner">
            <label for="buildingName" class="owner_form_label color_owner_text">ビル・マンション</label>
            <input
              v-if="!useCorporateInfo()"
              v-model="buildingName"
              type="text"
              placeholder="ビル・マンションを入力"
              class="owner_display_text"
              :class="useCorporateInfo() ? 'owner_disable_input' : ''"
            >
            <input
              v-else
              v-model="buildingName"
              type="text"
              placeholder=""
              class="owner_display_text"
              :class="useCorporateInfo() ? 'owner_disable_input' : ''"
            >
            <span
              v-for="error in errors['buildingName']"
              :key="error"
              class="error-message owner"
            >
              {{ error }}
            </span>
          </div>
          <div class="input_owner">
            <label for="email" class="owner_form_label color_owner_text">連絡先メールアドレス<span
              v-if="!useCorporateInfo()"
              class="require"
            >必須</span></label>
            <input
              v-model="email"
              type="text"
              placeholder="連絡先メールアドレスを入力"
              class="owner_display_text"
              :class="useCorporateInfo() ? 'owner_disable_input' : ''"
            >
            <span
              v-for="error in errors['email']"
              :key="error"
              class="error-message owner"
            >
              {{ error }}
            </span>
          </div>

          <div class="input_owner width_input">
            <label for="phone" class="owner_form_label color_owner_text">
              電話番号
              <span v-if="!useCorporateInfo()" class="require">必須</span>
            </label>
            <input
              v-model="phone"
              type="text"
              placeholder="電話番号（ハイフン不要、半角のみ）を入力"
              class="owner_display_text"
              :class="useCorporateInfo() ? 'owner_disable_input' : ''"
            >
            <span
              v-for="error in errors['phone']"
              :key="error"
              class="error-message owner"
            >
              {{ error }}
            </span>
          </div>
        </div>
      </div>

      <div class="btn_area_owner control two_items owner_btn_padding">
        <router-link
          :to="{}"
          class="btn_owner primary"
          @click.native="confirmUpdatePayment"
        >更新する</router-link>
        <router-link
          :to="{}"
          class="btn_owner primary_border ml_medium"
          @click.native="goBack()"
        >キャンセル</router-link>
      </div>
    </div>
  </main>
</template>
<script>
import Validator from '../../../validator';
import { PaymentsService } from '../../../services/Owner/payments.service';
import { mapGetters } from 'vuex';
import { AddressInfo, PopupDisplayError } from '../../../common/util';
import utils from '../../../common/util';
import { TYPE_CREDIT } from '../../../common/variables';
import moment from 'moment';

const IS_USE_OWNER_INFO = '0';

export default {
  data() {
    return {
      payment: {},
      errors: {},
      owner: {},
      oldPayment: {},
      invoiceSendType: null,
      useOwnerInfo: 0,
      name: null,
      postcode: null,
      prefectureId: null,
      city: null,
      address: null,
      buildingName: null,
      email: null,
      phone: null,
      staffName: null,
      staffNameKana: null,
      departmentName: null,
      paymentTypeName: null,
      isFirstLoadPage: true,
      currenrUseOwnerInfo: null,
      slug: '',
      paymentTypes: [],
      paymentTypeId: null,
      typeCredit: TYPE_CREDIT,
      fincodeOwnerSubscriptionsFuture: [],
      fincodeOwnerSubscriptionsPresent: []
    };
  },
  computed: {
    ...mapGetters({
      prefectures: 'getAllPrefectures',
    }),
  },
  watch: {
    useOwnerInfo: function () {
      this.isFirstLoadPage = false;
    },
    payment: function (newVal) {
      this.paymentTypeName = newVal.payment_type;
      this.invoiceSendType = newVal.invoice_send_type;
      if (newVal.use_owner_info === false) {
        this.useOwnerInfo = '0';
      } else {
        this.useOwnerInfo = '1';
      }
      this.name = newVal.name;
      this.postcode = newVal.postcode;
      this.prefectureId = newVal.prefecture_id;
      this.city = newVal.city;
      this.address = newVal.address;
      this.buildingName = newVal.building_name;
      this.email = newVal.email;
      this.phone = newVal.phone;
      this.staffName = newVal.staff_name;
      this.staffNameKana = newVal.staff_name_kana;
      this.departmentName = newVal.department_name;
      this.oldPayment = newVal;
    },

    postcode(val) {
      if (val === this.payment.postcode) {
        this.prefectureId = this.payment.prefecture_id;
        this.city = this.payment.city;
        this.address = this.payment.address;
      } else if (val === this.owner.postcode) {
        this.prefectureId = this.owner.prefecture_id;
        this.city = this.owner.city;
        this.address = this.owner.address;
      } else {
        AddressInfo(val, this.prefectures).then((address) => {
          this.prefectureId = address.code;
          this.city = address.city + address.address;
        });
      }
    },
  },
  async mounted() {
    this.getInfoPayment();
    this.getPaymentTypes();
  },
  methods: {
    ...utils,

    setFromInputPayment() {
      this.paymentTypeName = this.oldPayment.payment_type;
      // this.invoiceSendType = this.oldPayment.invoice_send_type;
      this.useOwnerInfo = 0;
      this.name = this.owner.name;
      this.postcode = this.owner.postcode;
      this.prefectureId = this.owner.prefecture_id;
      this.city = this.owner.city;
      this.address = this.owner.address;
      this.buildingName = this.owner.building_name;
      this.email = this.owner.email;
      this.phone = this.owner.phone;
      this.staffName = this.owner.staff_name;
      this.staffNameKana = this.owner.staff_name_kana;
      this.departmentName = this.owner.department_name;
    },
    confirmUpdatePayment: async function () {
      this.validate();
      if (Object.keys(this.errors).length) {
        this.scrollTopScreen();
        PopupDisplayError(this.$swal);
        return;
      }

      this.popupconfirmUpdatePayment();
    },
    async popupconfirmUpdatePayment() {
      const confirm = await this.$swal.fire({
        title: '確認',
        html: `<p>支払情報を変更します。</p>
               <p>よろしいですか？</p>`,
        confirmButtonText: '決定する',
        cancelButtonText: 'キャンセル',
        showCloseButton: false,
        showCancelButton: true,
        customClass: {
          confirmButton: 'confirm-popup-owner',
          title: 'title-popup-owner',
          cancelButton: 'cancel-popup-owner',
          htmlContainer: 'html-popup-owner',
        },
      });

      if (confirm.isConfirmed) {
        try {
          const params = {
            payment_type_id: this.paymentTypeId,
            invoice_send_type: this.invoiceSendType,
            use_owner_info: this.useOwnerInfo,
            name: this.name,
            postcode: this.postcode,
            prefecture_id: this.prefectureId,
            city: this.city,
            address: this.address,
            building_name: this.buildingName,
            phone: this.phone,
            email: this.email,
            staff_name: this.staffName,
            staff_name_kana: this.staffNameKana,
            department_name: this.departmentName,
          };
          await PaymentsService.update(params);
          return this.$router.push(`/${this.slug}/owner/payment`);
        } catch (error) {
          console.log(error);
          this.scrollTopScreen();
          PopupDisplayError(this.$swal);
        }
      }
    },
    useCorporateInfo() {
      return this.useOwnerInfo == IS_USE_OWNER_INFO;
    },
    cleanFromInputPayment() {
      if (!this.isFirstLoadPage && this.currenrUseOwnerInfo === false) {
        this.name = null;
        this.postcode = null;
        this.prefectureId = null;
        this.city = null;
        this.address = null;
        this.buildingName = null;
        this.email = null;
        this.phone = null;
        this.staffName = null;
        this.staffNameKana = null;
        this.departmentName = null;
      } else if(this.currenrUseOwnerInfo === true) {
        this.name = this.payment.name;
        this.postcode = this.payment.postcode;
        this.prefectureId = this.payment.prefecture_id;
        this.city = this.payment.city;
        this.address = this.payment.address;
        this.buildingName = this.payment.building_name;
        this.email = this.payment.email;
        this.phone = this.payment.phone;
        this.staffName = this.payment.staff_name;
        this.staffNameKana = this.payment.staff_name_kana;
        this.departmentName = this.payment.department_name;
      }
    },
    async getInfoPayment() {
      try {
        this.$loading(true);
        const { response } = await PaymentsService.index();
        this.payment = response.data;
        this.owner = response.owner;
        this.paymentTypeId = response.owner.payment_type_id;
        this.currenrUseOwnerInfo = response.data.use_owner_info;
        if (this.owner.media_name != null) { this.slug = this.owner.media_name };
        if (this.paymentTypeId == this.typeCredit) {
          this.fincodeOwnerSubscriptionsFuture = response.fincode_owner_subscriptions_future
          this.fincodeOwnerSubscriptionsPresent = response.fincode_owner_subscriptions_present
        }
        this.$loading(false);
      } catch (error) {
        this.$loading(false);
        console.log(error);
      }
    },
    async getPaymentTypes() {
      try {
        const { response } = await PaymentsService.paymentTypes();
        this.paymentTypes = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    scrollTopScreen() {
      window.scrollTo(0, 0);
      const elmnt = document.getElementById('body_wrapper_owner');
      elmnt.scrollTo(0, 0);
    },
    validate() {
      const validator = new Validator();

      validator.checkRequire('name', this.name, '請求書受け取り先名（会社名）') &&
        validator.checkMaxLength('name', this.name, 255, '請求書受け取り先名（会社名）');

      validator.checkRequire('postcode', this.postcode, '郵便番号') &&
        validator.checkMaxLength('postcode', this.postcode, 7, '郵便番号') &&
        validator.checkPostCode('postcode', this.postcode, '郵便番号');

      validator.checkRequire('prefecture_id', this.prefectureId, '都道府県');

      validator.checkRequire('city', this.city, '市区町村') &&
        validator.checkMaxLength('city', this.city, 255, '市区町村');

      validator.checkRequire('address', this.address, '丁目・番地・号') &&
        validator.checkMaxLength('address', this.address, 255, '丁目・番地・号');

      validator.checkRequire('prefectureId', this.prefectureId, '都道府県');

      validator.checkMaxLength('buildingName', this.buildingName, 255, 'ビル・マンション');

      validator.checkRequire('staffName', this.staffName, '担当者名') &&
        validator.checkMaxLength('staffName', this.staffName, 255, '担当者名');

      validator.checkRequire('staffNameKana', this.staffNameKana, '担当者名（カナ）') &&
        validator.checkKatakana( 'staffNameKana', this.staffNameKana, '担当者名（カナ）') &&
        validator.checkMaxLength('staffNameKana', this.staffNameKana, 255, '担当者名（カナ）');

      validator.checkMaxLength('departmentName', this.departmentName, 255, '部署名');

      validator.checkRequire('email', this.email, '連絡先メールアドレス') &&
        validator.checkEmail('email', this.email, '連絡メールアドレス');

      validator.checkRequire('phone', this.phone, '電話番号');
      if (this.phone) {
        const phone = this.phone;
        validator.checkTel('phone', phone, '電話番号') &&
          validator.checkMinLength('phone', phone, 10, '電話番号') &&
          validator.checkMaxLength('phone', phone, 11, '電話番号');
        if (!validator.errors.phone) {
          validator.checkPhone('phone', this.phone, '電話番号');
        }
      }
      this.errors = validator.errors;
    },
    goBack() {
      return this.$router.push(`/${this.slug}/owner/payment`);
    },
    convertStringToDate(str) {
      return moment(str, "YYMM").format("YYYY/MM");
    }
  },
};
</script>
<style lang="scss" scoped>
.btn_area_owner.control {
  width: 95%;
}
.radio_btn_owner {
  padding-left: 0;
}
.owner_display_text {
  overflow: hidden;
  white-space: pre-line;
  text-overflow: ellipsis;
}
.checkmark_owner {
  height: 21px;
  width: 21px;
}
.note-credit {
  font-size: 16px;
}

.disable-option {
  pointer-events: none;
  color: #AAA;
  .checkmark_owner {
    pointer-events: none;
    color: #AAA;
    background: #AAA;
    border: 2px solid #817676;
  }
}
.padding-top-6px {
  padding-top: 6px;
}
@media screen and (max-width: 480px) {
  .radio_position_change {
    display: block;
  }
}
</style>