<template>
  <div class="modal fade show" style="display: block;">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header header_modal_icon" style="background-color: var(--sub-color);">
          <h5 class="modal-title" style="color: white;">ウォークイン登録</h5>
          <img :src="cross" @click="onClose" class="cross cursor-pointer" alt=""/>
        </div>
        <div class="modal-body">
          <div class="col-12">
            <label class="people-text modal-people-text">
              予約人数<span class="require">必須</span>
            </label>
            <div class="input-label-format text-center">{{combinedPeople}}</div>
          </div>
          <div class="people-component-wrapper">
            <div class="people-input-wrapper">
              <div class="mr-3">
                <label class="people-text">大人</label>
                <input type="text" readonly class="form-control input-field" :class="adult_focus?'input-focus':''" @focus="handleAdultFocus" :value="reservation.number_of_adults" />              </div>
              <div>
                <label class="people-text">子供</label>
                <input type="text" readonly class="form-control input-field" :class="children_focus?'input-focus':''" @focus="handleChildrenFocus" :value="reservation.number_of_children" />
              </div>
            </div>
            <div>
              <Numpad @press="selectNumber" />
            </div>
          </div>
          <div v-if="error" class="alert alert-danger mt-3">{{ error }}</div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary walk-btn" @click="handleSubmit">確認</button>
        </div>
      </div>
    </div>
    <div class="modal-backdrop show"></div>
  </div>
</template>

<script>
import cross from "../../../../assets/images/white-cross.svg";
import Numpad from "../../src/components/NumPad.vue";
import Http from "../../shared/http";
import stores from "../../src/stores";

export default {
  components: { Numpad },
  computed: {
    combinedPeople() {
      const adults = this.reservation.number_of_adults ? `大人: ${this.reservation.number_of_adults}人` : '';
      const children = this.reservation.number_of_children ? `子供: ${this.reservation.number_of_children}人` : '';
      return [adults, children].filter(Boolean).join('    ');
    }
  },
  props: {
    reservation: {
      type: Object
    },
  },
  data() {
    return {
      cross: cross,
      children_focus: false,
      adult_focus: true,
      error: ''
    };
  },
  methods: {
    onClose() {
      this.$emit("close");
    },
    selectNumber(num) {
      if (!isNaN(num)) {
        if (this.children_focus) {
          if(this.reservation.number_of_children.length<=4){
            this.$emit('update-children', ((this.reservation.number_of_children === '' ? 0 : this.reservation.number_of_children) + Number(num)));
          }
        } else if (this.adult_focus) {
          if (this.reservation.number_of_adults.length <= 4) {
            this.$emit('update-adults', ((this.reservation.number_of_adults === '' ? 0 : this.reservation.number_of_adults) + Number(num)));
          }
        }
      } else {
        if (num.replace(/\s/g, '') === "Del") {
          let no_of_adults = this.reservation.number_of_adults;
          let no_of_children = this.reservation.number_of_children;
          if (this.adult_focus) {
            no_of_adults = no_of_adults.slice(0, -1);
            if (no_of_adults.length === 0) {
              no_of_adults = '';
            }
            this.$emit('update-adults', no_of_adults);
          } else if (this.children_focus) {
            no_of_children = no_of_children.slice(0, -1);
            if (no_of_children.length === 0) {
              no_of_children = '';
            }
            this.$emit('update-children', no_of_children);
          }
        }
      }
    },
    handleAdultFocus() {
      this.adult_focus = true;
      this.children_focus = false;
    },
    handleChildrenFocus() {
      this.children_focus = true;
      this.adult_focus = false;
    },
    formatErrorMessages(errors) {
      let formattedErrors = '';
      for (const [key, value] of Object.entries(errors)) {
        formattedErrors += `${value.join(', ')} `;
      }
      return formattedErrors.trim();
    },
    async handleSubmit() {
      const data = this.reservation;
      const number_of_people = Number(data.number_of_adults !== '' ? data.number_of_adults : 0) + Number(data.number_of_children !== '' ? data.number_of_children : 0);

      // Validation check
      if (data.number_of_adults === '' && data.number_of_children === '') {
        this.error = "大人または子供の人数を入力してください。";
        return;
      } else {
        this.error = '';
      }

      const _data = {
        reservation: {
          is_course: 0,
          shop_table_ids: data.shop_table_ids,
          start_time: data.start_time,
          stay_minutes: data.stay_minutes,
          number_of_people: number_of_people,
          number_of_adults: data.number_of_adults !== '' ? data.number_of_adults : 0,
          number_of_children: data.number_of_children !== '' ? data.number_of_children : 0,
          reservation_type: "walkin",
          first_name: "",
          last_name: "ウォークイン",
          state: "seating",
          course_ids: []
        },
        update_user_data: false,
        customer_id: null,
        send_mail: false,
        uuid: data.uuid
      };
      const slug = document.getElementById('slug').value == '' ? '' : `/${document.getElementById('slug').value}`;
      const path = `${slug}/shop_manager/time_table/`;

      try {
        await Http.post(path, _data);
        this.$emit('reload');
        this.$emit('close');
      } catch (error) {
        this.error = this.formatErrorMessages(error.response.data);
      }
    }
  }
};
</script>
<style scoped>
.walk-btn {
  width: 180px;
  font-size: 16px;
  font-weight: 500;
}
.modal-people-text {
  font-size: 16px;
  font-weight: 700;
}
.modal-body {
  padding: 1rem 2rem 0 2rem;
}
.modal-dialog {
  z-index: 2007;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: auto;
  min-height: calc(100dvh - 100px);
}
.modal.backdrop {
  z-index: 2006;
}
.modal {
  z-index: 2006;
}
.message-wrapper {
  width: 100%;
  text-align: left;
  padding-top: 16px;
}
.cross {
  width: 20px;
  color: white;
  margin-left: auto;
}
.modal-header {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  justify-content: center;
}
.modal-content {
  min-height: 80dvh;
  border-radius: 12px;
}
.modal-title {
  margin-left: auto;
}
@media only screen and (min-device-width: 1366px) {
  .modal-dialog {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: auto;
    margin: 0.2rem auto;
    min-height: calc(100dvh - 100px);
  }
.modal-body{
  padding: 1rem 2rem 0 2rem;
}

  .modal-content {
    min-height: 80dvh;
  }
  .error-message {
    font-size: 18px;
  }
}
.people-component-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin: 1rem;
}
.people-input-wrapper {
  display: flex;
}
.people-text {
  color: var(--color-main);
}
.input-focus{
  border: 2px solid var(--color-main) !important;
}
input[readonly] {
  background-color: #FFFFFF;
  border: 1px solid #B4B4B4;
  border-radius: 5px;
  height: 45px;
  padding-left: 3%;
  color: var(--color-main);
  line-height: 24px;
  cursor: pointer;
}
.input-label-format {
  height:30px;
  font-size: 1.5rem;
}
</style>
