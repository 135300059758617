var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sidebar"},[_c('div',{staticClass:"sp_menu"},[_c('div',{staticClass:"logo_owner",class:[_vm.slug ? `brand_${_vm.slug}` : '']}),_vm._v(" "),_c('div',{staticClass:"sp_menu_trigger"})]),_vm._v(" "),_c('div',{staticClass:"sidebar sidebar-owner",class:[
        _vm.openSidebar ? 'sidebar-owner-open' : '',
        _vm.hideSidebar ? 'sidebar-owner-hide' : '',
      ]},[_c('ul',[_c('li',{staticClass:"owner_menu_content",on:{"click":_vm.handleSidebar}},[_c('router-link',{class:_vm.$route.path.includes('/owner/shop') ||
                _vm.$route.name === 'restaurant_dashboard' ||
                _vm.$route.name == 'owner_detail' ||
                _vm.$route.name == 'owner_delete' ||
                _vm.$route.name == 'owner_edit' ||
                _vm.$route.name == 'complete_delete_owner'
                ? 'router-link-exact-active'
                : '',attrs:{"to":_vm.convertLink('/owner')}},[_c('span',{staticClass:"text"},[_vm._v("アカウント管理")])])],1),_vm._v(" "),(_vm.owner.permission==='HAVE_FULL_ACCESS')?_c('li',{on:{"click":_vm.gobackToSNSB}},[_c('router-link',{attrs:{"to":"#"}},[_c('span',{staticClass:"text"},[_vm._v("SNS Booster")])])],1):_vm._e(),_vm._v(" "),_c('li',{on:{"click":_vm.logoutMember}},[_c('router-link',{attrs:{"id":"logout","to":"#"}},[_c('span',{staticClass:"text"},[_vm._v("ログアウト")])])],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }