const getDefaultState = () => {
    return {
        createForm: {
            uuid: null,
            reservation_type: 'reservation', // 'reservation' or 'walkin'
            shop_tags: [],
            shop_courses:[],
            showReservationMethod:false,
            showValidationMessage:false,
            showCloseValidationModal: false,
            validErrorMessages: {},
            current_step:1,
            completed_step:0,
            data:{
                date: null,
                timeTableStart:{},
                timeTableEnd:{},
                timeTable:[],
                number_of_children:'',
                number_of_adults:'',
                hours:'',
                set_stay_minutes: 120,
                shop_stay_minutes: 120,
                minutes:'',
                tags:[],
                note:'',
                stay_minutes:120,
                course_ids: "",
                hourList: Array.from({ length: 24 }, (_, i) => i), // Hours from 0 to 23
                minutesList: Array.from({ length: 4 }, (_, i) => i * 15),
                selected_seats:[],
                workingTimes:[],
                selected_date:null,
                actualWorkingTimes:[],
                first_name:'',
                last_name:'',
                first_name_kana:'',
                last_name_kana:'',
                customer_id:null,
                company_name:'',
                tel:'',
                staff:'',
                reservation_type:'phone'
            }
        },
    };
};

const active_reservation = {
    state: {
        createForm: {
            uuid: null,
            reservation_type: 'reservation',
            shop_tags: [],
            shop_courses:[],
            showReservationMethod:false,
            showValidationMessage:false,
            showCloseValidationModal: false,
            validErrorMessages: {},
            current_step:1,
            completed_step:0,
            data:{
                date: null,
                timeTableStart:{},
                timeTableEnd:{},
                timeTable:[],
                fullTimeTable:[],
                number_of_children:'',
                number_of_adults:'',
                hours:'',
                minutes:'',
                tags:[],
                selected_date:null,
                note:'',
                stay_minutes:120,
                course_ids: '',
                selected_seats:[],
                workingTimes:[],
                actualWorkingTimes:[],
                first_name:'',
                last_name:'',
                hourList: Array.from({ length: 24 }, (_, i) => i), // Hours from 0 to 23
                minutesList: Array.from({ length: 4 }, (_, i) => i * 15),
                first_name_kana:'',
                last_name_kana:'',
                customer_id:null,
                company_name:'',
                tel:'',
                staff:'',
                reservation_type:'phone'
            }
        },
    },
    mutations: {
        SET_UUID(state, uuid) {
            state.createForm.uuid = uuid;
        },
        SET_RESERVATION_TYPE(state, type) {
          state.createForm.reservation_type = type;
        },
        SET_SHOW_VALIDATION(state, status) {
            state.createForm.showValidationMessage = status;
        },
        SET_SHOW_CLOSE_VALIDATION_MODAL(state, status) {
            state.createForm.showCloseValidationModal = status;
        },
        SET_STEP(state, number) {
            state.createForm.current_step = number;
        },
        SET_COMPlETED_STEP(state, number){
            state.createForm.completed_step = number;
        },
        SET_SHOP_COURSES(state,courses){
          state.createForm.shop_courses=courses
        },
        SET_SHOW_RESERVATION_MODAL(state,status){
          state.createForm.showReservationMethod = status
        },
        SET_DATA(state, data) {
          for (let key in data) {
            state.createForm.data[key] = data[key];
          }
        },
        SET_SHOP_TAGS(state, data){
          state.createForm.shop_tags = data
        },
        SET_ERROR_MESSAGE(state, message) {
          state.createForm.validErrorMessages = message
        },
        CLEAR_STATE_ACTIVE_RESERVATION(state) {
            Object.assign(state, getDefaultState());
        },
    },
    getters: {
        getActiveReservation: state => state.createForm,
    }
};

export default active_reservation;
