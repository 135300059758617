import { BaseService } from '../base.service';
import { ResponseWrapper, ApiError } from '../http';

export class PaymentsService extends BaseService {
  static get resourceEndpoint() {
    return 'owners/payments';
  }
  static async update(data = {}) {
    try {
      const response = await this.request().patch(
        `${this.resourceEndpoint}`,
        data
      );
      return new ResponseWrapper(response);
    } catch (error) {
      throw new ApiError(error);
    }
  }

  static async paymentTypes(parameters = {}) {
    const params = { ...parameters };
    try {
      const response = await this.request().get(
        `${this.resourceEndpoint}/payment_types`,
        { params }
      );
      return new ResponseWrapper(response);
    } catch (error) {
    }
  }

  static async deleteCustomterCard(data = {}) {
    try {
      const response = await this.request().post(
        `${this.resourceEndpoint}/delete_customter_card`,
        data
      );
      return new ResponseWrapper(response);
    } catch (error) {
      throw new ApiError(error);
    }
  }
}
