<template>
  <div class="owner_main_area">
    <div class="wrapper_owner_have_two_button">
      <h1 class="title_owner">新規店舗登録</h1>
      <div class="input_owner">
        <label class="owner_form_label">紹介画像</label>
        <div class="owner_margin_inline_10 owner_line_down">
          <div v-if="createForm.image" class="file_upload_image">
            <img class="image_shop" :src="createForm.image">
          </div>
          <div v-else class="file_upload_image no_image">
            <div class="file_upload_image_main">
              <div class="add_image_shop" />
            </div>
          </div>
        </div>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">店舗名</label>
        <div class="owner_margin_inline_10 owner_line_down">
          {{ createForm.name }}
        </div>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">店舗名（カナ）</label>
        <div class="owner_margin_inline_10 owner_line_down">
          <p class="break-space">{{ createForm.name_kana.trim() }}</p>
        </div>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">電話番号</label>
        <div class="owner_margin_inline_10 owner_line_down">
          {{ createForm.tel }}
        </div>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">郵便番号</label>
        <div class="owner_margin_inline_10 owner_line_down">
          {{ createForm.zipcode }}
        </div>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">都道府県</label>
        <div class="owner_margin_inline_10 owner_line_down">{{ getPrefectureName(prefectures, createForm.prefecture_id) }}</div>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">市区町村番地</label>
        <div class="owner_margin_inline_10 owner_line_down">
          {{ createForm.address }}
        </div>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">アクセス</label>
        <div class="owner_margin_inline_10 owner_line_down">
          {{ createForm.access }}
        </div>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">店舗URL</label>
        <div class="owner_margin_inline_10 owner_line_down">
          {{ createForm.official_url }}
        </div>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">店舗紹介タイトル</label>
        <div class="owner_margin_inline_10 owner_line_down">
          {{ createForm.desc_header }}
        </div>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">店舗紹介本文</label>
        <div class="owner_margin_inline_10 owner_line_down">
          {{ createForm.desc_content }}
        </div>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">平均単価</label>
        <div class="owner_margin_inline_10 owner_line_down">
          {{ createForm.shop_prices.price }}
        </div>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">カテゴリー</label>
        <div class="owner_margin_inline_10 owner_line_down">
          {{ getTagName(tag, createForm.shop_tags.tag_id) }}
        </div>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">店舗ID</label>
        <div class="owner_margin_inline_10 owner_line_down">
          {{ createForm.login_id }}
        </div>
      </div>
      <div class="input_owner">
        <label class="owner_form_label"> 店舗パスワード</label>
        <div class="owner_margin_inline_10 owner_line_down pasword_text">
          {{ password }}
        </div>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">Instagramのユーザーネーム</label>
        <div v-if="createForm.instagram_name" class="owner_margin_inline_10 owner_line_down">
          <a v-bind:href="link" target="_blank">https://www.instagram/{{ createForm.instagram_name }}</a>
        </div>
      </div>
      <div class="btn-wrapper_obsolute">
        <div class="btn_area_owner two_items owner_text_note_submit">
          <router-link
            :to="{}"
            class="btn btn_owner primary"
            @click.native="handleSave"
          >追加登録する</router-link>
          <router-link
            :to="{}"
            class="btn btn_owner primary_border ml_medium"
            @click.native="back"
          >キャンセル</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ShopAdministratorsService } from '../../../../services/Owner/shop_administrators.service';
import { mapGetters } from 'vuex';
import utils from '../../../../common/util';
export default {
  props: {
    restaurantAdministrators: {
      type: Array,
      default: () => []
    },
    tag: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      password: '********',
      link: '',
    };
  },

  computed: {
    ...mapGetters({
      createForm: 'getCreateForm',
      prefectures: 'getAllPrefectures',
    })
  },

  mounted() {
    this.link = 'https://www.instagram.com/' + this.createForm.instagram_name;
  },

  methods: {
    ...utils,
    async handleSave() {
      const params = Object.assign({}, this.createForm);
      params['login_id'] = this.createForm.login_id;
      this.$loading(true);
      try {
        const { response } = await ShopAdministratorsService.register(params);
        this.$emit('change_screen', 'complete');
        this.$emit('created', response.data);
        this.$loading(false);
      } catch (errors) {
        console.log(errors);
        this.$loading(false);
      }
    },
    back() {
      this.$emit('change_screen', 'create');
    },
  },
};
</script>

<style scoped>
.pasword_text {
  letter-spacing: 5px;
  font-size: 20px;
}
a {
  cursor: pointer;
}
.two_items {
  width: 90%;
}
.wrapper_owner_have_two_button {
  padding-bottom: 120px;
}
.break-space {
  white-space: break-spaces !important;
}
@media screen and (max-width: 480px) {
  .wrapper_owner_have_two_button {
    padding-bottom: 160px;
  }
  .two_items {
    width: 75%;
  }
  .btn_area_owner.two_items {
    margin-left: 10%;
  }
}
</style>
