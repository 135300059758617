<template>
      <div class="modal-content">
        <div class="heading">
          <span @click="onClose" title="Close" class="cross-button cursor-pointer">x</span>
        </div>

        <div class="modal-body pd-0" id="modal-body">

          <div class="tab-div">
            <template>
              <div class="tab_area">
                <a class="tab order_manage btn_point cursor-pointer">色ラベル</a>
              </div>
            </template>
          </div>

         <div class="content-and-footer-container">
           <div class="content-div">
             <div class="color-container" v-for="color in colorCodes">
               <div :class="{showBorder:(!selectedColor&&backGroundColor===color) ||selectedColor===color}">
                 <div @click="updateColor(color)" class="color-div cursor-pointer" :style="{backgroundColor:color}"></div>
               </div>
             </div>
           </div>
           <div class="modal-footer">
             <button v-if="selectedColor !== ''" @click="removeColor" type="button" class="btn btn-danger btn-fill mr_small">選択解除</button>
             <button @click="saveColor" type="button" class="btn btn-primary mr_small">保存</button>
           </div>
         </div>

        </div>
      </div>
  </template>

<script>
import Http from "../../shared/http"
export default{
  props:['reservationId','backGroundColor'],
  data(){
    return{
      selectedColor:'',
      colorCodes:[
        'var(--color-error)',
        'var(--color-tt-course)',
        'var(--color-tt-block)',
        'var(--color-tt-message)',
        'var(--color-tt-tag-color)',
      ],
    }
  },
  watch: {
    backGroundColor: {
      immediate: true,
      handler(r) {
        this.selectedColor = r === null ? '' : r
      }
    }
  },
  methods:{
    onClose(){
      this.$emit('close')
    },
     updateColor(color) {
      this.selectedColor=color;
    },
    removeColor(){
      this.selectedColor = ''
      this.backGroundColor = ''
    },
    async saveColor(){
      const slug = document.getElementById('slug').value == '' ? '' : `/${document.getElementById('slug').value}`;
      await Http.patch(`${slug}/shop_manager/time_table/reservation_color/${this.reservationId}`, {
        color: this.selectedColor
      })
          .then((response) => {
            this.$emit('reload')
            this.$emit('close');

          })
          .catch((error) => {
            console.log(error);
          });
    }

  }
}
</script>

<style scoped>
.modal-content {
  height: 100%;
  border: none;
  position: relative
}
.heading {
  background-color: var(--color-main);
  display: flex;
  align-items: center;
  padding: 10px 30px;
  position: fixed;
  width: 100%;
  gap: 400px;
}
.cross-button {
  font-size: 30px;
  font-weight: normal;
  color: var(--color-white);
}
.modal-body {
  margin-top: 70px;
  position: relative;
  padding: 0.6rem 0rem;
}
.tab-div {
  width: 100%;
}
.tab_area {
  display: flex;
  gap: 20px;
  width: 100%;
}
.tab {
  border-bottom: 2px solid var(--color-main);
}
.color-container{
  padding:20px 40px 20px 25px;
}

.color-div{
  height:50px;
  border:2px solid black;
  border-radius:10px;
}
.color-div:hover{
  filter:brightness(85%);
}


.showBorder{
  padding:10px;
  border:2px solid var(--color-main);
}
.modal-footer{
  justify-content:center;
}
.btn{
  font-size:16px;
}
.content-and-footer-container{
  display:flex;
  flex-direction:column;
  height:calc(100dvh - 140px);
  position:relative;
}
.content-div{
  overflow-y:auto;
  height:100%;
}
</style>
