<template>
  <div class="modal fade show" style="display: block;">
    <div class="modal-dialog" role="document" :style="imageContainerStyle">
      <div class="modal-content">
        <div class="modal-body">
          <i title="Close Image" @click="onClose" class="fa fa-times cross-button cursor-pointer" aria-hidden="true"></i>
          <div class="single-image-container">
            <img :src="imageID" @load="checkImageOrientation($event)">
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop show"></div>
  </div>
</template>

<script>
export default{
  props:['imageID'],
  data() {
    return {
      imageOrientation: 'Unknown',
      imageContainerStyle:{}
    };
  },

  methods:{
    onClose(){
      this.$emit('close')
    },
    checkImageOrientation(event) {
      const img = event.target;
      if (img.width > img.height) {
        this.imageOrientation = 'Landscape';
        this.imageContainerStyle = {maxWidth : '80dvw' };
      } else if (img.width < img.height) {
        this.imageOrientation = 'Portrait';
        this.imageContainerStyle = { minHeight: '80dvh'};
      } else {
        this.imageOrientation = 'Square';
        if(['landscape-primary', 'landscape-secondary'].includes(window.screen.orientation.type)){
          this.imageContainerStyle = { maxWidth : '50dvw'};
        }
        else{
          this.imageContainerStyle = { maxWidth : '80dvw'}
        }
      }
    },
  }
}
</script>

<style scoped>
.modal-dialog {
  z-index: 1050;
}
.single-image-container{
  display:flex;
  justify-content:center;
  padding:24px 0px;
  max-height: 90dvh;
  max-width: 90dvw;
}
.cross-button{
  position:absolute;
  top:-2px;
  right:3px;
  font-size:40px;
  color:var(--color-main);
}
.modal-dialog {
  min-height: calc(100vh - 200px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: auto;
}

</style>
