<template>
  <div class="sidebar">
    <div class="sp_menu">
      <div class="logo_owner" :class="[slug ? `brand_${slug}` : '']" />
      <div class="sp_menu_trigger" />
    </div>
    <div
      class="sidebar sidebar-owner"
      :class="[
        openSidebar ? 'sidebar-owner-open' : '',
        hideSidebar ? 'sidebar-owner-hide' : '',
      ]"
    >
      <ul>
        <li class="owner_menu_content" @click="handleSidebar">
          <router-link
            :to="convertLink('/owner')"
            :class="
              $route.path.includes('/owner/shop') ||
                $route.name === 'restaurant_dashboard' ||
                $route.name == 'owner_detail' ||
                $route.name == 'owner_delete' ||
                $route.name == 'owner_edit' ||
                $route.name == 'complete_delete_owner'
                ? 'router-link-exact-active'
                : ''
            "
          >
            <span class="text">アカウント管理</span>
          </router-link>
        </li>
        <li @click="gobackToSNSB" v-if="owner.permission==='HAVE_FULL_ACCESS'">
          <router-link to="#" class=""><span class="text">SNS Booster</span> </router-link>
        </li>
        <li @click="logoutMember">
          <router-link id="logout" to="#" class=""><span class="text">ログアウト</span> </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { AuthService } from '../../services/auth.service';
import { OwnersService } from '../../services/Owner/owners.service';

export default {
  props: {
    openSidebar: {
      type: Boolean,
    },
    hideSidebar: {
      type: Boolean,
    },
    handleSidebar: {
      type: Function,
    },
  },
  data() {
    return {
      owner: {},
      slug: document.getElementById('slug').value,
      oem_payment_type: false
    }
  },
  async mounted() {
    this.getOwners();
  },
  methods: {
    closeSidebar() {
      this.handleSidebar();
    },

    async logoutMember() {
      document.getElementById('logout').classList.add('router-link-exact-active');
      const logoutPopup = await this.$swal.fire({
        title: '確認',
        html: 'ログアウトします。よろしいですか？',
        showCancelButton: true,
        confirmButtonText: 'ログアウトする',
        cancelButtonText: 'キャンセル',
        customClass: {
          confirmButton: 'confirm-popup-owner owner_width_button',
          title: 'title-popup-owner',
          cancelButton: 'cancel-popup-owner owner_width_button',
          htmlContainer: 'html-popup-owner',
        },
      });

      if (logoutPopup.isConfirmed) {
        try {
          const res = await AuthService.logout();
          const slug = res.response.data.slug == null ? '' : res.response.data.slug;
          window.location.href = `/${slug}`;
        } catch (error) {
          window.location.href = '/';
        }
      } else {
        document.getElementById('logout').classList.remove('router-link-exact-active');
      }
    },
    async getOwners() {
      try {
        const { response } = await OwnersService.index();
        this.owner = response.data;
        this.oem_payment_type = this.owner.oem_payment_type
      } catch (error) {
        console.log(error);
      }
    },
    convertLink(link) {
      let currentLink = '';
      currentLink = `/${this.slug}${link}`;
      return currentLink;
    },
   async gobackToSNSB(){
     await this.$router.push('/dip/store-list')
     await window.location.reload()
    }
  },
};
</script>

<style scoped>
.text{
  color:  #0090d7;
}
@media screen and (max-width: 1367px) {
  .text{
    color: white;
  }
}
</style>
