const getDefaultState = () => {
  return {
    createForm: {
      login_id: null,
      name: null,
      name_kana: null,
      tel: null,
      zipcode: null,
      prefecture_id: null,
      address: null,
      password: null,
      official_url: null,
      desc_header: null, 
      desc_content: null,
      instagram_name: null,
      image: null,
      shop_prices: {
        price: null
      },
      shop_tags: {
        tag_id: null
      },
    },
  };
};

const restaurants_owner = {
  state: {
    createForm: {
      login_id: null,
      name: null,
      name_kana: null,
      tel: null,
      zipcode: null,
      prefecture_id: null,
      address: null,
      password: null,
      official_url: null,
      desc_header: null, 
      desc_content: null,
      instagram_name: null,
      image: null,
      image_name: null,
      shop_prices: {
        price: null
      },
      shop_tags: {
        tag_id: null
      },
    },
  },
  mutations: {
    CLEAR_STATE_RESTAURANTS_OWNER(state) {
      Object.assign(state, getDefaultState());
    },
  },
  getters: {
    getCreateForm: state => state.createForm,
  }
};

export default restaurants_owner;
