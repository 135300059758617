<template>
  <main class="owner_container bg_white">
    <div class="owner_main_area">
      <h1 class="title_owner">店舗編集</h1>
      <form>
        <div class="form_row vertical mb_medium">
          <label class="form_label">紹介画像</label>
          <div class="owner_input_wrapper">
            <div v-show="image" class="file_upload_image">
              <img class="image_shop" :src="image">
            </div>
            <div v-show="!image" class="file_upload_image no_image">
              <div class="file_upload_image_main">
                <div class="add_image_shop" />
              </div>
            </div>
            <div class="btn_area two_items two_items_image">
              <button class="btn primary btn_row btn_upload" type="button" @click="$refs.file.click()">画像をアップロード</button>
              <button v-show="isBase64" class="btn primary btn_row btn_clear" type="button" @click="deleteImage" >画像をクリアする</button>
            </div>
            <div>
              <input id="file_uploader" ref="file" type="file"
                    class="file_uploader"
                    accept="image/x-png,image/gif,image/jpeg,image/jpg,image/tiff,image/bmp"
                    hidden @change="previewFiles"
              >
            </div>
          </div>
          <div>
            <p class="owner_text_note form_text_note_margin">予約ページに表示するお店の画像をアップロードします。</p>
            <p class="owner_text_note form_text_note_margin">目安：容量2MBまで、サイズ 600px × 400pxまで、形式 jpeg, gif, png</p>
          </div>
          <span v-for="error in errors['image']" :key="error" class="error-message owner">
            {{ error }}
          </span>
        </div>
      </form>
      <div class="input_owner">
        <label class="owner_form_label">店舗名<span class="require">必須</span></label>
        <div class="owner_input_wrapper">
          <input v-model="name" type="text"
                 placeholder="店舗名を入力"
          >
          <span v-for="error in errors['name']" :key="error" class="error-message owner">
            {{ error }}
          </span>
        </div>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">店舗名（カナ）<span class="require">必須</span></label>
        <div class="owner_input_wrapper">
          <input v-model="nameKana" type="text"
                 placeholder="店舗名（カナ）を入力"
          >
          <span v-for="error in errors['name_kana']" :key="error" class="error-message owner">
            {{ error }}
          </span>
        </div>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">電話番号<span class="require">必須</span></label>
        <div class="owner_input_wrapper medium">
          <input v-model="tel" type="text"
                 placeholder="電話番号（ハイフン不要、半角のみ）を入力"
          >
          <span v-for="error in errors['tel']" :key="error" class="error-message owner">
            {{ error }}
          </span>
        </div>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">郵便番号<span class="require">必須</span></label>
        <div class="owner_input_wrapper medium">
          <input v-model="zipcode" type="text"
                 placeholder="郵便番号（ハイフン不要、半角のみ）を入力"
          >
          <span v-for="error in errors['zipcode']" :key="error" class="error-message owner">
            {{ error }}
          </span>
        </div>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">都道府県<span class="require">必須</span></label>
        <div class="owner_select_wrapper arrow_down medium">
          <select
            v-model="prefectureId"
            class="select_box_padding owner_select_shevron_down_none"
          >
            <option v-for="option in prefectures" :key="option.value" :value="option.code">{{ option.name }}</option>
          </select>
        </div>
        <span v-for="error in errors['prefecture_id']" :key="error" class="error-message owner">
          {{ error }}
        </span>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">市区町村番地<span class="require">必須</span></label>
        <div class="owner_input_wrapper">
          <input v-model="address" type="text"
                 placeholder="市区町村番地を入力"
          >
          <span v-for="error in errors['address']" :key="error" class="error-message owner">
            {{ error }}
          </span>
        </div>
      </div>
      <div class="input_owner">
        <label for="access" class="owner_form_label">アクセス<span class="require">必須</span></label>
        <div class="owner_input_wrapper">
          <input v-model="access" type="text"
                 placeholder="アクセス情報を入力"
          >
          <span v-for="error in errors['access']" :key="error" class="error-message owner">
            {{ error }}
          </span>
        </div>
      </div>
      <div class="input_owner">
        <label for="official_url" class="owner_form_label">店舗URL</label>
        <div class="owner_input_wrapper">
          <input v-model="official_url" type="text"
                 placeholder="店舗URLを入力"
          >
          <span v-for="error in errors['official_url']" :key="error" class="error-message owner">
            {{ error }}
          </span>
        </div>
      </div>
      <div class="input_owner">
        <label for="desc_header" class="form_label">店舗紹介タイトル</label>
        <div class="owner_input_wrapper">
          <input v-model="desc_header" type="text"
                 placeholder="店舗タイトルを入力"
          >
          <span v-for="error in errors['desc_header']" :key="error" class="error-message owner">
            {{ error }}
          </span>
        </div>
      </div>
      <div class="input_owner">
        <label for="desc_content" class="form_label">店舗紹介本文</label>
        <div class="owner_input_wrapper">
          <textarea v-model="desc_content" placeholder="店舗紹介本文を入力" rows="3" />
          <span v-for="error in errors['desc_content']" :key="error" class="error-message owner">
            {{ error }}
          </span>
        </div>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">平均単価<span class="require">必須</span></label>
        <div class="owner_input_wrapper medium">
          <input v-model="shop_prices.price" type="text"
                 placeholder="平均単価を入力"
          >
          <p class="owner_text_note form_text_note_margin">数字のみでご入力ください。複数の予算帯がある場合は平均予算をご記載ください。例：3000</p>
          <span v-for="error in errors['price']" :key="error" class="error-message owner">
            {{ error }}
          </span>
        </div>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">カテゴリー<span class="require">必須</span></label>
        <div class="owner_select_wrapper arrow_down medium">
          <select
            v-model="shop_tags.tag_id"
            class="select_box_padding owner_select_shevron_down_none"
          >
            <option v-for="option in tag" :key="option.value" :value="option.id">{{ option.name }}</option>
          </select>
        </div>
        <span v-for="error in errors['tag_id']" :key="error" class="error-message owner">
          {{ error }}
        </span>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">店舗ID<span class="require">必須</span></label>
        <div class="owner_input_wrapper medium">
          <div class="flex alignCenter">
            <div class="owner_margin_inline_10 owner_line_down owner_color_black">{{ shopAdministrator.login_id }}</div>
          </div>
        </div>
      </div>
      <div v-if="!isEditPasswword">
        <div class="input_owner">
          <label class="owner_form_label">店舗パスワード<span class="require">必須</span></label>
          <router-link :to="{}" class="ml_small owner_color_blue" @click.native="showInputPassword">変更する</router-link>
        </div>
      </div>
      <div v-else>
        <div class="input_owner">
          <label class="owner_form_label">店舗パスワード<span class="require">必須</span></label>
          <div class="owner_input_wrapper medium">
            <input id="password_old" v-model="password" type="password"
                   class="owner_input_pwd"
                   placeholder="店舗パスワードを入力"
            >
            <div class="owner_password_old" :class="showOldPwd ? 'owner_password_old-open' : 'owner_password_old-hide'" @click="showPasswordOld" />
            <p class="note_sp form_text_note_margin">半角英数字8文字以上で入力してください</p>
            <span v-for="error in errors['password']" :key="error" class="error-message owner">
              {{ error }}
            </span>
          </div>
        </div>
        <div class="input_owner">
          <label class="owner_form_label">店舗パスワード（確認用）<span class="require">必須</span></label>
          <div class="owner_input_wrapper medium">
            <input id="password_confirm" v-model="passwordConfirm" type="password"
                   class="owner_input_pwd"
                   placeholder="店舗パスワード（確認用）を入力"
            >
            <div class="owner_password_old" :class="showConfirmPwd ? 'owner_password_old-open' : 'owner_password_old-hide'" @click="showPasswordConfirm" />
            <span v-for="error in errors['password_confirm']" :key="error" class="error-message owner">
              {{ error }}
            </span>
          </div>
        </div>
      </div>
      <div class="input_owner">
        <label for="instagram_name" class="form_label">Instagramのユーザーネーム</label>
        <div class="owner_input_wrapper medium">
          <input v-model="instagram_name" type="text"
                 placeholder="Instagramのユーザーネームを入力"
          >
          <p class="owner_text_note form_text_note_margin">席予約ボタンを設置する店舗のInstagramのユーザーネームを記入してください。</p>
          <span v-for="error in errors['instagram_name']" :key="error" class="error-message owner">
            {{ error }}
          </span>
        </div>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">登録日</label>
        <div class="owner_margin_inline_10 owner_line_down owner_color_black">
          {{ formatDate(shopAdministrator.created_at) }}
        </div>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">ステータス</label>
        <div class="owner_margin_inline_10 owner_line_down" :class="changeTextColor() ? 'text_color-reb' : 'owner_color_black'">
          {{ status[shopAdministrator.status] }}
        </div>
      </div>
      <div class="btn_area_owner two_items owner_btn_padding">
        <router-link
          :to="{}"
          class="btn btn_owner primary"
          @click.native="handleConfirm"
        >
          更新する
        </router-link>
        <router-link
          :to="{ path: slug == null ? `/owner/shop/${shopAdministrator.id}/detail` : `/${this.slug}/owner/shop/${shopAdministrator.id}/detail`}"
          class="btn btn_owner primary_border ml_medium"
        >
          キャンセル
        </router-link>
      </div>
    </div>
  </main>
</template>
<script>
import Validator from '../../../validator';
import { ShopAdministratorsService } from '../../../services/Owner/shop_administrators.service';
import { mapGetters } from 'vuex';
import utils from '../../../common/util';
import { AddressInfo, PopupDisplayError } from '../../../common/util';
import { CorrectNumber } from '../../../common/util';

export default {
  data() {
    return {
      isBase64: '',
      checkImage: 0,
      tag: [],
      shop_tags: [],
      shop_prices: [],
      imageName: '',
      imageError: '',
      image: '',
      access: '',
      desc_content: '',
      desc_header: '',
      shopAdministrator: {},
      name: '',
      nameKana: '',
      zipcode: '',
      prefectureId: '',
      city: '',
      address: '',
      instagram_name: '',
      buildingName: '',
      email: '',
      tel: '',
      official_url: '',
      password: '',
      passwordConfirm: '',
      showOldPwd: false,
      showConfirmPwd: false,
      status: {
        active: '利用中',
        admin_cancel: '停止中',
        owner_cancel: '利用中',
        inactive: '解約済',
      },
      isEditPasswword: false,
      errors: [],
      price_id: null,
      tag_id: null,
      image_name: null,
      slug: ''
    };
  },

  computed: {
    ...mapGetters({
      prefectures: 'getAllPrefectures',
    })
  },

  watch: {
    'zipcode'(val) {
      if (!val || val.length !== 7) return;

      if (val === this.shopAdministrator.zipcode) {
        this.prefectureId = this.shopAdministrator.prefecture_id;
        this.address = this.shopAdministrator.address;
      }else {
        AddressInfo(val, this.prefectures).then(addressInfo => {
          this.prefectureId = addressInfo.code;
          this.address = addressInfo.city + addressInfo.address;
        });
      }
    },
  },

  async mounted() {
    await this.getTag();
    await this.getRestaurantAdministrator();
  },
  methods: {
    ...utils,
    async getTag() {
      this.$loading(true);
      try {
        const { response } = await ShopAdministratorsService.load_tags();
        this.tag = response.data;
        this.$loading(false);
      } catch (error){
        console.log(error);
        this.$loading(false);
      }
    }, 
    async checkBase64() {
      if(this.image.includes('images/shops/default_image.png')) {
        this.isBase64 = false;
      }
      else {
        this.isBase64 = true;
      }
    },
    changeTextColor() {
      return this.shopAdministrator.status === 'admin_cancel';
    },
    async getRestaurantAdministrator() {
      this.$loading(true);
      try {
        const { response } = await ShopAdministratorsService.get(this.$router.history.current.params.id);
        this.shopAdministrator = response.data;
        this.name = response.data.name;
        this.nameKana = response.data.name_kana;
        this.zipcode = response.data.zipcode;
        this.prefectureId = response.data.prefecture_id;
        this.address = response.data.address;
        this.tel = response.data.tel;
        this.official_url = response.data.official_url;
        this.instagram_name = response.data.instagram_name;
        this.shop_prices.price = response.data.price;
        this.shop_tags.tag_id = response.data.tag_id;
        this.desc_header = response.data.desc_header;
        this.desc_content = response.data.desc_content;
        this.access = response.data.access;
        this.image = response.data.image;
        this.price_id = response.data.shop_price_id;
        this.tag_id = response.data.shop_tag_id;
        this.slug = response.data.media_name;
        this.$loading(false);
      } catch (error){
        this.$loading(false);
      }
      this.checkBase64();
    },
    showInputPassword() {
      this.isEditPasswword = true;
    },
    showPasswordOld() {
      this.showOldPwd = !this.showOldPwd;
      const passwordField = document.querySelector('#password_old');
      if (passwordField.getAttribute('type') === 'password') passwordField.setAttribute('type', 'text');
      else passwordField.setAttribute('type', 'password');
    },
    showPasswordConfirm() {
      this.showConfirmPwd = !this.showConfirmPwd;
      const passwordField = document.querySelector('#password_confirm');
      if (passwordField.getAttribute('type') === 'password') passwordField.setAttribute('type', 'text');
      else passwordField.setAttribute('type', 'password');
    },
    showDate(date) {
      return date !== null ? this.formatDateWithoutLeadingZeros(date) : '';
    },
    async handleConfirm() {
      this.validate();

      if (Object.keys(this.errors).length) {
        this.scrollTopScreen();
        PopupDisplayError(this.$swal);
        return;
      }

      const confirm = await this.$swal.fire({
        title: '確認',
        html: `<p>店舗情報を更新します。</p>
              <p>よろしいですか？</p>`,
        confirmButtonText: '決定する',
        cancelButtonText: 'キャンセル',
        showCancelButton: true,
        customClass: {
          title: 'title-popup-owner',
          htmlContainer: 'html-popup-owner',
          confirmButton: 'confirm-popup-owner',
          cancelButton: 'cancel-popup-owner',
        }
      });

      if (confirm.isConfirmed) {
        this.handleSave();
      }
    },
    async handleSave() {
      this.validate();
      if( this.checkImage == -1) {
        this.image_id = 'default';
      }
      let params = {
        name : this.name,
        name_kana : this.nameKana,
        zipcode : this.zipcode,
        prefecture_id : this.prefectureId,
        address : this.address,
        tel : this.tel,
        official_url : this.official_url,
        instagram_name : this.instagram_name,
        shop_prices : [{
          id: this.price_id,
          price : this.shop_prices.price,
        }],
        shop_tags : [{
          id: this.tag_id,
          tag_id : this.shop_tags.tag_id,
        }],
        image : this.image_id,
        desc_header : this.desc_header,
        desc_content : this.desc_content,
        access : this.access,
        image_name: this.image_name
      };

      if (this.password) {
        params = {...params, password: this.password };
      }

      this.$loading(true);
      try {
        await ShopAdministratorsService.update(this.shopAdministrator.id, params);
        const link = this.slug == null ? `/owner/shop/${this.shopAdministrator.id}/detail` : `/${this.slug}/owner/shop/${this.shopAdministrator.id}/detail`;
        this.$router.push(link);
        this.$loading(false);
      } catch (error) {
        this.scrollTopScreen();
        PopupDisplayError(this.$swal);
        this.$loading(false);
      }
    },

    scrollTopScreen() {
      window.scrollTo(0, 0);
      const elmnt = document.getElementById('body_wrapper_owner');
      elmnt.scrollTo(0, 0);
    },
    previewFiles(event) {
      this.isBase64 = true;
      this.checkImage = 1;
      if (event.target.files.length < 1) {
        return;
      }
      this.imageError = false;
      const file = event.target.files[0];
      if (file.size > 3 * 1000 * 1000) { // 3MB is max size
        this.imageError = true;
      }
      this.createBase64Image(file);
    },
    createBase64Image(file) {
      this.image_name = file.name;

      const reader = new FileReader();

      reader.onload = (e) => {
        this.image = e.target.result;
        this.image_id = this.image;
      };
      reader.readAsDataURL(file);
    },
    deleteImage() {
      this.isBase64 = false;
      this.checkImage = -1;
      this.image = null;
      document.getElementById('file_uploader').value = null;
    },

    validate() {
      const validator = new Validator();
      
      validator.checkRequire('name', this.name, '店舗名') &&
        validator.checkMaxLength('name', this.name, 255, '店舗名');

      validator.checkRequire('name_kana', this.nameKana, '店舗名（カナ）') &&
        validator.checkKatakana('name_kana', this.nameKana, '店舗名（カナ）') &&
        validator.checkMaxLength('name_kana', this.nameKana, 255, '店舗名（カナ）');
      
      validator.checkRequire('prefecture_id', this.prefectureId, '都道府県');

      validator.checkRequire('address', this.address, '市区町村番地') &&
        validator.checkMaxLength('address', this.address, 255, '市区町村番地');

      validator.checkRequire('zipcode', this.zipcode, '郵便番号')&&
        validator.checkMaxLength('zipcode', this.zipcode, 7, '郵便番号')&&
        validator.checkPostCode('zipcode', this.zipcode, '郵便番号');

      validator.checkRequire('price', this.shop_prices.price, '平均単価') &&
        validator.checkDigit('price', this.shop_prices.price, '平均単価') &&
        validator.checkMaxNumber('price', this.shop_prices.price, 9, '平均単価') &&
        validator.checkGreaterThan('price', this.shop_prices.price, 1, '平均単価');

      const tel =  this.tel;
      validator.checkRequire('tel', tel, '電話番号') &&
        validator.checkMaxLength('tel', CorrectNumber(tel), 11, '電話番号') &&
        validator.checkMinLength('tel', CorrectNumber(tel), 10, '電話番号') &&
        validator.checkTel('tel', tel, '電話番号');
      if (!validator.errors.tel) {
        validator.checkTel('tel', CorrectNumber(this.tel), '電話番号');
      }

      if (this.imageError) {
        validator.errors['image'] = [];
        validator.errors['image'].push('添付ファイルのサイズが許容制限エラーを超えています。');
      }

      if(this.official_url){
        validator.checkMaxLength('official_url', this.official_url, 255, '店舗URL')&&
          validator.checkUrl('official_url',this.official_url,'店舗URL');
      }

      if(this.desc_header){
        validator.checkMaxLength('desc_header', this.desc_header, 255, '店舗紹介タイトル');
      }

      if(this.desc_content){
        validator.checkMaxLength('desc_content', this.desc_content, 255, '店舗紹介本文');
      }

      if(this.instagram_name){
        validator.checkMaxLength('instagram_name', this.instagram_name, 30, 'Instagramのユーザーネーム')&&
          validator.checkInstagram('instagram_name', this.instagram_name, 'Instagramのユーザーネーム');
      }

      validator.checkRequire('tag_id', this.shop_tags.tag_id, 'カテゴリー');

      if (this.isEditPasswword) {
        if (this.password === null && this.passwordConfirm === null || this.password === '' && this.passwordConfirm === '') {
          this.errors = validator.errors;
        } else {
          validator.checkRequire('password', this.password, '店舗パスワード') &&
            validator.checkAsciiWithoutSpace('password', this.password, '店舗パスワード') &&
            validator.checkMinLength('password', this.password, 8, '店舗パスワード') &&
            validator.checkMaxLength('password', this.password, 50, '店舗パスワード');

          validator.checkRequire('password_confirm', this.passwordConfirm, '店舗パスワード（確認用）') &&
            validator.checkSameValue('password_confirm', this.passwordConfirm, this.password, '店舗パスワード（確認用）');

          this.errors = validator.errors;
        }
      } else {
        this.errors = validator.errors;
      }
    },
  }
};
</script>

<style scoped>
.btn.primary.btn_row.btn_clear{
  width: 170px;
  margin-left: 10px;
  background-color: #ffffff;
  color: #0090d7;
  border: 2px solid #0090d7 !important;
}
.btn.primary.btn_row.btn_upload{
  width: 170px;
}
.mb_12 {
  margin-bottom: 12px;
}
.text_note_mb {
  margin-bottom: 6px;
}
.two_items_image {
  justify-content: unset;
  width: 90%;
  margin: unset;
  margin-top: 16px;
}
.btn_area.two_items .btn {
  max-width: 200px;
}
.file_upload_image img {
  width: 150px;
  object-fit: cover !important;
}
.file_upload_image.no_image {
  border: unset;
  cursor: unset;
  position: relative;
}
.flex {
  flex: unset
}
.btn_area {
  height: 50px;
}
@media screen and (max-width: 480px) {
  .btn_area.two_items {
    display: block;
    height: unset;
  }
  .btn_upload {
    height: 50px;
  }
  .btn_clear {
    height: 50px;
    margin-left: 0px !important;
    margin-top: 10px;
  }
  .btn_area_owner.two_items {
    margin-left: 10%;
  }
}
@media screen and (max-width: 1366px) {
  .bg_white{
    background: white;
  }
}
</style>
