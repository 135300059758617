<template>
<div class="numpad-container">
  <div class="numpad-btn" @click="press">
    1
  </div>
  <div class="numpad-btn" @click="press">
    2
  </div>
  <div class="numpad-btn" @click="press">
    3
  </div>
  <div class="numpad-btn" @click="press">
    4
  </div>
  <div class="numpad-btn" @click="press">
    5
  </div>
  <div class="numpad-btn" @click="press">
    6
  </div>
  <div class="numpad-btn" @click="press">
    7
  </div>
  <div class="numpad-btn" @click="press">
    8
  </div>
  <div class="numpad-btn" @click="press">
    9
  </div>
  <div class="numpad-btn transparent">
    ~
  </div>
  <div class="numpad-btn" @click="press">
    0
  </div>
  <div class="numpad-btn" @click="press">
    Del
  </div>
</div>
</template>
<script>
export default {
  name:'Numpad',
  methods:{
    press(e){
    this.$emit('press',e.target.textContent)
    }
  }
}
</script>
<style scoped>
.numpad-container{
  padding: 6px 6px;
  //width: 20vw;
  background-color: white;
  display: grid;
  grid-template-columns: repeat(3,1fr);
  gap: 8px;
  border-radius: 8px;
}
.numpad-btn{
  cursor: pointer;
  width: 100%;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--color-main);
  background: white;
  font-size: 2.2em;
  border-radius: 8px;
  color: var(--color-main);
}
.numpad-btn:active{
  transform: scale(0.98);
  box-shadow: -2px 5px 20px 1px rgba(0,0,0,0.26);
  -webkit-box-shadow: -2px 5px 20px 1px rgba(0,0,0,0.26);
  -moz-box-shadow: -2px 5px 20px 1px rgba(0,0,0,0.26);

}
.transparent{
  opacity: 0;
}
</style>