<template>
  <div class="choose-modal" v-if="isVisible"  @click.self="close">
    <div class="choose-modal-content" @click.self="close" >
      <div class="choose-modal-buttons" @click.stop>
      <div class="choose-buttons" @click="OpenReservationModal" title="予約登録">
        <img class="my-memo top-nav-images" src="../../../../../assets/images/icon_timetable/icon_memo1.svg" width="32" height="32" alt="img">
        予約登録
      </div>
        <div class="choose-buttons ml-2" @click="OpenWalkInReservationModal" title="ウォークイン登録">
          <img class="my-memo top-nav-images" src="../../../../../assets/images/icon_timetable/icon_clock.svg" width="32" height="32" alt="img">
        ウォークイン登録
      </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
export default {
  name: 'ChooseReservationTypeModal',
  props:{
    isVisible:{
      type: Boolean
    },
    selectedDate:{
      type: Date
    }
  },
  methods:{
    close(){
      this.$emit('close')

    },
    get_new_route(step){
      const route_path = this.$route.fullPath.split('/');
      const query=route_path.pop().split('?')[1]
      route_path.push(`time_table/reservations/${step}?${query}`);
      return route_path;
    },
    OpenReservationModal(){
        window.location.href = `/shop_manager/time_table/reservations/1?date=${this.formatDate(this.selectedDate)}`
    },
    OpenWalkInReservationModal(){
      const t = Math.random().toString(36).substring(2,7);
      window.location.href = `/shop_manager/time_table/reservations/1?date=${this.formatDate(this.selectedDate)}&t=${t}`
      this.$router.push(this.get_new_route(1));

      this.close()
    },
    formatDate(date){
      if(date==='' || date===null || date===undefined){
        return moment(new Date).format('YYYY-MM-DD')

      }
      else{
        return moment(this.selectedDate).format('YYYY-MM-DD')
      }

    }
  }

}
</script>
<style scoped>
.choose-modal {
  position: fixed; /* Stay in place */
  z-index: 9999999; /* Sit on top */
  left: 0;
  top: 0;

  overflow: auto; /* Enable scroll if needed */
  background-color: #00000080 /* Fallback color */
}

.choose-modal-content {

  height: 100dvh; /* Use full viewport height */
  width: 100dvw; /* Use full viewport height */

}
.choose-modal-buttons{
  display:flex;
  justify-content: center;
  top: 50dvh;
  left: 15dvw;
  justify-items: center;
  position: absolute;
  width: 80%; /* Could be more or less, depending on screen size */

}
.choose-buttons{
cursor: pointer;
  background-color: var(--main_opacity5);
  padding: 30px 30px;
  border: 2px solid var(--color-main);
  border-radius: 10px;
  font-size: 32px;
  align-items: center;
  color: var(--color-main);

}

</style>
