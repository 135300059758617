<template>
  <div class="modal fade show" style="display: block;">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close close_reservation_modal" @click="closeModal"><span>&times;</span></button>
        </div>
        <div class="modal-body input-field-font">
          <div class="row">
            <input
                type="text"
                v-model="anniversary_name"
                class="form-control"
                placeholder="記念日名"
            >
            <small v-if="errors['anniversary_name'] && errors['anniversary_name'].length > 0" class="error">
              {{ errors['anniversary_name'][0] }}
            </small>
          </div>
          <div class="row mt-3">
            <DatePicker input-class="form-control" v-model="anniversary" value-type="format" format="YYYY/MM/DD"
                        placeholder="日付"></DatePicker>
            <small v-if="errors['anniversary'] && errors['anniversary'].length > 0" class="error">
              {{ errors['anniversary'][0] }}
            </small>
          </div>
        </div>
        <div class="modal-footer">
          <button @click="add" class="btn btn-primary">追加</button>
          <button @click="closeModal" class="btn btn-outline-primary">閉じる</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/ja';
export default {
  components: {
    DatePicker
  },
  data() {
    return {
      errors: [],
      anniversary_name: '',
      anniversary: '',
    }
  },
  methods: {
    closeModal() {
      this.$emit('close-delete-modal', true)
    },
    add() {
      this.errors = {};
      if (!this.anniversary_name) {
        this.errors['anniversary_name'] = ['記念日名は必須です'];
      }
      if(this.anniversary_name.length>20){
        this.errors['anniversary_name']=['記念日は20文字以下で入力してください。'];
      }
      if (!this.anniversary) {
        this.errors['anniversary'] = ['日付は必須です'];
      }
      if (Object.keys(this.errors).length > 0) {
        return;
      }
      this.$emit('addAnniversary', {anniversary_name: this.anniversary_name, anniversary: this.anniversary});
      this.anniversary_name = '';
      this.anniversary = '';
      this.closeModal();
    }
  }
}
</script>
<style scoped>
.error{
  font-size:12px;
}
.input-field-font {
  margin: auto;
  width: 80%;
}
.modal-content {
  border-radius: 35px;
}
.modal-footer {
  text-align: center;
  display: block;
}
</style>