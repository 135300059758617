<template>
  <main class="owner_container bg_white">
    <div class="owner_main_area">
      <h2 class="mb_medium">法人情報管理</h2>
      <form>
        <div class="input_owner">
          <label class="owner_form_label">オーナーID</label>
          <div class="owner_margin_inline_10 owner_line_down owner_color_black">
            {{ owner.login_id }}
          </div>
        </div>
        <div v-if="isActive === true" class="input_owner">
          <label class="owner_form_label">パスワード</label>
          <div
            class="owner_margin_inline_10 owner_color_blue btn_pointer"
            @click="isChangePassword()"
          >
            変更する
          </div>
        </div>

        <div v-if="isActive === false">
          <div class="input_owner">
            <label class="owner_form_label">新しいパスワード<span class="require">必須</span></label>
            <div class="owner_input_wrapper medium">
              <input
                id="password_old"
                v-model="password"
                type="password"
                class="owner_input_pwd"
                placeholder="新しいパスワードを入力"
              >
              <div
                class="owner_password_old"
                :class="
                  showOldPwd
                    ? 'owner_password_old-open'
                    : 'owner_password_old-hide'
                "
                @click="showPasswordOld"
              />
              <p class="owner_text_note form_text_note_margin">半角英数字8文字以上で入力してください</p>
              <span
                v-for="error in errors['password']"
                :key="error"
                class="error-message owner"
              >
                {{ error }}
              </span>
            </div>
          </div>
          <div class="input_owner">
            <label class="owner_form_label">新しいパスワード（確認）<span class="require">必須</span></label>
            <div class="owner_input_wrapper medium">
              <input
                id="password_confirm"
                v-model="passwordConfirm"
                type="password"
                class="owner_input_pwd"
                placeholder="新しいパスワード（確認）を入力"
              >
              <div
                class="owner_password_old"
                :class="
                  showConfirmPwd
                    ? 'owner_password_old-open'
                    : 'owner_password_old-hide'
                "
                @click="showPasswordConfirm"
              />
              <span
                v-for="error in errors['password_confirm']"
                :key="error"
                class="error-message owner"
              >
                {{ error }}
              </span>
            </div>
          </div>
        </div>

        <div class="input_owner">
          <label for="name" class="owner_form_label">法人名<span class="require">必須</span></label>
          <input
            v-model="name"
            type="text"
            placeholder="法人名を入力"
            class="owner_display_text"
          >
          <span
            v-for="error in errors['name']"
            :key="error"
            class="error-message owner"
          >
            {{ error }}
          </span>
        </div>
        <div class="input_owner">
          <label for="nameKana" class="owner_form_label">法人名（カナ）<span class="require">必須</span></label>
          <input
            v-model="nameKana"
            type="text"
            placeholder="法人名（カナ）を入力"
            class="owner_display_text"
          >
          <span
            v-for="error in errors['nameKana']"
            :key="error"
            class="error-message owner"
          >
            {{ error }}
          </span>
        </div>

        <div class="input_owner">
          <label for="directorName" class="owner_form_label">法人代表者名<span class="require">必須</span></label>
          <input
            v-model="directorName"
            type="text"
            placeholder="法人代表者名を入力"
            class="owner_display_text"
          >
          <span
            v-for="error in errors['directorName']"
            :key="error"
            class="error-message owner"
          >
            {{ error }}
          </span>
        </div>
        <div class="input_owner">
          <label for="directorNameKana" class="owner_form_label">法人代表者名（カナ）<span class="require">必須</span></label>
          <input
            v-model="directorNameKana"
            type="text"
            placeholder="法人代表者名（カナ）を入力"
            class="owner_display_text"
          >
          <span
            v-for="error in errors['directorNameKana']"
            :key="error"
            class="error-message owner"
          >
            {{ error }}
          </span>
        </div>

        <div class="input_owner">
          <label for="staffName" class="owner_form_label">担当者名<span class="require">必須</span></label>
          <input
            v-model="staffName"
            type="text"
            placeholder="担当者名を入力"
            class="owner_display_text"
          >
          <span
            v-for="error in errors['staffName']"
            :key="error"
            class="error-message owner"
          >
            {{ error }}
          </span>
        </div>
        <div class="input_owner">
          <label for="staffNameKana" class="owner_form_label">担当者名（カナ）<span class="require">必須</span></label>
          <input
            v-model="staffNameKana"
            type="text"
            placeholder="担当者名（カナ）を入力"
            class="owner_display_text"
          >
          <span
            v-for="error in errors['staffNameKana']"
            :key="error"
            class="error-message owner"
          >
            {{ error }}
          </span>
        </div>

        <div class="input_owner">
          <label for="departmentName" class="owner_form_label">部署名</label>
          <input
            v-model="departmentName"
            type="text"
            placeholder="部署名を入力"
            class="owner_display_text"
          >
          <span
            v-for="error in errors['departmentName']"
            :key="error"
            class="error-message owner"
          >
            {{ error }}
          </span>
        </div>

        <div class="input_owner width_input">
          <label for="postcode" class="owner_form_label">郵便番号<span class="require">必須</span></label>
          <input
            v-model="postcode"
            type="text"
            placeholder="郵便番号（ハイフン不要、半角のみ）を入力"
          >
          <span
            v-for="error in errors['postcode']"
            :key="error"
            class="error-message owner"
          >
            {{ error }}
          </span>
        </div>
        <div class="input_owner">
          <label class="owner_form_label">都道府県<span class="require">必須</span></label>
          <div class="owner_select_wrapper arrow_down medium">
            <select
              v-model="prefectureId"
              class="select_box_padding owner_select_shevron_down_none"
            >
              <option
                v-for="(prefecture, index) in prefectures"
                :key="index"
                :value="prefecture.code"
              >
                {{ prefecture.name }}
              </option>
            </select>
          </div>
          <span
            v-for="error in errors['prefectureId']"
            :key="error"
            class="error-message owner"
          >
            {{ error }}
          </span>
        </div>
        <div class="input_owner">
          <label for="city" class="owner_form_label">市区町村<span class="require">必須</span></label>
          <input
            v-model="city"
            type="text"
            placeholder="例：港区北青山"
            class="owner_display_text"
          >
          <span
            v-for="error in errors['city']"
            :key="error"
            class="error-message owner"
          >
            {{ error }}
          </span>
        </div>
        <div class="input_owner">
          <label for="address" class="owner_form_label">丁目・番地・号<span class="require">必須</span></label>
          <input
            v-model="address"
            type="text"
            placeholder="例：2-14-4"
            class="owner_display_text"
          >
          <span
            v-for="error in errors['address']"
            :key="error"
            class="error-message owner"
          >
            {{ error }}
          </span>
        </div>
        <div class="input_owner">
          <label for="buildingName" class="owner_form_label">ビル・マンション</label>
          <input
            v-model="buildingName"
            type="text"
            placeholder="ビル・マンションを入力"
            class="owner_display_text"
          >
          <span
            v-for="error in errors['building_name']"
            :key="error"
            class="error-message owner"
          >
            {{ error }}
          </span>
        </div>
        <div class="input_owner">
          <label for="email" class="owner_form_label">連絡先メールアドレス<span class="require">必須</span></label>
          <input
            v-model="email"
            type="text"
            placeholder="連絡先メールアドレスを入力"
          >
          <span
            v-for="error in errors['email']"
            :key="error"
            class="error-message owner"
          >
            {{ error }}
          </span>
        </div>
        <div class="input_owner">
          <label for="phone" class="owner_form_label">電話番号<span class="require">必須</span></label>
          <div class="width_input">
            <input
              v-model="phone"
              type="text"
              placeholder="電話番号（ハイフン不要、半角のみ）を入力"
            >
          </div>
          <span
            v-for="error in errors['phone']"
            :key="error"
            class="error-message owner"
          >
            {{ error }}
          </span>
        </div>

        <div class="input_owner">
          <label class="owner_form_label">登録日</label>
          <div class="owner_margin_inline_10 owner_color_black owner_color_black">
            {{ formatDate(owner.created_at) }}
          </div>
        </div>
      </form>

      <div class="btn_area_owner control two_items owner_btn_padding">
        <router-link
          :to="{}"
          class="btn_owner primary"
          @click.native="confirmUpdateOwner"
        >更新する</router-link>
        <router-link
          :to="{}"
          class="btn_owner primary_border ml_medium"
          @click.native="goBack()"
        >キャンセル</router-link>
      </div>
    </div>
  </main>
</template>

<script>
import Validator from '../../../validator';
import { OwnersService } from '../../../services/Owner/owners.service';
import { mapGetters } from 'vuex';
import utils from '../../../common/util';
import { AddressInfo, PopupDisplayError } from '../../../common/util';

export default {
  data() {
    return {
      owner: {},
      errors: {},
      name: null,
      nameKana: null,
      email: null,
      directorName: null,
      directorNameKana: null,
      staffName: null,
      staffNameKana: null,
      postcode: null,
      prefectureId: null,
      city: null,
      address: null,
      buildingName: null,
      phone: null,
      passwordConfirm: null,
      showOldPwd: false,
      showConfirmPwd: false,
      password: null,
      isActive: true,
      loginId: null,
      departmentName: null,
      slug: ''
    };
  },
  computed: {
    ...mapGetters({
      prefectures: 'getAllPrefectures',
    }),
  },
  watch: {
    postcode(val) {
      if (val === this.owner.postcode) {
        this.prefectureId = this.owner.prefecture_id;
        this.city = this.owner.city;
        this.address = this.owner.address;
      } else {
        AddressInfo(val, this.prefectures).then((address) => {
          this.prefectureId = address.code;
          this.city = address.city + address.address;
        });
      }
    },
  },

  async mounted() {
    await this.getOwners();
  },

  methods: {
    ...utils,

    isChangePassword() {
      this.isActive = false;
    },

    async getOwners() {
      try {
        const { response } = await OwnersService.index();
        this.owner = response.data;
        this.loginId = response.data.login_id;
        this.name = response.data.name;
        this.nameKana = response.data.name_kana;
        this.email = response.data.email;
        this.directorName = response.data.director_name;
        this.directorNameKana = response.data.director_name_kana;
        this.staffName = response.data.staff_name;
        this.staffNameKana = response.data.staff_name_kana;
        this.postcode = response.data.postcode;
        this.prefectureId = response.data.prefecture_id;
        this.city = response.data.city;
        this.address = response.data.address;
        this.buildingName = response.data.building_name;
        this.phone = response.data.phone;
        this.departmentName =  response.data.department_name;
        if (this.owner.media_name != null) { this.slug = this.owner.media_name };
      } catch (error) {
        console.log(error);
      }
    },

    confirmUpdateOwner: async function () {
      this.validate();
      if (Object.keys(this.errors).length) {
        this.scrollTopScreen();
        PopupDisplayError(this.$swal);
        return;
      }

      this.popupconfirmUpdateOwner();
    },

    async popupconfirmUpdateOwner() {
      const confirm = await this.$swal.fire({
        title: '確認',
        html: `<p>法人情報を変更します。</p>
               <p>よろしいですか？</p>`,
        confirmButtonText: '決定する',
        cancelButtonText: 'キャンセル',
        showCloseButton: false,
        showCancelButton: true,
        customClass: {
          confirmButton: 'confirm-popup-owner',
          title: 'title-popup-owner',
          cancelButton: 'cancel-popup-owner',
          htmlContainer: 'html-popup-owner',
        },
      });
      if (confirm.isConfirmed) {
        try {
          const params = {
            login_id: this.loginId,
            name: this.name,
            name_kana: this.nameKana,
            email: this.email,
            director_name: this.directorName,
            director_name_kana: this.directorNameKana,
            staff_name: this.staffName,
            staff_name_kana: this.staffNameKana,
            prefecture_id: this.prefectureId,
            postcode: this.postcode,
            city: this.city,
            address: this.address,
            building_name: this.buildingName,
            phone: this.phone,
            password: this.password,
            password_confirmation: this.passwordConfirm,
            department_name: this.departmentName,
          };
          if (this.password === null) {
            delete params['password'];
            delete params['password_confirmation'];
          }
          await OwnersService.update(params);
          return this.$router.push(`/${this.slug}/owner/detail`);
        } catch (error) {
          console.log(error);
          this.scrollTopScreen();
          PopupDisplayError(this.$swal);
        }
      }
    },

    showPasswordOld() {
      this.showOldPwd = !this.showOldPwd;
      const passwordField = document.querySelector('#password_old');
      if (passwordField.getAttribute('type') === 'password')
        passwordField.setAttribute('type', 'text');
      else passwordField.setAttribute('type', 'password');
    },

    scrollTopScreen() {
      window.scrollTo(0, 0);
      const elmnt = document.getElementById('body_wrapper_owner');
      elmnt.scrollTo(0, 0);
    },

    showPasswordConfirm() {
      this.showConfirmPwd = !this.showConfirmPwd;
      const passwordField = document.querySelector('#password_confirm');
      if (passwordField.getAttribute('type') === 'password')
        passwordField.setAttribute('type', 'text');
      else passwordField.setAttribute('type', 'password');
    },

    validate() {
      const validator = new Validator();

      validator.checkRequire('name', this.name, '法人名') &&
        validator.checkMaxLength('name', this.name, 255, '法人名');

      validator.checkRequire('nameKana', this.nameKana, '法人名（カナ）') &&
        validator.checkMaxLength('nameKana', this.nameKana, 255, '法人名（カナ）') &&
        validator.checkKatakana('nameKana', this.nameKana, '法人名（カナ）');

      validator.checkRequire('directorName', this.directorName, '法人代表者名') &&
        validator.checkMaxLength('directorName', this.directorName, 255, '法人代表者名');

      validator.checkRequire('directorNameKana', this.directorNameKana, '法人代表者名（カナ）') &&
        validator.checkMaxLength('directorNameKana', this.directorNameKana, 255, '法人代表者名（カナ）') &&
        validator.checkKatakana('directorNameKana', this.directorNameKana, '法人代表者名（カナ）');

      validator.checkRequire('staffName', this.staffName, '担当者名') &&
        validator.checkMaxLength('staffName', this.staffName, 255, '担当者名');

      validator.checkRequire('staffNameKana', this.staffNameKana, '担当者名（カナ）') &&
        validator.checkKatakana( 'staffNameKana', this.staffNameKana, '担当者名（カナ）') &&
        validator.checkMaxLength('staffNameKana', this.staffNameKana, 255, '担当者名（カナ）');

      validator.checkRequire('postcode', this.postcode, '郵便番号') &&
        validator.checkMaxLength('postcode', this.postcode, 7, '郵便番号') &&
        validator.checkPostCode('postcode', this.postcode, '郵便番号');

      validator.checkRequire('prefectureId', this.prefectureId, '都道府県');

      validator.checkRequire('city', this.city, '市区町村') &&
        validator.checkMaxLength('city', this.city, 255, '市区町村');

      validator.checkRequire('address', this.address, '丁目・番地・号') &&
        validator.checkMaxLength('address', this.address, 255, '丁目・番地・号');

      validator.checkRequire('phone', this.phone, '電話番号');
      if (this.phone) {
        const phone = this.phone;
        validator.checkTel('phone', phone, '電話番号') &&
          validator.checkMaxLength('phone', phone, 11, '電話番号') &&
          validator.checkMinLength('phone', phone, 10, '電話番号');
        if (!validator.errors.phone) {
          validator.checkPhone('phone', this.phone, '電話番号');
        }
      }

      validator.checkMaxLength('buildingName', this.buildingName, 255, 'ビール・マンション');
      validator.checkMaxLength('departmentName', this.departmentName, 255, '部署名');

      validator.checkRequire('email', this.email, '連絡先メールアドレス') &&
        validator.checkEmail('email', this.email, '連絡先メールアドレス');

      if (this.isActive === false) {
        if (
          (this.password === null && this.passwordConfirm === null) ||
          (this.password === '' && this.passwordConfirm === '')
        ) {
          this.errors = validator.errors;
        } else {
          validator.checkAsciiWithoutSpace('password', this.password, '新しいパスワード') &&
            validator.checkMinLength('password', this.password, 8, '新しいパスワード') &&
            validator.checkMaxLength('password', this.password, 50, '新しいパスワード');

          validator.checkRequire('password_confirm', this.passwordConfirm, '新しいパスワード（確認）') &&
            validator.checkSameValue('password_confirm', this.passwordConfirm, this.password, '新しいパスワード（確認）');

          this.errors = validator.errors;
        }
      }
      else {
        this.errors = validator.errors;
      }
    },
    goBack() {
      return this.$router.push(`/${this.slug}/owner/detail`);
    },
  },
};
</script>
<style lang="scss" scoped>
.btn_area_owner.control {
  width: 95%;
}

@media  screen  and (max-width: 1361px){
  .bg_white{
    background: white;
  }
}
</style>

