export const validationMessages ={
    BLANK_RESERVATION_DATE: "「ご予約日」を選択してください。",
    BLANK_RESERVATION_TIME: "「予約時間」を選択してください。",
    BlANK_NUMBER_OF_PEOPLE: "「予約人数」を入力してください。",
    MAX_NUMBER_OF_PEOPLE: "「予約人数」は大人/子供、合計48人以下にしてください。",
    BLANK_COURSE: "コースが選択されていません。",
    NO_SELECTED_SEATS: "テーブルが選択されていません。テーブルを選択してください。",
    BLANK_FIRST_NAME: "あなたの名前を入力してください",
    BLANK_LAST_NAME: "あなたの姓を入力してください",
    BLANK_NAME: "名前を入力してください",
    BLANK_PHONE: "電話番号を入力してください",
    NAME_FORMAT: "あお名前(ひらがな)めいはひらがなだけで入力し_てください。",
    TOO_LONG_FIRST_NAME: "名は255文字以下である必要があります。",
    NO_ADULTS_SELECTED: '「予約人数」の「大人」を選択してください。',
    SELECT_TOTAL_NUMBER_OF_PEOPLE: '「予約人数」を選択してください。',
    TOO_LONG_LAST_NAME: "姓は255文字以下で入力してください。",
    TOO_LONG_COMPANY_NAME: "会社名は255文字以下で入力してください。",
    INVALID_FIRST_NAME_KANA: "お名前(姓カナ)は全角カタカナで入力してください。",
    INVALID_LAST_NAME_KANA: "お名前(名カナ)は全角カタカナで入力してください。",
    INVALID_NAME_KANA: "お名前(カナ)は全角カタカナで入力してください。",
    SELECTED_TABLE_WITH_EXISTING_RESERVATION: "選択された座席はすでに予約されています",
    MAX_PEOPLE_EXCEEDED: "テーブルは予約人数に対しテーブルの席数が足りません。",
    RESERVATION_EXCEEDED: "予約人数が席の収容人数を超えています。 席を見直してください。",
    MINIMUM_ADULTS: "大人はゼロにはなれない。"
}