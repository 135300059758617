<template>
  <div class="owner_main_area">
    <h2 class="mb_medium">店舗の新規登録</h2>
    <form>
      <div class="form_row vertical mb_medium">
        <label class="form_label">紹介画像</label>
        <div class="owner_input_wrapper">
          <div v-show="createForm.image" class="file_upload_image">
            <img class="image_shop" :src="createForm.image">
          </div>
          <div v-show="!createForm.image" class="file_upload_image no_image">
            <div class="file_upload_image_main">
              <div class="add_image_shop" />
            </div>
          </div>
          <div class="btn_area two_items two_items_image">
            <button class="btn primary btn_row btn_upload" type="button" @click="$refs.file.click()">画像をアップロード</button>
            <button v-show="createForm.image" class="btn primary btn_row btn_clear" type="button" @click="deleteImage" >画像をクリアする</button>
          </div>
          <div>
            <input id="file_uploader" ref="file" type="file"
                  class="file_uploader"
                  accept="image/x-png,image/gif,image/jpeg,image/jpg,image/tiff,image/bmp"
                  hidden @change="previewFiles"
            >
          </div>
        </div>
        <div>
          <p class="owner_text_note form_text_note_margin">予約ページに表示するお店の画像をアップロードします。</p>
          <p class="owner_text_note form_text_note_margin">目安：容量2MBまで、サイズ 600px × 400pxまで、形式 jpeg, gif, png</p>
        </div>
        <span v-for="error in errors['image']" :key="error" class="error-message owner">
          {{ error }}
        </span>
      </div>
      <div class="form_row vertical mb_medium">
        <label class="form_label">店舗名<span class="require">必須</span></label>
        <div class="owner_input_wrapper">
          <input v-model="createForm.name" type="text"
                 placeholder="店舗名を入力"
          >
          <span v-for="error in errors['name']" :key="error" class="error-message owner">
            {{ error }}
          </span>
        </div>
      </div>
      <div class="form_row vertical mb_medium">
        <label class="form_label">店舗名（カナ）<span class="require">必須</span></label>
        <div class="owner_input_wrapper">
          <input v-model="createForm.name_kana" type="text"
                 placeholder="店舗名（カナ）を入力"
          >
          <span v-for="error in errors['name_kana']" :key="error" class="error-message owner">
            {{ error }}
          </span>
        </div>
      </div>
      <div class="form_row vertical mb_medium">
        <label class="form_label">電話番号<span class="require">必須</span></label>
        <div class="owner_input_wrapper medium">
          <input v-model="createForm.tel" type="text"
                 placeholder="電話番号（ハイフン不要、半角のみ）を入力"
          >
          <span v-for="error in errors['tel']" :key="error" class="error-message owner">
            {{ error }}
          </span>
        </div>
      </div>
      <div class="form_row vertical mb_medium">
        <label class="form_label">郵便番号<span class="require">必須</span></label>
        <div class="owner_input_wrapper medium">
          <input v-model="createForm.zipcode" type="text"
                 placeholder="郵便番号（ハイフン不要、半角のみ）を入力"
          >
          <span v-for="error in errors['zipcode']" :key="error" class="error-message owner">
            {{ error }}
          </span>
        </div>
      </div>
      <div class="form_row vertical mb_medium">
        <label class="form_label">都道府県<span class="require">必須</span></label>
        <div class="owner_select_wrapper arrow_down medium">
          <select
            v-model="createForm.prefecture_id"
            class="select_box_padding owner_select_shevron_down_none"
          >
            <option v-for="option in prefectures" :key="option.value" :value="option.code">{{ option.name }}</option>
          </select>
        </div>
        <span v-for="error in errors['prefecture_id']" :key="error" class="error-message owner">
          {{ error }}
        </span>
      </div>
      <div class="form_row vertical mb_medium">
        <label class="form_label">市区町村番地<span class="require">必須</span></label>
        <div class="owner_input_wrapper">
          <input v-model="createForm.address" type="text"
                 placeholder="市区町村番地を入力"
          >
          <span v-for="error in errors['address']" :key="error" class="error-message owner">
            {{ error }}
          </span>
        </div>
      </div>
      <div class="form_row vertical mb_medium">
        <label for="access" class="form_label">アクセス<span class="require">必須</span></label>
        <div class="owner_input_wrapper">
          <input v-model="createForm.access" type="text"
                 placeholder="アクセス情報を入力"
          >
          <span v-for="error in errors['access']" :key="error" class="error-message owner">
            {{ error }}
          </span>
        </div>
      </div>
      <div class="form_row vertical mb_medium">
        <label for="official_url" class="form_label">店舗URL</label>
        <div class="owner_input_wrapper">
          <input v-model="createForm.official_url" type="text"
                 placeholder="店舗URLを入力"
          >
          <span v-for="error in errors['official_url']" :key="error" class="error-message owner">
            {{ error }}
          </span>
        </div>
      </div>
      <div class="form_row vertical mb_medium">
        <label for="instagram_name" class="form_label">Instagramのユーザーネーム</label>
        <div class="owner_input_wrapper medium">
          <input v-model="createForm.instagram_name" type="text"
                 placeholder="Instagramのユーザーネームを入力"
          >
          <p class="owner_text_note form_text_note_margin">席予約ボタンを設置する店舗のInstagramのユーザーネームを記入してください。</p>
          <span v-for="error in errors['instagram_name']" :key="error" class="error-message owner">
            {{ error }}
          </span>
        </div>
      </div>
      <div class="form_row vertical mb_medium">
        <label for="desc_header" class="form_label">店舗紹介タイトル</label>
        <div class="owner_input_wrapper">
          <input v-model="createForm.desc_header" type="text"
                 placeholder="店舗タイトルを入力"
          >
          <span v-for="error in errors['desc_header']" :key="error" class="error-message owner">
            {{ error }}
          </span>
        </div>
      </div>
      <div class="form_row vertical mb_medium">
        <label for="desc_content" class="form_label">店舗紹介本文</label>
        <div class="owner_input_wrapper">
          <textarea v-model="createForm.desc_content" placeholder="店舗紹介本文を入力" rows="3" />
          <span v-for="error in errors['desc_content']" :key="error" class="error-message owner">
            {{ error }}
          </span>
        </div>
      </div>
      <div class="form_row vertical mb_medium">
        <label class="form_label">平均単価<span class="require">必須</span></label>
        <div class="owner_input_wrapper medium">
          <input v-model="createForm.shop_prices.price" type="tel"
                 placeholder="平均単価を入力"
          >
          <p class="owner_text_note form_text_note_margin">数字のみでご入力ください。複数の予算帯がある場合は平均予算をご記載ください。例：3000</p>
          <span v-for="error in errors['price']" :key="error" class="error-message owner">
            {{ error }}
          </span>
        </div>
      </div>
      <div class="form_row vertical mb_medium">
        <label class="form_label">カテゴリー<span class="require">必須</span></label>
        <div class="owner_select_wrapper arrow_down medium">
          <select
            v-model="createForm.shop_tags.tag_id"
            class="select_box_padding owner_select_shevron_down_none"
          >
            <option v-for="option in tag" :key="option.value" :value="option.id">{{ option.name }}</option>
          </select>
        </div>
        <span v-for="error in errors['tag_id']" :key="error" class="error-message owner">
          {{ error }}
        </span>
      </div>
      <div class="form_row vertical mb_12">
        <label class="form_label">店舗ID<span class="require">必須</span></label>
        <div class="owner_input_wrapper medium">
          <div class="flex alignCenter">
            <input v-model="createForm.login_id" type="text"
                   placeholder="店舗IDを入力"
                   autocomplete="off"
            >
          </div>
          <p class="owner_text_note form_text_note_margin">オーナーIDとは別のIDを登録してください。半角英数字6文字以上で入力してください。</p>
          <span v-for="error in errors['login_id']" :key="error" class="error-message owner">
            {{ error }}
          </span>
        </div>
      </div>
      <div class="form_row vertical mb_12">
        <label class="form_label">店舗パスワード<span class="require">必須</span></label>
        <div class="owner_input_wrapper medium">
          <input id="password_old" v-model="createForm.password" type="password"
                 class="owner_input_pwd"
                 placeholder="店舗管理ユーザーパスワードを入力"
                 autocomplete="new-password"
          >
          <div class="owner_password_old" :class="showOldPwd ? 'owner_password_old-open' : 'owner_password_old-hide'" @click="showPasswordOld" />
          <p class="owner_text_note form_text_note_margin">半角英数字8文字以上で入力してください</p>
          <span v-for="error in errors['password']" :key="error" class="error-message owner">
            {{ error }}
          </span>
        </div>
      </div>
      <div class="form_row vertical mb_medium">
        <label class="form_label">店舗パスワード（確認用）<span class="require">必須</span></label>
        <div class="owner_input_wrapper medium">
          <input id="password_confirm" v-model="passwordConfirm" type="password"
                 class="owner_input_pwd"
                 placeholder="店舗管理ユーザーパスワード（確認）を入力"
          >
          <div class="owner_password_old" :class="showConfirmPwd ? 'owner_password_old-open' : 'owner_password_old-hide'" @click="showPasswordConfirm" />
          <span v-for="error in errors['password_confirm']" :key="error" class="error-message owner">
            {{ error }}
          </span>
        </div>
      </div>
    </form>
    <div class="owner_text_note_submit">
      <p v-if="restaurantAdministrators.length > 0" class="owner_text_note text_note_mb">※店舗を追加するとご利用料金が追加になります。</p>
    </div>
    <div class="btn_area_owner two_items owner_text_note_submit">
      <button class="btn btn_owner primary owner_font_16 btn_pointer"
              @click="handleSave"
      >
        確認する
      </button>
      <button class="btn btn_owner primary_border owner_font_16 btn_pointer ml_medium" @click="redirectBack">キャンセル</button>
    </div>
  </div>
</template>

<script>
import Validator from '../../../../validator';
import { CorrectNumber } from '../../../../common/util';
import { mapGetters } from 'vuex';
import { ShopAdministratorsService } from '../../../../services/Owner/shop_administrators.service';
import { AddressInfo, PopupDisplayError } from '../../../../common/util';

export default {
  props: {
    restaurantAdministrators: {
      type: Array,
      default: () => []
    },
    tag: {
      type: Array,
      default: () => []
    },
    slug: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      imageError: false,
      errors: [],
      showOldPwd: false,
      showConfirmPwd: false,
      passwordConfirm: ''
    };
  },
  computed: {
    ...mapGetters({
      createForm: 'getCreateForm',
      prefectures: 'getAllPrefectures',
    })
  },

  watch: {
    'createForm.zipcode'(val) {
      if (!val || val.length !== 7) return;

      AddressInfo(val, this.prefectures).then(addressInfo => {
        this.createForm.prefecture_id = addressInfo.code;
        this.createForm.address = addressInfo.city + addressInfo.address;
      });
    },
  },

  methods: {
    async handleSave() {
      this.validate();

      if (Object.keys(this.errors).length) {
        this.scrollTopScreen();
        PopupDisplayError(this.$swal);
        return;
      }

      const params = Object.assign({}, this.createForm);
      params['login_id' ] = this.createForm.login_id;
      try {
        await ShopAdministratorsService.confirm_register(params);
        this.$emit('change_screen', 'confirm');
      } catch (error) {
        this.errors = error.response;
        this.scrollTopScreen();
        PopupDisplayError(this.$swal);
      }
    },

    showPasswordOld() {
      this.showOldPwd = !this.showOldPwd;
      const passwordField = document.querySelector('#password_old');
      if (passwordField.getAttribute('type') === 'password') passwordField.setAttribute('type', 'text');
      else passwordField.setAttribute('type', 'password');
    },
    showPasswordConfirm() {
      this.showConfirmPwd = !this.showConfirmPwd;
      const passwordField = document.querySelector('#password_confirm');
      if (passwordField.getAttribute('type') === 'password') passwordField.setAttribute('type', 'text');
      else passwordField.setAttribute('type', 'password');
    },

    scrollTopScreen() {
      window.scrollTo(0, 0);
      const elmnt = document.getElementById('body_wrapper_owner');
      elmnt.scrollTo(0, 0);
    },
    deleteImage() {
      this.createForm.image = null;
      document.getElementById('file_uploader').value = null;
    },

    validate() {
      const validator = new Validator();
      validator.checkRequire('name', this.createForm.name, '店舗名') &&
        validator.checkMaxLength('name', this.createForm.name, 255, '店舗名');

      validator.checkRequire('name_kana', this.createForm.name_kana, '店舗名（カナ）') &&
        validator.checkKatakana('name_kana', this.createForm.name_kana, '店舗名（カナ）') &&
        validator.checkMaxLength('name_kana', this.createForm.name_kana, 255, '店舗名（カナ）');

      validator.checkRequire('zipcode', this.createForm.zipcode, '郵便番号')&&
        validator.checkMaxLength('zipcode', this.createForm.zipcode, 7, '郵便番号')&&
        validator.checkPostCode('zipcode', this.createForm.zipcode, '郵便番号');

      validator.checkRequire('prefecture_id', this.createForm.prefecture_id, '都道府県');

      validator.checkRequire('address', this.createForm.address, '丁目・番地・号') &&
        validator.checkMaxLength('address', this.createForm.address, 255, '丁目・番地・号');

      validator.checkRequire('access', this.createForm.access, 'アクセス') &&
        validator.checkMaxLength('access', this.createForm.access, 255, 'アクセス');

      if(this.createForm.official_url){
        validator.checkMaxLength('official_url', this.createForm.official_url, 255, '店舗URL')&&
          validator.checkUrl('official_url',this.createForm.official_url,'店舗URL');
      }

      if(this.createForm.desc_header){
        validator.checkMaxLength('desc_header', this.createForm.desc_header, 255, '店舗紹介タイトル');
      }

      if(this.createForm.desc_content){
        validator.checkMaxLength('desc_content', this.createForm.desc_content, 255, '店舗紹介本文');
      }

      if(this.createForm.instagram_name){
        validator.checkMaxLength('instagram_name', this.createForm.instagram_name, 30, 'Instagramのユーザーネーム')&&
          validator.checkInstagram('instagram_name', this.createForm.instagram_name, 'Instagramのユーザーネーム');
      }
      
      validator.checkRequire('tag_id', this.createForm.shop_tags.tag_id, 'カテゴリー');

      const tel =  this.createForm.tel;
      validator.checkRequire('tel', tel, '電話番号') &&
        validator.checkMaxLength('tel', CorrectNumber(tel), 11, '電話番号') &&
        validator.checkMinLength('tel', CorrectNumber(tel), 10, '電話番号') &&
        validator.checkTel('tel', tel, '電話番号');
      if (!validator.errors.tel) {
        validator.checkTel('tel', CorrectNumber(this.createForm.tel), '電話番号');
      }

      validator.checkRequire('price', this.createForm.shop_prices.price, '平均単価') &&
        validator.checkDigit('price', this.createForm.shop_prices.price, '平均単価') &&
        validator.checkMaxNumber('price', this.createForm.shop_prices.price, 9, '平均単価') &&
        validator.checkGreaterThan('price', this.createForm.shop_prices.price, 1, '平均単価');

      validator.checkRequire('login_id', this.createForm.login_id, '店舗ID') &&
        validator.checkAlphanumeric('login_id', this.createForm.login_id, '店舗ID')&&
        validator.checkMinLength('login_id', this.createForm.login_id, 6, '店舗ID') &&
        validator.checkMaxLength('login_id', this.createForm.login_id, 250, '店舗ID');

      validator.checkRequire('password', this.createForm.password, '店舗パスワード') &&
        validator.checkAsciiWithoutSpace('password', this.createForm.password, '店舗パスワード')&&
        validator.checkMinLength('password', this.createForm.password, 8, '店舗パスワード') &&
        validator.checkMaxLength('password', this.createForm.password, 50, '店舗パスワード');

      validator.checkRequire('password_confirm', this.passwordConfirm, '店舗パスワード（確認用）') &&
        validator.checkSameValue('password_confirm', this.passwordConfirm, this.createForm.password, '店舗パスワード（確認用）');

      if (this.imageError) {
        validator.errors['image'] = [];
        validator.errors['image'].push('添付ファイルのサイズが許容制限エラーを超えています。');
      }

      this.errors = validator.errors;
    },
    previewFiles(event) {
      if (event.target.files.length < 1) {
        return;
      }
      this.imageError = false;
      const file = event.target.files[0];
      if (file.size > 3 * 1000 * 1000) { // 3MB is max size
        this.imageError = true;
      }
      this.createBase64Image(file);
    },
    createBase64Image(file) {
      this.createForm.image_name = file.name;

      const reader = new FileReader();

      reader.onload = (e) => {
        this.createForm.image = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    redirectBack() {
      this.$store.commit('CLEAR_STATE_RESTAURANTS_OWNER');
      return this.$router.push(`/${this.slug}/owner`);
    }
  }
};
</script>

<style scoped>
.btn.primary.btn_row.btn_clear{
  width: 170px;
  margin-left: 10px;
  background-color: #ffffff;
  color: #009BDC;
  border: 2px solid #009BDC !important;
}
.btn.primary.btn_row.btn_upload{
  width: 170px;
}
.mb_12 {
  margin-bottom: 12px;
}
.text_note_mb {
  margin-bottom: 6px;
}
.two_items_image {
  justify-content: unset;
  width: 90%;
  margin: unset;
  margin-top: 16px;
}
.btn_area.two_items .btn {
  max-width: 200px;
}
.file_upload_image img {
  object-fit: cover !important;
}
.file_upload_image.no_image {
  border: unset;
  cursor: unset;
  position: relative;
}
.flex {
  flex: unset
}
.btn_area {
  height: 50px;
}
.image_shop {
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 480px) {
  .btn_area.two_items {
    display: block;
    height: unset;
  }
  .btn_upload {
    height: 50px;
  }
  .btn_clear {
    height: 50px;
    margin-left: 0px !important;
    margin-top: 10px;
  }
  .btn_area_owner.two_items {
    margin-left: 15%;
  }
}
</style>
