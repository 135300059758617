<template>
  <div class="owner_main_area">
    <h1 class="title_owner mb_medium">店舗の新規登録</h1>
    <p class="note_sp mb_large">
      店舗の新規登録と店舗アカウントの登録が完了しました。
    </p>
    <div class="btn_area_owner three_items owner_btn_padding">
      <router-link
        :to="{ }"
        class="btn btn_owner primary"
        @click.native="back"
      >
        続けて店舗を登録する
      </router-link>
      <router-link
         :to="{ path: slug == '' ? `/owner` : `/${slug}/owner`}"
        class="btn btn_owner primary_border ml_medium"
      >
        店舗管理画面へ
      </router-link>
      <router-link :to="{}" class="btn btn_owner dark_orange ml_medium" @click.native="toTop">予約管理画面へ</router-link>
    </div>
  </div>
</template>

<script>
import { ChangeShopService } from '../../../../services/Owner/change_shop.service';

export default {
  props: {
    restaurant: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {
      slug: this.restaurant.media_name == null ? '' : this.restaurant.media_name
    }
  },

  methods: {
    async toTop() {
      if (this.restaurant.media_name != null) { this.slug = this.restaurant.media_name };
      await ChangeShopService.changeShop({ shop_id: this.restaurant.id});

      const linkShop = this.slug == '' ? '/shop_manager/dashboard' : `/${this.slug}/shop_manager/dashboard`

      window.location.href = linkShop;
    },
    back() {
      this.$store.commit('CLEAR_STATE_RESTAURANTS_OWNER');
      this.$emit('change_screen', 'create');
      const linkShop = this.slug == '' ? '/owner/shop/create' : `/${this.slug}/owner/shop/create`;

      window.location.href = linkShop;
    }
  }
};
</script>

<style scoped>
a {
  cursor: pointer;
}
</style>
