<template>
  <div
    v-show="loading"
    class="loading-screen"
  >
    <v-loading :active="true" />
  </div>
</template>
<script>
import Vue from 'vue';
import vLoading from 'vue-loading-overlay';

Vue.component('v-loading', vLoading);

export default {
  data() {
    return {
      loading: false,
    };
  }};
</script>
<style scoped>
.loading-screen {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 300;
  flex-direction: column;
  user-select: none;
  background-color: rgba(255, 255, 255, 0);
}
</style>
