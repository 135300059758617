import { BaseService } from '../base.service';

export class ChangeShopService extends BaseService {
  static get resourceEndpoint() {
    return 'owners/change_shop';
  }

  static async changeShop(parameters = {}) {
    const params = { ...parameters };
    try {
      const response = await this.request().get(
        `${this.resourceEndpoint}`,
        { params }
      );
      return new ResponseWrapper(response);
    } catch (error) {
    }
  }
}