import { render, staticRenderFns } from "./ModalDelete.vue?vue&type=template&id=5f81943a&scoped=true&"
import script from "./ModalDelete.vue?vue&type=script&lang=js&"
export * from "./ModalDelete.vue?vue&type=script&lang=js&"
import style0 from "./ModalDelete.vue?vue&type=style&index=0&id=5f81943a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../shared/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f81943a",
  null
  
)

export default component.exports