<template>
  <div class="recording-indicator">
    <div :class="active?'icon-outer-wrapper recording-active':'icon-outer-wrapper'">
      <div class="icon-inner-wrapper">
        <i v-if="active" class="fa fa-microphone microphone-icon" aria-hidden="true"></i>
        <i v-else class="fa fa-microphone-slash microphone-icon" aria-hidden="true"></i>

      </div>
    </div>
    <span v-if="active" style="color: #b85450">録音中</span>
    <span v-else style="color: #b85450">録音は進行中ではありません</span>

  </div>
</template>
<script>
export default {
  name: 'RecordingIndicator',
  props:{
    active: {
      type: Boolean,
      required: true
    },
  }
}
</script>
<style scoped>
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
.recording-indicator{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  border: solid 1px grey;
  background-color: #FFCCCC;
  border-radius: 16px;
  width: 240px;
  height: 48px;
  padding: 4px 6px;
}
.icon-outer-wrapper{
  border: solid #b85450 1px;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.recording-active{
  animation: pulse 1s infinite;
}
.icon-inner-wrapper{
  background-color: #b85450;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.microphone-icon{
  color: white;
}
</style>