<template>
  <div class="reservation-full-screen">
    <Header reservation-type="reservation"/>
    <div class="content">
      <router-view></router-view>
    </div>
    <Footer :is-recording-active="isRecording" @register-reservation="stopRecording" @confirm-reservation="confirmReservation" />
  </div>
</template>

<script>
import Footer from "./Footer.vue";
import Header from "./Header.vue";
import { v4 as uuidv4 } from 'uuid';
import stores from "../../stores";
import Http from "../../../shared/http";

export default {
  name: 'ReservationsLayout',
  components: { Header, Footer },
  data() {
    return {
      uniqueId: '',
      isRecording: true,
      recorder: null,
      audioChunks: [],
      chunkLength: 3 * 10000, // in milliseconds
    };
  },
  mounted() {
    console.log('mounted')
    this.checkMicrophonePermissions();
    const routePath = this.$route.path;
    const step = routePath.split('/').pop();
    if (parseInt(step) === 1) {
      stores.commit('CLEAR_STATE_ACTIVE_RESERVATION', {});
      if (this.$route.query.t) {
        stores.commit('SET_RESERVATION_TYPE', 'walk-in');
        stores.commit('SET_DATA', { date: this.$route.query.date });
        stores.commit('SET_DATA', { selected_date: this.$route.query.date });
      }
      this.uniqueId = uuidv4();
      stores.commit('SET_UUID', this.uniqueId);
      stores.commit('SET_STEP', parseInt(step));
    } else {
      this.isRecording = false;
    }
  },
  watch: {
    isRecording(newVal) {
      if (!newVal) {
        this.sendFinalBlobToBackend();
      }
    }
  },
  beforeDestroy() {
    if (this.isRecording) {
      this.stopRecording();
    }
  },
  methods: {
    checkMicrophonePermissions() {
      if (navigator.permissions) {
        navigator.permissions.query({name: 'microphone'}).then((permissionStatus) => {
          if (permissionStatus.state === 'denied') {
            this.isRecording = false;
          } else {
            this.startRecording();
          }
          permissionStatus.onchange = () => {
            if (permissionStatus.state === 'denied') {
              this.isRecording = false;
            } else {
              this.startRecording();
            }
          };
        }).catch((error) => {
          console.error('Error checking microphone permissions:', error);
        });
      } else {
        console.log('Permissions API is not supported in this browser.');
      }
    },
    startRecording() {
      this.isRecording = true;
      this.audioChunks = [];
      navigator.mediaDevices.getUserMedia({audio: true, video: false})
          .then(stream => {
            this.recorder = new MediaRecorder(stream);
            this.recorder.ondataavailable = this.handleChunk;
            this.recorder.start(this.chunkLength);
          })
          .catch(err => {
            console.error('Error accessing microphone:', err);
          });
    },
    stopRecording() {
      if (this.isRecording) {
        this.isRecording = false;
        if (this.recorder !== null) {
          this.recorder.stop();
        }
      }
    },
    handleChunk(e) {
      const url = URL.createObjectURL(e.data);
      this.audioChunks.push(url);
      this.sendBlobToBackend(e.data, this.audioChunks.length);
    },
    confirmReservation() {
      this.stopRecording();
      stores.commit('CLEAR_STATE_ACTIVE_RESERVATION');
      window.location.href = `/shop_manager/time_table?date=${this.$route.query.date}`;
    },
    async handleStop() {
      const uncompletedUploads = localStorage.getItem('uncompleted_uploads');
      if (uncompletedUploads !== null) {
        await JSON.parse(uncompletedUploads).map(async audio => {
          try {
            const formData = new FormData();
            const blobResponse = await fetch(this.audioChunks[audio.idx - 1]).then(response => response.blob());
            formData.append('file', blobResponse, audio.name);
            await Http.post('/shop_manager/time_table/reservation/upload_audio_blob', formData, {
              headers: {'Content-Type': 'multipart/form-data'}
            });
          } catch (error) {
            console.error('Failed to upload data again. This recording might be corrupted', error);
          }
        });
        localStorage.removeItem('uncompleted_uploads');
      }
    },
    async sendFinalBlobToBackend() {
      const formData = new FormData();
      const filename = `${this.uniqueId}_final`;
      const blobResponse = await fetch(this.audioChunks[this.audioChunks.length - 1]).then(response => response.blob());
      formData.append('file', blobResponse, filename);
      formData.append('clip_type', "create")
      formData.append('reservation_uuid', this.uniqueId)
      try {
        const response = await Http.post(`/shop_manager/time_table/reservation/upload_audio_blob`, formData, {
          headers: {'Content-Type': 'multipart/form-data'}
        });
        console.log('Final blob uploaded successfully:', response.data);
      } catch (error) {
        console.error('Error uploading final blob:', error);
        const uncompletedUploads = JSON.parse(localStorage.getItem('uncompleted_uploads')) || [];
        uncompletedUploads.push({name: filename, idx: this.audioChunks.length});
        localStorage.setItem('uncompleted_uploads', JSON.stringify(uncompletedUploads));
      }
    },
    async sendBlobToBackend(blob, index) {
      const formData = new FormData();
      const filename = `${this.uniqueId}_${index}`;
      formData.append('file', blob, filename);
      formData.append('clip_type', "create")
      formData.append('reservation_uuid', this.uniqueId)
      try {
        const response = await Http.post(`/shop_manager/time_table/reservation/upload_audio_blob`, formData, {
          headers: {'Content-Type': 'multipart/form-data'}
        });
        console.log('Blob uploaded successfully:', response.data);
      } catch (error) {
        console.error('Error uploading blob:', error);
        const uncompletedUploads = JSON.parse(localStorage.getItem('uncompleted_uploads')) || [];
        uncompletedUploads.push({name: filename, idx: index});
        localStorage.setItem('uncompleted_uploads', JSON.stringify(uncompletedUploads));
      }
    },
  }
}
</script>

<style scoped>
.reservation-full-screen {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 2000;
  max-height: 100dvh;
  left: 0;
  top: 0;
  overflow: unset;
  background-color: var(--main_opacity5);
}

.content {
  width: 100%;
  height: 85dvh;
  display: flex;
  justify-content: space-evenly;
  overflow: scroll;
}
</style>