<template>
  <div class="top-btn-group d-flex flex-row align-items-center">
    <div class="ml-2 notice d-flex ml-auto align-items-center py-1">
      <div class="popover fade show bs-popover-bottom" :style="{left: popLeft>0 ?-(popLeft+20): '-19px'}" v-if="showReservationColor">
        <div class="arrow" :style="{left: popLeft>0 ?popLeft: '-2px'}" ></div>
        <div class="label">
          <a>ラベル色選択 {{popLeft}}</a>
        </div>
        <div >
          <div class="content-div">
            <div class="color-container" v-for="color in colorCodes">
              <div >
                <div  @click="saveColor(color)" class="color-div cursor-pointer" :style="{backgroundColor:color}"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="backdrop" v-if="showReservationColor" @click="onClose"></div>
  </div>
</template>

<script >
import Http from "../../../shared/http";

export default {
  props:{
    showReservationColor: {
      type: Boolean
    },
    reservationId: {
      type: Number
    },
    backGroundColor: {
      type: String
    },
    popLeft:{
      type:Number
    }
  },
  data(){
    return{
      selectedColor:'',
      colorCodes:[
        'var(--color-tt-message)',
        'var(--color-error)',
        'var(--color-status)',
        'var(--color-tt-course)',
        'var(--gray)',
      ],
    }
  },
  watch: {
    backGroundColor: {
      immediate: true,
      handler(r) {
        this.selectedColor = r === null ? '' : r
      }
    },
    popLeft:{
      handler(r){
        console.log(r)
      }
    }
  },
  methods:{
    onClose(){
      this.$emit('close')
    },
    async saveColor(color){
      this.selectedColor = color;
      const slug = document.getElementById('slug').value == '' ? '' : `/${document.getElementById('slug').value}`;
      await Http.patch(`${slug}/shop_manager/time_table/reservation_color/${this.reservationId}`, {
        color: this.selectedColor
      })
          .then((response) => {
            this.$emit('reload')
            this.$emit('close');
          })
          .catch((error) => {
            console.log(error);
          });
    }
  }
}
</script>

<style lang="scss" scoped>

.color-container{
  padding:5px;
}
.label{
  margin-left: 10px;
  margin-top: 10px;
  font-weight: bold;
  color: var(--color-main);
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
}
.color-div{
  height:36px;
  width: 60px;
  border-radius:5px;
}
.color-div:hover{
  filter:brightness(85%);
}
.btn{
  font-size:16px;
}
.content-div{
  display: flex;
}
.notice {
  position: relative;
  height: 40px;
  > .btn {
    padding: 6px 12px;
    margin: 0;
    min-height: 38px;
    font-size: 13px;
    i.fa {
      font-size: 1.2rem;
    }
  }
  .popover {
    position: absolute;
    top: 65px;
    min-width: fit-content;
    font-size: 12px;

  }
}
</style>
