import Vue from 'vue';
import Vuex from 'vuex';
import 'es6-promise/auto';
import createPersistedState from 'vuex-persistedstate';
import SecureLS from 'secure-ls';

var ls = new SecureLS();

import prefectures from './modules/owner/prefectures';
import active_confirm from './modules/active_confirm';
import restaurant_owner from './modules/owner/restaurants';
import active_reservation from "./modules/reservations/active_reservation";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      paths: ['isLoading', 'error', 'prefectures',
              'active_confirm','active_reservation.createForm'],
      storage: {
        getItem: key => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: key => ls.remove(key)
      }
    })
  ],
  state: {
    isLoading: true,
    error: null,
  },
  modules: {
    restaurant_owner,
    prefectures,
    active_confirm,
    active_reservation,
  },
  mutations: {
    LOADING(state, isLoading) {
      state.isLoading = isLoading;
    },
    ERROR(state, error) {
      state.error = error;
    },
  },
});