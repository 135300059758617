<template>
  <div class="modal fade show" style="display: block;">
    <div class="modal-dialog" >
      <div class="modal-content" >
        <div class="modal-header header_modal_icon" >
          <img :src="cross" @click="onClose" class="cross cursor-pointer"/>
        </div>
        <div class="alert">
          <img :src="alert" >
          <label>お客様にキャンセル通知を送信しますか？</label>
        </div>
        <div style="color: var(--color-main)">メール本文</div>
        <div class="modal-body">
          <div class="first-part">
            <div>{{reservation.last_name}} {{reservation.first_name}}様</div>
            <div>{{shop.name}} です。</div>
            <div>下記のご予約につきまして、誠に勝手ながらお店よりキャンセルさせていただきました。</div>
          </div>
          <div>
            <div>予約日時：{{reservation.start_time}}</div>
            <div>予約人数：{{reservation.number_of_people}}</div>
            <div>予約者名：{{reservation.last_name}} {{reservation.first_name}}</div>
            <div>店舗名：{{shop.name}}</div>
            <div>電話番号：{{reservation.tel}}</div>
          </div>
          <div class="message">誠に恐れ入りますが、ご了承のほど何卒お願い申し上げます。</div>
          <div>
            <div>※本メールは送信専用のため、返信内容を確認できません。</div>
            <div>※ご不明な点がございましたら恐れ入りますが、お店に直接ご連絡くださいますようお願い申し上げます。当メールにご返信いただいても、お店には届きません。</div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button"  class="back-button"  @click="onClose">もどる</button>
          <button type="button"  class="send-button" @click="save_reservation(true)">送信する</button>
          <button type="button"  class="cancel-button" @click="save_reservation()">送信しない</button>
        </div>
      </div>
    </div>
    <div class="modal-backdrop show"></div>
  </div>
</template>

<script>
import cross from "../../../../../assets/images/cross.svg"
import alert from "../../../../../assets/images/alert.svg"
import Http from "../../../shared/http";

export default {
  props: {
    reservation: {
      type: Object
    },
    selectedStatus: {
      type: Object
    },
    shop:{
      type: Object
    }
  },
  data() {
    return {
      cross:cross,
      alert: alert
    };
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
    async save_reservation(send_mail = false) {
      const slug = document.getElementById('slug').value == '' ? '' : `/${document.getElementById('slug').value}`;
      const path = `${slug}/shop_manager/time_table/${this.reservation.id}`

      await Http.post(path, {
        reservation: {
          shop_table_ids: this.reservation.shop_table_ids,
          start_time: this.reservation.start_time,
          stay_minutes: this.reservation.stay_minutes,
          number_of_people: this.reservation.number_of_people,
          number_of_adults: this.reservation.number_of_adults,
          number_of_children: this.reservation.number_of_children,
          reservation_type: this.reservation.reservation_type,
          first_name: this.reservation.first_name,
          last_name: this.reservation.last_name,
          first_name_kana: this.reservation.first_name_kana,
          last_name_kana: this.reservation.last_name_kana,
          tel: this.reservation.tel,
          email: this.reservation.email,
          staff_name: this.reservation.staff_name,
          memo: this.reservation.memo,
          memo_from_customer: this.reservation.memo_from_customer,
          reset_time: this.reservation.reset_time,
          state: this.selectedStatus.value,
        },
        update_user_data: false,
        customer_id: this.reservation.customer_id,
        send_mail: send_mail,
      })
          .then((response) => {
            this.$emit('reload')
            this.$emit('close')
            window.location.reload();
          })
          .catch((error) => {
            this.$emit('loading', false);
            this.errors = error.response.data
          });
    },
  }
}
</script>

<style scoped>
.modal-dialog {
  z-index: 1050;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: auto;
  min-width: 590px;
}
.cross {
  width: 20px;
  color: white;
  margin-left: auto;
}
.modal-header{
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  justify-content: center;
  border-bottom: none;
}
.alert{
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--red-opacity5);
  padding: 20px;
  border-radius: 4px;
  gap: 10px;
}
.alert > label {
  color: var(--color-error);
  font-size: 18px;
  font-weight: bold;
}
.modal-content{
  min-height: calc(100vh - 200px);
  border-radius: 12px;
  padding: 10px;
}
.modal-body{
 border: 1px solid var(--color-tt-block-bg);
  border-radius: 4px;
  padding: 10px;
}
.first-part{
  display: flex;
  flex-direction: column;
  gap: 18px;
  margin-bottom: 20px;
}
.message{
  margin: 20px 0px;
}
.modal-footer{
  margin-left: 15px;
}
.back-button{
  color: var(--gray);
  border: 1px solid var(--gray);
  padding: 10px 35px;
  border-radius: 4px;
  background: var(--color-white);
}
.send-button{
  color: var(--color-white);
  padding: 10px 45px;
  border-radius: 4px;
  background: var(--color-error);
  border: 2px solid var(--color-error);
}
.cancel-button{
  color: var(--color-main);
  padding: 10px 45px;
  border-radius: 4px;
  border: 2px solid var(--color-main);
  background: var(--color-white);
}
@media only screen
and (min-device-width: 768px){
  .modal-dialog {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: auto;
  }
  .modal-content{
    min-height: calc(100vh - 400px);
  }
  .error-message{
    font-size: 18px;
  }
}
</style>
