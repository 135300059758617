<template>
<div>
  <div class="customer-info"><label class="customer-details-label">ステータス</label>
    <div class="row-item" @click.prevent="changeStateModal">
      <div class="store" :class="dynamicClass">{{getStateText(reservation.state)}}</div>
      <div class="status">ステータス変更</div>
    </div>
  </div>
  <div class="customer-info"><label class="customer-details-label">お名前</label>
    <div> {{reservation.last_name}} {{reservation.first_name}}
    </div>
  </div>

  <div class="customer-info memo"><label class="customer-details-label">予約に関するメモ</label>
    <div> {{reservation.memo}}
    </div>
  </div>

  <div class="customer-info memo" v-if="reservation.memo_from_customer"><label class="customer-details-label">メモ</label>
    <div> {{reservation.memo_from_customer}}
    </div>
  </div>

  <div class="customer-info"><label class="customer-details-label">日時</label>
    <div> {{reservation.start_time | formatDate}}
    </div>
  </div>

  <div class="customer-info"><label class="customer-details-label">予約人数</label>
    <div class="total-people">
      <span class="label-inside-select">大人：</span>
      <div> {{reservation.number_of_adults}}人
      </div>
       <span class="label-inside-select" style="margin-left: 40px;">子供：</span>
      <div> 
        {{reservation.number_of_children}}人
      </div>
    </div>
  </div>
  <div class="customer-info"><label class="customer-details-label">テーブル</label>
   <div class="row" v-for="item in reservation.shop_table_ids" :key="item.id">
    <span>{{ shop_tables.find(table=> table.id  === item).full_name }}</span>
  </div>
  </div>

  <div class="customer-info"><label class="customer-details-label">プラン</label>
    <div>{{ reservation.plan_name }}
    </div>
  </div>

  <div class="customer-info"><label class="customer-details-label">確保時間</label>
    <div> {{reservation.stay_minutes}}分
    </div>
  </div>
  <div class="customer-info"><label class="customer-details-label">メールアドレス</label>
    <div> {{reservation.email}}
    </div>
  </div>
  <div class="customer-info"><label class="customer-details-label">流入経路</label>
    <div> {{reservation.reservation_type_label}}
    </div>
  </div>

  <div class="customer-info"><label class="customer-details-label">予約担当者</label>
    <div> {{reservation.staff_name}}
    </div>
  </div>

  <div class="customer-info"><label class="customer-details-label">予約タグ</label>
     <div class="row grid-container">
      <p class="tag-main-container" v-for="(tag, index) in reservation.tag_details" :key="index" :title="tag.name">
      <span v-if="tag.icon">
        <span v-if="tag.icon === 'crown'" class="tag-images text-center ml-1 p-1">
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 160 160" class="text-center ml-1 p-1">
            <g id="グループ_400" data-name="グループ 400" transform="translate(530 -3683)">
              <path id="パス_114" data-name="パス 114" d="M7.211,32.291a7.113,7.113,0,0,0,.737-.074l3.8,42.791H128.257l3.8-42.791a7.118,7.118,0,0,0,.736.074,7.221,7.221,0,1,0-5.068-2.087l-26.3,26.3L72.628,13.911a7.212,7.212,0,1,0-5.256,0L38.577,56.5l-26.3-26.3a7.207,7.207,0,1,0-5.068,2.087" transform="translate(-520 3712)" fill="#ffffff"/>
              <path id="パス_115" data-name="パス 115" d="M24.675,166.285l1.485,16.742H137.886l1.485-16.742Z" transform="translate(-532.023 3630.978)" fill="#ffffff"/>
              <rect id="長方形_653" data-name="長方形 653" width="30" height="28" transform="translate(-530 3683)" fill="none"/>
            </g>
          </svg>          
          <span class="pe-auto tag-svg-text">{{ tag.name }}</span>
        </span>
        <span v-if="tag.icon === 'coupon'" class="tag-images text-center ml-1 p-1">
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 160 160" class="text-center ml-1 p-1">
            <g id="グループ_402" data-name="グループ 402" transform="translate(733 -3404)">
              <path id="前面オブジェクトで型抜き_1" data-name="前面オブジェクトで型抜き 1" d="M128.175,81.452H11.824A11.825,11.825,0,0,1,0,69.629V52.98a12.4,12.4,0,0,0,7.564-4.108,12.356,12.356,0,0,0,3.061-8.145,12.357,12.357,0,0,0-3.061-8.146A12.41,12.41,0,0,0,0,28.472V11.825A11.824,11.824,0,0,1,11.824,0H128.175A11.825,11.825,0,0,1,140,11.825V28.361a12.378,12.378,0,0,0,0,24.729V69.629a11.825,11.825,0,0,1-11.824,11.823ZM29.159,63.381v10.61h5.068V63.381Zm30.6-38.735a12.682,12.682,0,0,0-5.37,1.138,11.923,11.923,0,0,0-4.17,3.227c-2.395,2.874-3.66,6.884-3.66,11.6,0,4.562,1.2,8.45,3.475,11.244a11.613,11.613,0,0,0,9.325,4.315,12.082,12.082,0,0,0,9.37-4.03c2.48-2.816,3.79-6.969,3.79-12.01a21.252,21.252,0,0,0-.852-6.131,14.818,14.818,0,0,0-2.483-4.909A11.545,11.545,0,0,0,59.76,24.646Zm38.64.481v30.6h4.64V42.286h10.48V38.446H103.04v-9.44h11.28v-3.88Zm-21.119,0v30.6H81.92V42.286H92.4V38.446H81.92v-9.44H93.2v-3.88ZM29.159,44.741v10.61h5.068V44.741Zm0-18.643V36.711h5.068V26.1Zm0-18.637V18.07h5.068V7.461Zm30.361,44.9A6.924,6.924,0,0,1,53.5,48.751a15.929,15.929,0,0,1-2.06-8.265c0-5.993,2.523-12.04,8.159-12.04a6.9,6.9,0,0,1,6.075,3.73,16.135,16.135,0,0,1,1.965,8.11,16.457,16.457,0,0,1-2.05,8.485A6.936,6.936,0,0,1,59.52,52.366Z" transform="translate(-724 3443)" fill="#ffffff"/>
              <rect id="長方形_655" data-name="長方形 655" width="30" height="28" transform="translate(-733 3404)" fill="none"/>
            </g>
          </svg>
         <span class="pe-auto tag-svg-text">{{ tag.name }}</span>
        </span>
        <span v-if="tag.icon === 'cheers'" class="tag-images text-center ml-1 p-1">
           <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 160 160" class="text-center ml-1 p-1">
            <g id="グループ_401" data-name="グループ 401" transform="translate(447 -3404)">
              <path id="パス_116" data-name="パス 116" d="M60.682,61,23.425,51.782a3.883,3.883,0,0,0-4.707,2.841l-13.1,52.925a23.064,23.064,0,0,0,13.165,26.67l-6.662,26.924-7.3-1.808a3.89,3.89,0,0,0-1.869,7.552l22.159,5.483a3.888,3.888,0,0,0,1.869-7.548l-7.309-1.81,6.663-26.926a23.067,23.067,0,0,0,24.09-17.45l13.1-52.926A3.889,3.889,0,0,0,60.682,61M50.371,86.488,20.664,79.137l4.669-18.871,29.707,7.349Z" transform="translate(-435.755 3381.518)" fill="#ffffff"/>
              <path id="パス_117" data-name="パス 117" d="M262.463,162.176a3.891,3.891,0,0,0-4.709-2.843l-7.3,1.808-6.661-26.924a23.067,23.067,0,0,0,13.167-26.67l-13.1-52.925a3.883,3.883,0,0,0-4.707-2.841L201.9,61a3.889,3.889,0,0,0-2.841,4.709l13.1,52.926a23.067,23.067,0,0,0,24.09,17.45L242.9,163.01l-7.309,1.81a3.888,3.888,0,0,0,1.869,7.548l22.159-5.483a3.889,3.889,0,0,0,2.839-4.709M212.207,86.488l-4.669-18.873,29.707-7.349,4.669,18.871Z" transform="translate(-560.823 3381.518)" fill="#ffffff"/>
              <path id="パス_118" data-name="パス 118" d="M180.219,0A2.917,2.917,0,0,0,177.3,2.916V9.234a2.916,2.916,0,1,0,5.833,0V2.916A2.917,2.917,0,0,0,180.219,0" transform="translate(-547.219 3414)" fill="#ffffff"/>
              <path id="パス_119" data-name="パス 119" d="M125.994,21.64a2.916,2.916,0,1,0,4.671-3.492l-4.322-5.78a2.917,2.917,0,0,0-4.671,3.494Z" transform="translate(-511.881 3406.96)" fill="#ffffff"/>
              <path id="パス_120" data-name="パス 120" d="M227.127,21.64l4.322-5.779a2.916,2.916,0,0,0-4.671-3.494l-4.322,5.78a2.916,2.916,0,1,0,4.671,3.492" transform="translate(-575.24 3406.96)" fill="#ffffff"/>
              <rect id="長方形_654" data-name="長方形 654" width="30" height="28" transform="translate(-447 3404)" fill="none"/>
            </g>
          </svg>
          <span class="pe-auto tag-svg-text">{{ tag.name }}</span>
        </span>
        <span v-if="tag.icon !== 'coupon' && tag.icon !== 'crown' && tag.icon !== 'cheers'" class="tag-images text-center ml-2 p-1">
          <i :class="[tag.icon, 'p-2', 'pe-auto']" aria-hidden="true"></i><span class="pl-2 tag-image-text">{{ tag.name }}</span>
        </span>
      </span>
      <span v-else class="tag-images text-center p-2" >
        <span class="p-3 tag-image-text">{{ tag.name }}</span>
      </span>
    </p>
  </div>
  </div>
   <div class="row customer-info">
    <div class="column">
      <div class="row">
        <label class ="customer-details-label">受付日時</label>
      </div>
      <div class="row">
        {{createdAt}}
      </div>
    </div>
    <div class="column">
      <div class="row">
        <label class= "customer-details-label">変更日時</label>
      </div>
      <div class="row">
        {{updatedAt}}
      </div>
    </div>
<!--     <div v-if="reservation.reservation_type !== 'walkin'" ref="playButton" class="play-button" @click.stop="toggleAudio">-->
<!--       <svg-->
<!--           v-if="!isPlaying"-->
<!--           class="play-icon"-->
<!--           xmlns="http://www.w3.org/2000/svg"-->
<!--           viewBox="-12 -9 66 66"-->
<!--           width="48"-->
<!--           height="48"-->
<!--       >-->
<!--         <polygon points="10,6 38,24 10,42" fill="var(&#45;&#45;color-main)" />-->
<!--       </svg>-->
<!--       <svg-->
<!--           v-else-->
<!--           class="play-icon"-->
<!--           xmlns="http://www.w3.org/2000/svg"-->
<!--           viewBox="-12 -9 66 66"-->
<!--           width="48"-->
<!--           height="48"-->
<!--       >-->
<!--         <rect x="10" y="6" width="8" height="36" fill="var(&#45;&#45;color-main)" />-->
<!--         <rect x="28" y="6" width="8" height="36" fill="var(&#45;&#45;color-main)" />-->
<!--       </svg>-->
<!--       <p>予約担当者の応答を再生</p>-->
<!--       <audio ref="audio">-->
<!--         <source :src="audioSrc" type="audio/mpeg" />-->
<!--       </audio>-->
<!--     </div>-->
   </div>
  <StateChangeModal
      v-if="showStateChangeModal"
      @close="showStateChangeModal = false"
      :reservation="reservation"
      :showView="showView"
      :tab="tab"/>
</div>
</template>

<script>
import http from "../../shared/http";
import moment from 'moment';
import {
  decode_data
} from "../../shared/util";
import StateChangeModal from './StateChangeModal.vue'
import Http from "../../shared/http"

export default {
  components: {
    StateChangeModal
  },
  props: {
    reservation: {
      type: Object,
    },
    showReservationTags: {
      type: Array,
    },
    tab: {
      type: String,
    },
    showView: {
      type: Boolean,
    }
  },
  filters:{
    formatDate(val){
      return val!==null?moment(val).format('YYYY-MM-DD(ddd) HH:mm'):''
    }
  },
  computed: {
    isChecked() {
      return (optionId) => this.shop_table_ids && this.shop_table_ids.includes(optionId);
    },
    createdAt(){
      return moment(this.reservation.created_at).format('YYYY-MM-DD HH:mm')
    },
    updatedAt(){
      return moment(this.reservation.updated_at).format('YYYY-MM-DD HH:mm')
    },
    dynamicClass(){
      return this.reservation.state
    },
  },
  data() {
    return {
      shop_tables: decode_data("#data-shop-tables"),
      reservation_staffs: decode_data("#data-reservation-staffs"),
      shop: decode_data("#data-shop"),
      showStateChangeModal: false,
      course: "",
      isPlaying: false,
      audioSrc: '',
    };
  },
  mounted() {
    document.addEventListener("click", this.handleDocumentClick);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleDocumentClick);
    this.resetAudio();
  },
  methods: {
    async toggleAudio() {
      if (!this.reservation || !this.reservation.uuid) {
        console.error("Reservation UUID is missing", this.reservation);
        return;
      }
      if (!this.audioSrc) {
        try {
          const response = await Http.get(
              `/shop_manager/reservation/audio_clips/${this.reservation.uuid}`
          );
          this.audioSrc = response.data.url;
          this.$refs.audio.load();
        } catch (error) {
          console.error("Error fetching audio:", error);
          return;
        }
      }
      const audio = this.$refs.audio;
      if (this.isPlaying) {
        audio.pause();
      } else {
        audio.play();
      }
      this.isPlaying = !this.isPlaying;
    },
    handleDocumentClick(event) {
      const playButton = this.$refs.playButton;
      if (playButton && !playButton.contains(event.target) && this.isPlaying) {
        this.resetAudio();
      }
    },
    resetAudio() {
      const audio = this.$refs.audio;
      if (audio) {
        audio.pause();
        audio.currentTime = 0;
      }
      this.isPlaying = false;
    },
    changeStateModal(){
      const audio = this.$refs.audio;
      if(audio) {
        audio.pause();
        audio.currentTime = 0;
      }
      this.audioSrc = '';
      this.isPlaying = false;
      return this.showStateChangeModal = true
    },
     getStateText(state) {
      switch (state) {
        case 'reserved':
          return '来店待ち';
        case 'seating':
          return '来店';
        case 'finished':
          return '会計済';
        case 'not_show':
          return 'No Show';
        case 'cancelled':
          return '予約取消';
        default:
          return '';
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.play-button {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 120px; /* Adjust the width as needed */
  height: 155px; /* Adjust the height as needed */
  border:2px solid var(--color-main);
  border-radius: 10px;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  color: #ed9f00;
  font-size: 14px; /* Adjust font size as needed */
  font-family: Arial, sans-serif;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.play-button:hover {
  background-color: #f0f0f0; /* Light gray background on hover */
}
.play-icon {
  width: 48px; /* Adjust the size as needed */
  height: 48px; /* Adjust the size as needed */
  background-color: white;
  border: 2px solid #ed9f00;
  display: inline-block;
  padding: 2px; /* Adjust padding as needed */
  fill: white;
  transition: background-color 0.3s ease;
  border-radius: 50%;
}
.play-icon.playing {
  background-color: #ed9f00; /* Change the color to indicate playing state */
}
.play-button p {
  margin-top: 10px; /* Adjust spacing as needed */
  color: #333; /* Text color */
  margin-left: 1rem;
  margin-right: 1rem;
}
.customer-info {
  font-size: 18px;
  margin-bottom: 6px;
  padding-bottom: 20px;
}

.customer-details {
  font-size: 16px;
  display: flex;
  position: relative;
  height: 50px;
  margin-top: 20px;
  background: #fff;
  justify-content: space-evenly;
  width: 100%;

  .label {
    font-weight: 600;
  }

  .count-reservation {
    margin-left: 10px;
  }
}

.customer-details-label {
  font-size: 16px;
  position: relative;
  color: var(--color-main);
  margin-bottom: 0px;
}
.tag-main-container{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  width: 45%;
  display: inline-flex;
  gap: 3%;
  align-items: center;
  border: none !important;
}
.tag-images {
  i,svg{
    margin-top: 2px;
    background: var(--color-main) !important;
    color: white !important;
    font-size: 16px !important;
    text-align: center;
    width: 35px;
    height: 30px;
  ;
  }
}
.total-people{
  display: flex;
  margin: 2px; 
}
.row{
  margin: 0px !important;
  width: 100%;
  display: flex;
}
.column{
  margin-right: 20px;
  justify-content: space-around !important;
  width: 45%
}

.row-item{
  display: flex !important;
  gap: 10px;
  align-items: center;
}
.store{
  cursor: pointer;
  background: var(--sub-color-opacity10);
  border: 2px dashed var(--color-tt-message) ;
  padding: 8px 35px;
  border-radius: 5px;
  color: var(--color-main);
  font-weight: bold;
  width: 45%;
}
.status{
  color: var(--color-link-status);
  cursor: pointer;
}
.seating {
  background-color:var(--color-sit-down-opacity);
  border: 2px solid var(--sub-color);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  color: var(--color-main);
}
.memo{
  word-break: break-all;
  overflow-wrap: break-word;
}
.reserved {
  background-color:var( --color-reserved-opacity);
  border: 2px dashed var(--sub-color);
  border-radius: 5px;
  display: flex;
  justify-content: center;
}
.finished{
  background-color: var(--color-accounted-for);
  border: 2px solid var(--color-main);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  color: var(--color-main);
}
.cancelled{
  background-color: var(--color-reservation-cancel);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  color: var(--color-white);
}
.not_show {
  background-color: var(--color-no-show);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  color: var(--color-white);
}
.tag-svg-text, .tag-image-text{
  position: relative;
  font-size: 14px !important;
  text-align: center;

  top:0;
}
</style>
