import Vue from 'vue';
import VueRouter from 'vue-router';

import OwnerLayout from './shared/Owner/Layout.vue';
import NotfoundLayout from './shared/Owner/LayoutNotfound.vue';
import OwnerDashboard from './pages/Owner/Dashboard/Index.vue';
import OwnerShopCreate from './pages/Owner/Shop/Create.vue';
import OwnerEdit from './pages/Owner/Owners/Edit.vue';
import OwnerDeleteComplete from './pages/Owner/Owners/Complete.vue';
import OwnerDetail from './pages/Owner/Owners/Detail.vue';
import OwnerShopShow from './pages/Owner/Shop/Show.vue';
import OwnerShopEdit from './pages/Owner/Shop/Edit.vue';

import OwnerPaymentEdit from './pages/Owner/Payment/Edit.vue';
import NotFound from './pages/Owner/NotFound/Index.vue';
import OwnerPayment from './pages/Owner/Payment/Index.vue';
import SuccessUrl from './pages/Owner/Payment/Success.vue';
import CancelUrl from './pages/Owner/Payment/Cancel.vue';

import ReservationsLayout from './shared/Reservations/Layout.vue';
import EditReservation from "./shared/EditReservation/EditReservation.vue";


Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: '',
  routes: [
    {
      path: '/:slug(.*)*/owner',
      component: OwnerLayout,
      children: [
        { path: '', component: OwnerDashboard, name: 'restaurant_dashboard', meta: { title: '集客コボット for SNS Booster | アカウント管理' } },
        { path: 'payment', component: OwnerPayment, meta: { title: '集客コボット for SNS Booster | 支払管理' } },
        { path: 'payment/edit', component: OwnerPaymentEdit, meta: { title: '集客コボット for SNS Booster | 支払管理' } },
        { path: 'shop/create', component: OwnerShopCreate, meta: { title: '集客コボット for SNS Booster | 店舗の新規登録' } },
        { path: 'detail', component: OwnerDetail, name: 'owner_detail', meta: { title: '集客コボット for SNS Booster | 法人情報管理' } },
        { path: 'edit', component: OwnerEdit, name: 'owner_edit', meta: { title: '集客コボット for SNS Booster | 法人情報管理' } },
        { path: 'shop/:id/detail', component: OwnerShopShow, meta: { title: '集客コボット for SNS Booster | 店舗詳細' } },
        { path: 'shop/:id/edit', component: OwnerShopEdit, meta: { title: '集客コボット for SNS Booster | 店舗詳細' } },
        { path: 'complete_delete_owner', name: 'complete_delete_owner', component: OwnerDeleteComplete, meta: { title: '集客コボット for SNS Booster | 法人情報変更 アカウント削除完了' } },
        { path: 'payment/card_complete', component: SuccessUrl, meta: { title: '集客コボット for SNS Booster | 店舗詳細' } },
        { path: 'payment/card_cancel', component: CancelUrl, meta: { title: '集客コボット for SNS Booster | 店舗詳細' } },
      ]
    },
    {
      path: '/:slug(.*)*/reservations',
      component: ReservationsLayout,
      children:[
        { path:'1', component:()=>import(`./pages/Reservations/Create/RegisterNewReservation.vue`)},
        { path:'2', component:()=>import(`./pages/Reservations/Create/TableSelection.vue`)},
        { path:'3', component:()=>import(`./pages/Reservations/Create/RegisterCustomerInfo.vue`)},
        { path:'4', component:()=>import(`./pages/Reservations/Create/RegisterMemo.vue`)},
        { path:'5', component:()=>import(`./pages/Reservations/Create/SelectCourse.vue`)},
        { path:'*', component:()=>import(`./pages/Reservations/Create/ConfirmReservation.vue`)},

      ]
    },
    {
      path: '/:slug(.*)*/:id/edit',
      component: EditReservation,
    },
    {
      path: '/',
      component: NotfoundLayout,
      children: [
        { path: '/owner/*', component: NotFound, meta: { title: 'エラー '} },
      ]
    },
  ],
  linkActiveClass: 'active',
});

const DEFAULT_TITLE = 'Table Request';

router.beforeEach(async (to, from, next) => {
  const hideForAuth = to.matched.some(record => record.meta.hideForAuth);
  next();
});

router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  });
});

export default router;
