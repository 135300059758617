<template xmlns="http://www.w3.org/1999/html">
  <div class="customer-form-wrapper">
    <div class="customer-form">
      <div class="customer-info">
        <label class="customer-details-label">写真 </label>
        <div class="image-container" :class="{afterImageUploaded:hasArrayRequiredLength(images,1)}">
          <div v-for="(image, index) in images">
            <div class="image-card">
              <img class="cursor-pointer" @click="openImageModal(image.image_id)" :src="image.image_id" />
              <i class="fa fa-search-plus search-button" :style="{ width: '30px'}" @click="openImageModal(image.image_id)"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="customer-info"><label class="customer-details-label">お客様番号</label>
        <div> {{number}}
        </div>
      </div>
      <div class="customer-info"> <label class="customer-details-label">お名前</label>
        <div>
          {{last_name}}
          {{first_name}}
        </div>
        <div>
          {{lastNameKana}}
          {{firstNameKana}}
        </div>
      </div>
      <div class="customer-info"><label class="customer-details-label">TEL </label>
        <div>
          {{tel}}
        </div>
      </div>
      <div class="customer-info"> <label class="customer-details-label">
        メールアドレス</label>
        <div>
          {{email}}
        </div>
      </div>
      <div class="customer-info"> <label class="customer-details-label">
        アレルギー</label>
        <div>
          {{allergy}}
        </div>
      </div>
      <div class="customer-info"> <label class="customer-details-label">
        属性</label>
        <div v-for="(name, index) in property_name" :key="index">
          {{name}}
        </div>
      </div>
      <div class="customer-info"> <label class="customer-details-label">
        備考</label>
        <div>
          {{remark}}
        </div>
      </div>

      <div class="customer-info"> <label class="customer-details-label">
        領収書名</label>
        <div>
          {{receiptName}}
        </div>
      </div>

      <div class="customer-info"> <label class="customer-details-label">
        自宅住所</label>
        <div>
          {{homeAddress}}
        </div>
      </div>

      <div class="customer-info"> <label class="customer-details-label">
        SNS1</label>
        <div>
          {{sns1}}
        </div>
      </div>

      <div class="customer-info"> <label class="customer-details-label">
        SNS2</label>
        <div>
          {{sns2}}
        </div>
      </div>

      <div class="customer-info">
        <label class="customer-details-label">誕生日 :</label>
        <div> {{birthday}}
        </div>
      </div>
      <div class="customer-info">
        <label class="customer-details-label">
          結婚記念日</label>
        <div>{{weddingAnniversary}} </div>
      </div>

      <div class="customer-info"> <label class="customer-details-label">
        会社名</label>
        <div>
          {{companyName}}
        </div>
      </div>

      <div class="customer-info"> <label class="customer-details-label">
        部署名</label>
        <div>
          {{departmentName}}
        </div>
      </div>

      <div class="customer-info"> <label class="customer-details-label">
        役職名</label>
        <div>
          {{positionName}}
        </div>
      </div>

      <div class="customer-info"> <label class="customer-details-label">
        勤務先住所</label>
        <div>
          {{companyAddress}}
        </div>
      </div>

      <div class="customer-info"> <label class="customer-details-label">
        特徴</label>
        <div>
          {{customerCharacteristics}}
        </div>
      </div>

      <div class="customer-info"> <label class="customer-details-label">
        好きなもの</label>
        <div>
          {{customerPreferences}}
        </div>
      </div>

      <div class="customer-info"> <label class="customer-details-label">
        嫌いなもの</label>
        <div>
          {{customerDislike}}
        </div>
      </div>

      <div class="customer-info"> <label class="customer-details-label">
        その他1</label>
        <div>
          {{others1}}
        </div>
      </div>

      <div class="customer-info"> <label class="customer-details-label">
        その他2</label>
        <div>
          {{others2}}
        </div>
      </div>

      <div class="customer-info"> <label class="customer-details-label">
        性別</label>
        <div>
          {{sex}}
        </div>
      </div>

      <div class="customer-visit-info">
      <span class="customer-details-label" style="
      font-weight: bold;">来店情報</span>
        <div class="table_customer_modal">
          <table class="customer_table">
            <thead>
            <th>来店日</th>
            <th>人数</th>
            <th>予約種別</th>
            <th>ステータス</th>
            </thead>
            <tbody>
            <tr v-for="(reservation, index) in reservations" :key="index">
              <td>{{ convertTime(reservation.start_time) }}</td>
              <td> {{reservation.number_of_people}}人</td>
              <td v-if="reservation.course_id == null">席のみ</td>
              <td v-else>コース</td>
              <td v-if="reservation.state == 'reserved'">来店待ち</td>
              <td v-if="reservation.state == 'cancelled' ||
                  reservation.state == 'restored'
                  ">
                キャンセル
              </td>
              <td v-if="reservation.state == 'seating'">来店</td>
              <td v-if="reservation.state == 'finished'">会計済</td>
              <td v-if="reservation.state == 'not_show'">No Show</td>
            </tr>
            </tbody>
          </table>
          <div v-if="reservations.length == 0" class="reservation_null">
            表示するデータがありません。
          </div>
        </div>

        <div class ="customer-details">
          <div class="d-flex-content d-block-content column">
            <label class="customer-details-label">予約:</label>
            <div class="count-reservation">
              {{ count_all_reservation }}
            </div>
          </div>
          <div class="d-flex-content d-block-content column">
            <label class="customer-details-label">来店:</label>
            <div class="count-reservation">{{ count_reservation }}</div>
          </div>
          <div class="d-flex-content d-block-content column">
            <label class="customer-details-label">キャンセル:</label>
            <div class="count-reservation">{{ count_cancel }}</div>
          </div>
          <div class="d-flex-content d-block-content column">
            <label class="customer-details-label">No Show:</label>
            <div class="count-reservation">{{ count_not_show }}</div>
          </div>
        </div>
        <ImageModal
            v-if="showImageModal"
            :imageID="imageId"
            @close="showImageModal=false"
        />
      </div>
    </div>
    <div class="modal-footer">
      <button class="btn btn-primary" @click.prevent="openCustomerModal">編集する</button>
    </div>
    <CustomerModal
        :customer = "customer"
        :reservations = "reservations"
        v-show="showCustomerModal"
        @reload="reload"
        @close="showCustomerModal = false"
        @open-modal-allergy="true"
        @delete = "onDelete(customer)"
    >
    </CustomerModal>
    <ModalDelete
        :customer = "customer"
        v-show="showDeleteModal"
        @reload="reload"
        @close="showDeleteModal = false"
    ></ModalDelete>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/ja';
import moment from 'moment';
import ImageModal from "../customer/components/ImageModal.vue";
import CustomerModal from "../customer/components/CustomerModal.vue";
import ModalDelete from "../customer/components/ModalDelete.vue";

export default {
  components: {
    ModalDelete,
    CustomerModal,
    ImageModal,
    DatePicker,
  },
  data() {
    return {
      showCustomerModal: false,
      imageId:'',
      showImageModal:false,
      images:[],
      image_data:[],
      sexOptions:[
        {value:'male',label:'男性'},
        {value:'female',label:'女性'},
        {value:'other',label:'未設定'}
      ],
      customerCharacteristics: '',
      companyPostNumber: null,
      companyAddress: '',
      positionName: '',
      others2: '',
      others1: '',
      customerDislike: '',
      customerPreferences: '',
      departmentName: '',
      companyName: '',
      weddingAnniversary: null,
      sns2: '',
      sns1: '',
      address: '',
      homePostNumber: null,
      homeAddress: '',
      receiptName: '',
      email1: '',
      tel1: '',
      sex: null,
      loading: false,
      allergy: "",
      properties: [],
      errors: {},
      number: "",
      last_name: "",
      first_name: "",
      lastNameKana: "",
      firstNameKana: "",
      tel: "",
      email: "",
      remark: "",
      propertyIds: [],
      birthday: null,
      nameProperty: null,
      showModalProperty: false,
      count_reservation: 0,
      count_cancel: 0,
      count_not_show: 0,
      is_edit_count_reservations: false,
      showAnniversaryModal: false,
      showDeleteAnniversaryModal: false,
      anniversaryIndexToDelete: null,
      anniversaries: [],
      property_name: [],
      showDeleteModal: false
    };
  },
  computed: {
    count_all_reservation() {
      return (parseInt(this.count_reservation) || 0) + (parseInt(this.count_cancel) || 0) + (parseInt(this.count_not_show) || 0);
    },
  },
  props: {
    reservations: {
      type: Array,
    },
    customer: {
      type: Object,
    },
    isCreateNew: {
      type: Boolean,
    },
    hideCustomerSaveButton: {
      type: Boolean,
      required: false
    },
  },
  watch: {
    customer: {
      handler(r) {
        this.sex= this.getSexLabel(r.sex);
        this.others2=r.others2;
        this.others1=r.others1;
        this.customerDislike=r.customer_dislike;
        this.customerPreferences=r.customer_preferences;
        this.customerCharacteristics=r.customer_characteristics;
        this.companyAddress=r.company_address;
        this.companyPostNumber=r.company_post_number;
        this.positionName=r.position_name;
        this.departmentName=r.department_name;
        this.companyName=r.company_name;
        this.weddingAnniversary=r.birthday
            ? moment(r.wedding_anniversary).format("YYYY/MM/DD")
            : null;
        this.sns2=r.sns2;
        this.sns1=r.sns1;
        this.homeAddress=r.home_address;
        this.homePostNumber=r.home_post_number;
        this.receiptName=r.receipt_name;
        this.tel1=r.tel1;
        this.email1=r.email1;
        this.number = r.number;
        this.last_name = r.last_name;
        this.first_name = r.first_name;
        this.firstNameKana = r.first_name_kana;
        this.lastNameKana = r.last_name_kana;
        this.tel = r.tel;
        this.email = r.email;
        this.remark = r.remark;
        this.birthday = r.birthday
            ? moment(r.birthday).format("YYYY/MM/DD")
            : null;
        this.allergy = r.allergy ? r.allergy : '';
        this.propertyIds = r.property_ids != null ? r.property_ids : [];
        this.property_name = r.property_name;
        this.count_reservation = r.count_reservation;
        this.count_cancel = r.count_cancel;
        this.count_not_show = r.count_not_show;
        this.number_of_people =r.number_of_people;
        this.is_edit_count_reservations = false;
        this.anniversaries = r.anniversaries ? r.anniversaries.map(({id, anniversary_name, anniversary}) => ({
          id: id,
          anniversary_name: anniversary_name,
          anniversary: moment(anniversary).format('YYYY/MM/DD')
        })) : [];
        this.images = r.images ? r.images : [];
      },
    },

  },
  methods: {
    reload(){
      window.location.reload()
    },
    openCustomerModal(){
      this.showCustomerModal = true
    },
    async onDelete(customer) {
      this.customer = customer;
      this.showDeleteModal = true;
    },
    openImageModal(id){
      this.showImageModal=true;
      this.imageId=id;
    },
    convertTime(time) {
      return moment(time).format("YYYY/MM/DD")
    },
    hasArrayRequiredLength(arr,len=0){
      return arr?.length>=len
    },
    redirectToCustomerList: () =>{
      window.location.href = "/shop_manager/customer"
    },
    getSexLabel(sex){
      const sexValue =  this.sexOptions?.filter(opt => opt.value === sex)[0];
      return sexValue ? sexValue.label : null;
    }
  },
};

</script>

<style lang="scss" scoped>
.btn-primary {
  font-size: 16px;
}
.customer-details-label{
  font-size: 16px;
  position: relative;
  color: var(--color-main);
  margin-bottom: 0px;
}

button:focus {
  outline: none;
}

.btn-fill:focus {
  outline: none !important;
}

.customer_table {
  width: 100%;
  font-size: 15px;

  th {
    width: 25%;
    color: var(--color-main);
    font-weight: unset;
    padding: 10px 0px 10px 40px;
  }

  td {
    padding: 2px 0px 5px 30px;
    border-bottom: 1px solid #DDDDDD;
    background: #fff
  }
}

.reservation_null {
  width: 100%;
  text-align: center;
  font-size: 16px;
  padding-top: 15px;
  padding-bottom: 15px;
}

@media screen and (max-width: 480px)and (min-device-width: 320px)  {
  .count-reservation {
    margin-left: 0 !important;
  }

  .d-block-content {
    display: block;
  }

  .column-1 {
    padding-left: 30px;
    width: 95px;
  }

  .column-2 {
    width: 85px;
  }

  .column-3 {
    width: 85px;
    margin-left: 20px;
  }

  .column-4 {
    margin-left: 20px;
    width: 80px;
  }

  .customer_table {
    th {
      width: 25%;
      color: var(--color-main);
      font-weight: unset;
      padding: 10px 0;
      text-align: center;
    }

    td {
      padding: 5px 0;
      text-align: center;
      border-bottom: 1px solid #DDDDDD;
    }
  }

  .customer-details {
    font-size: 12px !important;
    display: flex;
    position: relative;
    height: 70px;
    margin-top: 20px;
    background: #fff;
    justify-content: space-between;
    width: 100%;
    .label {
      font-weight: 600;
    }

    .count-reservation {
      margin-left: 10px;
    }
  }

  .d-flex-content {
    padding: 2px !important;
    margin-left: 0px;
    display: flex;
  }

}

.customer-form-wrapper {
  display: flex;
  flex-direction: column;
  height: calc(100dvh - 128px);
  position: relative;
}
.customer-form{
  height:calc(100dvh - 129px);
  overflow-x: hidden;
  overflow-y: auto;
}
.customer-form {
  font-family: 'Noto Sans JP', Arial, sans-serif;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px 38px;
}

.image-preview {
  text-align: center;
  margin-bottom: 20px;
}

.image-preview img {
  max-width: 100%;
  height: auto;
}

.image-container{
  display:flex;
  gap:10px;
}
.afterImageUploaded{
  padding-bottom:20px;
  overflow-x:auto;
  overflow-y:hidden;
}
.image-card{
  border:2px solid var(--color-main);
  position:relative;
  min-width:120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  img{
    max-height: 120px;
    padding:2px;
  }
}

.search-button {
  position: absolute;
  right: 0px;
  cursor: pointer;
  top: 84px;
  background: #fff;
  border-radius: 50%;
  padding: 7px;
  color: gray;
}

.reservation_null {
  text-align: center;
  margin-top: 20px;
  font-style: italic;
  color: #999;
}

.modal-footer {
  justify-content: end;
  clear: both;
}

.customer-info{
  font-size: 18px;
  margin-bottom: 6px;
  padding-bottom: 20px;
}

.customer-details {
  font-size: 16px;
  display: flex;
  position: relative;
  height: 50px;
  margin-top: 20px;
  background: #fff;
  justify-content: space-evenly;
  width: 100%;
  .label {
    font-weight: 600;
  }

  .count-reservation {
    margin-left: 10px;
  }
}

.d-flex-content {
  padding: 12px 12px 5px 5px;
  margin-left: 20px;
  display: flex;
}

.customer-visit-info{
  background: var(--main_opacity20);
  padding: 10px;
  margin-bottom: 2rem;
}
</style>