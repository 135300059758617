<template>
  <div>
    <div class="modal fade show" style="display: block;">
      <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header header_modal_icon">
            <h5 class="modal-title">{{ isCreateNew ? '新規顧客登録' : '顧客編集'}}</h5>
            <button type="button" class="close close_reservation_modal" @click="onClose"><span>&times;</span></button>
          </div>
          <div class="modal-body" id="modal-body">
            <template v-show="!isCreateNew">
              <div class="tab_area">
                <a
                  class="tab order_manage btn_point cursor-pointer"
                  :class="tab === 'customer' ? 'active' : ''"
                  @click="tab = 'customer'"
                >顧客情報</a>
                <a v-if="isPosOrder.length > 0"
                  class="tab order_manage btn_point cursor-pointer"
                  :class="tab === 'pos_order' ? 'active' : ''"
                  @click="tab = 'pos_order'"
                >喫食情報</a>
              </div>
            </template>
            <CustomerForm v-show="tab === 'customer'" ref="customerForm" :customer="customer" :reservations="reservations" :hideCustomerSaveButton="true"
                          :isCreateNew="isCreateNew" @changeData="isChangeData = true" @loading="$emit('loading', $event)"
                          @submitSuccess="submitSuccess">
            </CustomerForm>
            <template v-if="tab === 'pos_order'">
              <div>
                <div class="header-res" style="border-bottom: 1px solid #DEE2E6;background: #0090d7;color: #fff;">
                  <div class="col-res-1" style="margin: 10px 0 10px 30px;">
                    日付
                  </div>
                  <div class="col-res-2" style="margin: 10px 0 10px 30px;">
                    品数
                  </div>
                  <div class="col-res-3" style="margin: 10px 0 10px 30px;">
                    飲食金額
                  </div>
                  <div class="col-res-4"></div>
                </div>
                <div v-for="(r, index) in reservations" v-bind:key="index">
                  <div v-if="r.pos_order_data">
                    <button class="accordion" :id="'accordion-' + index" :key="index" @click="showItemPosData($event, index)">
                      <div class="header-res">
                        <div class="col-res-1" style="margin: 10px 0 10px 30px;">
                          {{ convertTime(r.start_time) }}
                        </div>
                        <div class="col-res-2" style="margin: 10px 0 10px 30px;">
                          {{ r.pos_order_data.items.length > 0 ? r.pos_order_data.quantity : '' }}
                        </div>
                        <div v-if="r.pos_order_data.items.length > 0" class="col-res-3" style="margin: 10px 0 10px 30px;">
                          ￥{{ Intl.NumberFormat('ja-JP').format(r.pos_order_data.sum) }}
                        </div>
                        <div v-else class="col-res-3" style="margin: 10px 0 10px 30px;"></div>
                        <div class="col-res-4 icon-acc">
                        </div>
                      </div>
                    </button>
                    <div class="panel" :id="'panel-' + index" style="margin: 0 30px;">
                      <div style="margin-top: 10px; margin-left: 10px; margin-bottom: 10px;">伝票番号：{{ r.pos_order_data.order_no }}</div>
                      <div v-for="item in r.pos_order_data.items" v-bind:key="item.name">
                        <div class="header-res">
                          <div class="col-res-1 w-30-res">
                            {{ item.name }}
                          </div>
                          <div class="col-res-2">
                            {{ item.quantity }}
                          </div>
                          <div class="col-res-3 w-27-res">
                            ￥{{ Intl.NumberFormat('ja-JP').format(item.price) }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="border-bottom-res"></div>
                  </div>
                </div>
              </div>
            </template>
          </div>
          <div v-if="isCreateNew" class="modal-footer">
            <button type="button" class="btn btn-primary btn-unfocus" @click="onSave">{{ isCreateNew ? '登録' : '更新'}}する</button>
            <button type="button" class="btn btn-outline-primary" @click="onClose">閉じる</button>
          </div>
          <div v-else class="modal-footer flex-justify">
            <button type="button" class="btn btn-danger btn-unfocus" @click="deleteCustomer">削除する</button>
            <div class="flex flex-end">
              <button type="button" class="btn btn-primary btn-unfocus" @click="onSave">{{ isCreateNew ? '登録' : '更新'}}する</button>
              <button type="button" class="btn btn-outline-primary" @click="onClose">閉じる</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ModalConfirmClose
      v-if="showModalConfirmClose"
      @close="showModalConfirmClose=false"
      @confirm="onConfirmClose"
    >
    </ModalConfirmClose>
  </div>
</template>

<script>
import moment from 'moment';
import Http from "../../../shared/http"
import Swal from "sweetalert2";
import ModalConfirmClose from '../../components/ModalConfirmClose.vue'
import CustomerForm from '../../components/CustomerForm.vue';
export default {
  components: {
    CustomerForm,
    ModalConfirmClose
  },
  props: {
    reservations: {
      type: Array,
    },
    customer: {
      type: Object,
    },
  },
  data() {
    return {
      showModalReservation: false,
      tab: 'customer',
      showModalConfirmClose: false,
      isChangeData: false
    }
  },
  computed: {
    isCreateNew() {
      this.tab = 'customer';
      return !this.customer.id;
    },
    isPosOrder() {
      const isPos = this.reservations.filter(function (val) {
        return (val.pos_order_data != null);
      })

      return isPos;
    }
  },
  watch: {
    customer: {
      handler(r) {
        this.scrollTopScreen();
        this.showModalConfirmClose = false;
        this.isChangeData = false;
      }
    },
  },
  methods: {
    deleteCustomer(){
      this.$emit('delete')
    },
    scrollTopScreen() {
      const elmnt = document.getElementById('modal-body');
      elmnt.scrollTo(0, 0);
    },
    convertTime(time) {
      return moment(time).format("YYYY/MM/DD")
    },
    onClose() {
      if (this.isChangeData) {
        this.showModalConfirmClose = true;
      }
      else {
        this.$emit('close');
      }
    },
    async onSave() {
      const customerForm = this.$refs.customerForm;
      customerForm.onSave();
    },
    submitSuccess() {
      this.$emit('loading', false)
      this.$emit('close');

      const title = '顧客情報が編集されました。';

      Swal.fire({
        title: title,
        icon: "success",
        customClass: {
          confirmButton: 'btn btn-primary btn-fill btn-unfocus'
        },
        buttonsStyling: false,
        confirmButtonText: "閉じる",
        focusConfirm: false,
      }).then(() => {
        this.$emit('reload');
      });
    },
    showItemPosData($event, index) {
      document.getElementById(`accordion-${index}`).classList.toggle("active")
      const panel = document.getElementById(`panel-${index}`);
      if (panel.style.display === "block") {
        panel.style.display = "none";
      } else {
        panel.style.display = "block";
      }
    },
    onConfirmClose() {
      this.$emit('close');
    },
  }
}
</script>

<style lang="scss" scoped>
.flex-justify{
  justify-content: space-between;

}
  input {
    height: 40px;
  }
  .modal {
    background-color: #2125298a;
  }
  .modal-dialog {
    z-index: 1050;
  }
  button:focus {
    outline: none;
  }
  .btn-fill:focus {
    outline: none !important;
  }

  .dp-flex {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
  }

  .mr-10 {
    margin-right: 10px;
  }
  .disable {
    background: #E3E3E3;
    cursor: not-allowed;
    pointer-events: none;
  }
  .modal-dialog {
    z-index: 1050;
    max-width: 630px;
  }
  .btn-primary {
    border: 2px solid #0090d7 !important;
  }
  .reservation_null {
    width: 100%;
    text-align: center;
    font-size: 16px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .modal-open {
    overflow: hidden;
  }
  .header_modal_icon {
    align-items: center !important;
    padding: 0.5rem 1rem;
    .modal-title {
      padding-top: 10px;
    }
  }
  .modal-body {
    padding: 0 !important;
  }
  .body-content {
    padding: 1rem !important;
  }
  .transform-checkbox {
    width: 20px;
    height: 20px;
    accent-color: #0090d7;
  }

  .d-flex-content {
    display: flex;
  }
  .column-1 {
    padding-left: 40px;
    width: 160px;
  }
  .column-2 {
    width: 110px;
  }
  .column-3 {
    width: 155px;
    margin-left: 10px;
  }
  .column-4 {
    margin-left: 10px;
    width: 135px;
  }
  #span-icon-customer {
    position: unset !important;
  }

  @media screen and (max-width: 480px) {
    .count-reservation {
      margin-left: 0 !important;
    }
    .d-block-content {
      display: block;
    }

    .column-1 {
      padding-left: 30px;
      width: 110px;
    }
    .column-2 {
      width: 60px;
    }
    .column-3 {
      width: 85px;
      margin-left: 20px;
    }
    .column-4 {
      margin-left: 20px;
      width: 70px;
    }
    .customer_table {
      th {
        width: 33%;
        background-color: #0090d7;
        color: #fff;
        font-weight: unset;
        padding: 10px 0;
        text-align: center;
      };
      td {
        padding: 5px 0;
        text-align: center;
        border-bottom: 1px solid #DDDDDD;
      }
    }
  }

  .accordion {
  cursor: pointer;
  padding: 15px 0;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  transition: 0.4s;
  background: #fff;
  padding-bottom: 0;
  &.active {
    border-bottom: none;
  }
}


/* Style the accordion panel. Note: hidden by default */
.panel {
  mar: 0 18px;
  background-color: #ffefe5;
  display: none;
  padding-right: 0;
  overflow: hidden;
  font-size: 12px;
}

button.accordion .icon-acc {
  position: relative;
  display: inline-block;
  width: 15px;
  height: 15px;
  border-right: 0.2em solid black;
  border-top: 0.2em solid black;
  transform: rotate(135deg);
  margin-right: 0.5em;
  margin-left: 1.0em;
  float: right;
  margin-top: 8px;
}

button.accordion.active .icon-acc {
  position: relative;
  margin-top: 17px;
  content: "";
  display: inline-block;
  width: 15px;
  height: 15px;
  border-right: 0.2em solid black;
  border-top: 0.2em solid black;
  transform: rotate(-45deg);
  margin-right: 0.5em;
  margin-left: 1.0em;
  float: right;
}

.header-res {
  display: flex;
  align-items: stretch;
  .col-res-1 {
    width: 30% !important;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .col-res-2 {
    width: 23%;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .col-res-3 {
    width: 23% !important;
    margin-bottom: 10px;
    margin-left: 10px;
  }
}

.border-bottom-res {
  width: 100%;
  border-bottom: 1px solid #DEE2E6;
  margin-top: 15px;
}
.w-30-res {
  width: 30.5% !important;
}
.w-27-res {
  width: 27% !important;
}
</style>
