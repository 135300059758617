<script>
import Vue from 'vue';

export default Vue.extend({
  computed: {
    termsUserUrl() {
      return process.env.OTOMO_TERMS_OF_USE_USER_URL || '';
    },
    termsStoresUrl() {
      return process.env.OTOMO_TERMS_OF_USE_STORES_URL || '';
    },
    privacyPolicyUrl() {
      return process.env.OTOMO_PRIVACY_POLICY_URL || '';
    },
    groupName() {
      switch(this.slugData){
        case 'aumo':
          return '';
        default:
          return '';
      }
    },
  },
  data() {
    return {
      slugData:null,
      location: window.location
    };
  },
  mounted(){
    this.slugData = this.$route.params.slug;
  },
  methods: {
    termsOfUseUrl(slug) {
      switch(slug){
        case 'otomo':
          return null;
        case 'aumo':
          return 'https://bespo.tech/book-tablerequest/aumo/terms_and_conditions/user_contract.pdf';
        default:
          return 'https://bespo.tech/book-tablerequest/contract.html';
      }
    },
    aboutCompanyUrl(slug) {
      switch(slug){
        case 'aumo':
          return 'https://aumo.jp/inquiry';
        default:
          return 'https://bespo.tech/';
      }
    },
  }
});
</script>
<style scoped>
</style>
