<template>

  <div>
    <div v-if="unreadNotices.length>0" class="notice-container" style="margin-bottom: 1em;">
      <div class="unread-notice" v-for="n, i in unreadNotices" :key="i">
        <i class="fa fa-exclamation-circle" style="vertical-align: middle;"></i>
        <span style="padding-left: 10px">{{n.title}}</span>
        <button class="btn btn-primary-confirm btn-sm m-0 confirm" @click="confirmNotice(n)">確認</button>
      </div>
    </div>

    <div class="modal fade show" style="display: block;" v-if="showStatus">
      <div class="modal-dialog modal-dialog-box">
        <div class="modal-content">
          <div class="modal-header timetable_header">
            <h5 class="modal-title">タイムテーブルの表示</h5>
            <button type="button" class="close close_reservation_modal" @click="showStatus=false"><span style="font-size: 2.5rem">&times;</span></button>
          </div>
          <div class="modal-body status_body">
            <div class="d-flex timetable_span">
              <span>チップの表示</span>
            </div>
            <div class="d-flex timetable">
              <div class="label reserved">来店待ち</div>
              <div>
                <span>•••予約ありのステータス</span>
              </div>
            </div>
            <div class="d-flex timetable">
              <div class="label seating">来店</div>
              <div>
                <span>•••着席済み（来店済み）のステータス</span>
              </div>
            </div>
            <div class="d-flex timetable">
              <div class="label finished">会計済</div>
              <div>
                <span>•••会計が終わっているステータス</span>
              </div>
            </div>
            <div class="d-flex timetable">
              <div class="label not_show">来店無</div>
              <div>
                <span>•••来店がなかった(NoShow)のステータス</span>
              </div>
            </div>
            <div class="d-flex timetable">
              <div class="label block " style="font-size: 10px">ブロック</div>
              <div>
                <span>•••オンラインの予約受付をブロックしている箇所　※ウォークイン/予約登録は可能</span>
              </div>
            </div>
            <hr>
            <div class="d-flex timetable_span">
              <span>アイコンの表示</span>
            </div>
            <div class="d-flex timetable">
              <div class="label">
                <img class="my-bell" style="margin-right: 5px; width: 22px;" src="../../../../../assets/images/icon_timetable/icon_bell.svg" alt="img">
              </div>
              <div>
                <span>•••当日受け付けた予約</span>
              </div>
            </div>
            <div class="d-flex timetable">
              <div class="label">
                <img class="my-fork" style="margin-right: 5px; width: 22px;" src="../../../../../assets/images/icon_timetable/icon_fork.svg" alt="img">
              </div>
              <div>
                <span>•••コース予約</span>
              </div>
            </div>
            <div class="d-flex timetable">
              <div class="label">
                <span class="square-number" style="padding: 0 6px; margin-right: 5px;">2</span>
              </div>
              <div>
                <span>•••お客様の来店回数</span>
              </div>
            </div>
            <div class="d-flex timetable">
              <div class="label">
                <img class="my-primary" style="margin-right: 5px; width: 22px;" src="../../../../../assets/images/icon_timetable/icon_link.svg" alt="img">
              </div>
              <div>
                <span>•••テーブルを連結している席</span>
              </div>
            </div>
            <div class="d-flex timetable">
              <div class="label">
                <img class="my-message" style="margin-right: 5px; width: 22px;" src="../../../../../assets/images/icon_timetable/icon_message.svg" alt="img">
              </div>
              <div>
                <span>•••予約に関する備考欄に記載有</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div  class="right-sidebar" :class="{expandedRightSidebar: showModalReservationBlock }">
    <ReservationBlockModal v-show="showModalReservationBlock" :working-time="workingTime" :reservation="reservation" :datetime_blocks="datetime_blocks" @reload="$emit('reload')" @close="changeModalReservation" />
    </div>
    <ReservationCourseModal v-if="showModalReservationCourse" :date="date" @close="changeModalReservationCourse" />
    <MemoListModal v-if="showModalMemo" :date="date" :memo="memo" @close="showModalMemo = false" @save-memo="saveMemo" :memo-list="memoList" @reload-memo="reloadMemo"  />
    <Loading v-if="loading" />
  </div>
</template>

<script>
import moment from 'moment';
moment.locale("ja", {weekdaysShort: ["日", "月", "火", "水", "木", "金", "土"]});
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/ja';
import ReservationCourseModal from './ReservationCourseModal.vue';
import ReservationBlockModal from '../../components/ReservationBlockModal.vue';
import MemoModal from './MemoModal.vue';
import {DisableScrollBody, sleep} from "../../../shared/util";
import Loading from '../../components/Loading';
import DateComponent from "./TopNavDateComponent.vue";
import TopNavIcons from "./TopNavIcons.vue";
import MemoListModal from "./MemoListModal.vue";
import Vue from "vue";

export default {
  components: {
    MemoListModal,
    TopNavIcons, DatePicker,DateComponent, ReservationCourseModal, ReservationBlockModal, Loading, MemoModal},
  props: {
    date: {
      type: Date,
    },
    summary: {
      type: Object,
    },
    workingTimes: {
      type: Array,
    },
    notices: {
      type: Array,
    },
    memo: {
      type: Object
    },
    memoList: {
      type: Array
    },
    workStart: {
      type: Object
    },
    workEnd: {
      type: Object
    },
    timeTableStart: {
      type: Object
    },
    timeTableEnd: {
      type: Object
    },
    showModalReservationCourse:{
      type: Boolean
    },
    showModalReservationBlock:{
      type: Boolean
    }
  },
  data() {
    return {
      showStatus: false,
      showNotice: false,
      showPrintDialog: false,
      printTimeStart: null,
      printTimeEnd: null,
      printPressTime: null,
      loading: false,
      noticeViewSize: 5,
      datetime_blocks: [],
      reservation: {},
      workingTime: {},
      showModalMemo: false,
      dateComponentInstance: null,
      iconsComponentInstance: null,
    };
  },
  computed: {
    unreadNotices() {
      return this.notices.filter(n => !n.confirmed_at)
    },

  },
  watch: {
    date: {
      handler(val) {
        this.noticeViewSize = 5
        this.changeDate(val)
      },
    },
    showModalReservationCourse(newVal) {
      DisableScrollBody(newVal)

      return newVal;
    },
    showStatus(newVal) {
      DisableScrollBody(newVal)
      this.reRenderDateComponent()
      return newVal;
    },
    printPressTime(){
      this.reRenderDateComponent()
    },

    showNotice() {
      this.reRenderIconsComponent();
    },

    memo() {
      this.reRenderIconsComponent();
    },

    showModalMemo() {
      this.reRenderIconsComponent();
    },

    showPrintDialog() {
      this.reRenderIconsComponent();
    },
    printTimeStart() {
      this.reRenderIconsComponent();
    },
    printTimeEnd() {
      this.reRenderIconsComponent();
    },

    notices() {
      this.reRenderIconsComponent();
    },

    showModalReservationBlock() {
      this.reRenderIconsComponent();
    },

  },
  mounted() {
    this.mountIconsComponent()

  },

  methods: {
    moment: function () {
      return moment();
    },
    reloadMemo(date){
      this.$emit('reload-memo', date)
    },
    mountDateComponent(){
      const mountElement = document.querySelector("#toMountData");

      if (mountElement) {
        this.dateComponentInstance = new Vue({
          render: (h) => h(DateComponent, {
            props: {
              date: this.date,
              showStatus: this.showStatus,
              printPressTime: this.printPressTime,
              timeTableStart: this.timeTableStart,
              timeTableEnd: this.timeTableEnd,
              summary: this.summary
            },
            on: {
              'change-date': this.changeDate,
              'close-print-mode': this.closePrintMode
            }
          })
        });

        this.dateComponentInstance.$mount();
        mountElement.appendChild(this.dateComponentInstance.$el);
      }
    },
    mountIconsComponent() {
      const mountElement = document.querySelector("#navItems");
      if (mountElement) {
        this.iconsComponentInstance = new Vue({
          render: (h) => h(TopNavIcons, {
            props: {
              showNotice: this.showNotice,
              memo: this.memo,
              showModalMemo: this.showModalMemo,
              showPrintDialog: this.showPrintDialog,
              printError: this.printError,
              printTimeStart: this.printTimeStart,
              printTimeEnd: this.printTimeEnd,
              notices: this.notices,
              showModalReservationBlock: this.showModalReservationBlock,
            },
            on: {
              'modal-reservation-block': this.modalReservationBlock,
              'change-show-notice': this.changeShowNotice,
              'hide-notice': this.hideNotice,
              'confirm-notice': this.confirmNotice,
              'change-modal-reservation-course': this.changeModalReservationCourse,
              'change-show-memo': this.changeShowMemo,
            }
          })

        });
        this.iconsComponentInstance.$mount()
        mountElement.appendChild(this.iconsComponentInstance.$el);
      }
    },
    unmountDateComponent() {
      if (this.dateComponentInstance ) {
        // Destroy the Vue instance
        this.dateComponentInstance.$destroy();
        // Remove the mounted element from the DOM
        this.dateComponentInstance.$el.remove();
      }
    },
    unmountIconsComponent() {
      if (this.iconsComponentInstance.$el) {
        // Destroy the Vue instance
        this.iconsComponentInstance.$destroy();
        // Remove the mounted element from the DOM
        this.iconsComponentInstance.$el.remove();
      }
    },
    reRenderIconsComponent() {
      this.unmountIconsComponent()
      this.mountIconsComponent()
    },
    reRenderDateComponent() {
      this.unmountDateComponent()
      this.mountDateComponent()
    },
    hideNotice() {
      this.showNotice = false
    },
    changeModalReservationCourse() {
      this.showPrintDialog = false
      this.$emit('change-modal-reservation-course')
    },
    changeModalReservation(){
      this.showPrintDialog = false
      this.$emit('change-modal-reservation')
    },
    changeShowMemo() {
      this.showModalMemo = true
    },
    changeShowNotice() {
      this.showNotice = !this.showNotice
    },
    changeDate(date) {
      this.$emit('change-date', date);
      this.$root.$emit('check-reservation', date);
    },
    viewMoreNotice() {
      this.noticeViewSize += 5
    },
    confirmNotice(notice) {
      this.$emit('confirm-notice', notice);
    },
    saveMemo(params) {
      this.$emit('save-memo', params);
    },
    async modalReservationBlock() {
      this.reservation = {start_date: this.date}
      this.datetime_blocks = []

      this.workingTime = {
        start_time: this.workStart.format('YYYY-MM-DD HH:mm'),
        end_time: this.workEnd.format('YYYY-MM-DD HH:mm'),
      }

      this.datetime_blocks.push({
        id: null,
        start_time: this.workStart.format('HH:mm'),
        end_time: this.workEnd.format('HH:mm'),
        _destroy: false
      })

      this.showModalReservationBlock = true;
    },
    async closePrintMode() {
      this.loading = true
      document.body.classList.toggle('print-mode')
      this.printPressTime = null
      this.$emit('change-print-time', {
        start: null,
        end: null,
      })
      this.showPrintDialog = false
      await sleep(300)
      this.loading = false
    },
  },
};
</script>

<style lang="scss">
.mx-datepicker {
  width: auto;
}
.mx-btn-text {
  height: inherit;
}
</style>
<style lang="scss" scoped>

.btn-outline-primary {
  background-color: #fff;
  border: 2px solid var(--color-main) !important;
  color: var(--color-main);
  font-size: 20px;
  font-weight: 550;
  &:hover {
    opacity: 0.7;
  }
}
.btn-outline-green {
  color: var(--color-tt-course);
  border: 2px solid var(--color-tt-course);
  background: #fff;
  display: flex !important;
  align-items: center;
  &:hover {
    opacity: 0.7;
  }
}
.btn-outline-blue {
  color: var(--color-tt-today);
  border: none;
  background: #fff;
  display: flex !important;
  align-items: center;
  &:hover {
    opacity: 0.7;
  }
}
.btn-outline-purple {
  color: var(--color-tt-block);
  border: 2px solid var(--color-tt-block);
  background: #fff;
  display: flex !important;
  align-items: center;
  &:hover {
    opacity: 0.7;
  }
}
.btn-memo {
  color: var(--color-tt-message);
  border: 2px solid var(--color-tt-message);
  background: #fff;
  display: flex !important;
  align-items: center;
  &:hover {
    opacity: 0.7;
  }
}
.timetable {
  align-items: center;
  margin-bottom: 5px;
  .label {
    padding: 8px 0px;
    margin-right: 4px;
    min-width: 45px;
    width: 45px;
    font-size: 12px;
    text-align: center;
  }
}
.h-38 {
  height: 38px;
}

.supplementary {
  border-radius: 6px;
  border: 1px dashed black;
  font-size: 11px;
  line-height: 13px;
  background-color: #fff;
}

.square-number {
  background-color: var(--color-main);
  min-width: 16px;
  color: #FFFFFF;
  display: inline-block;
  text-align: center;
  font-weight: bold;
}

.notice {
  position: relative;
  margin-bottom: 15px;
  > .btn {
    padding: 6px 12px;
    margin: 0;
    min-height: 38px;
    font-size: 13px;
    i.fa {
      font-size: 1.2rem;
    }
  }
  .popover {
    position: absolute;
    top: 39px;
    left: -230px;
    min-width: 550px;
    font-size: 12px;

    .arrow {
      left: 300px;
    }
    .list-group-item {
      border: none;
      border-bottom: 1px solid rgba(0, 0, 0, 0.125);
      padding: 0.5rem 0.5rem;
    }
    &.print-dialog {
      left: 100px;
    }
  }
  .popover-body {
    overflow-y: auto;
    max-height: 400px;
  }
  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
  }
  .print-dialog {
    font-size: 13px;
    .form-control {
      font-size: 14px;
    }
    .btn {
      margin: 0;
      font-size: 13px;
    }
  }
}

.print-btn-group {
  align-items: center;
  position: absolute;
  display: flex;
  right: 55px;
  pointer-events: auto;

  .btn {
    padding: 6px 8px;
    font-size: 14px !important;
    margin-left: 8px;
  }
}

.btn.confirm {
  float: right;
}

.btn-primary-confirm {
  background-color: #ffffff;
  border: 1px solid var(--color-main);
  color: #212529;
}
.btn-primary-confirm:hover {
  opacity: 0.7;
}
.modal-dialog {
  z-index: 1050;
  max-width: 560px;
}
.modal-dialog-box{
  max-width: 619px;
}
.btn-today {
  font-size: 16px !important;
}

.unread-notice {
  padding: 4px 12px;
  background: var(--color-error);
  border-radius: 4px;
  font-size: 12px;
  color: #fff;
  margin-top: 4px;
  line-height: 26px;
  i.fa {
    font-size: 1.2rem;
  }
}
.top_title {
  font-size: 20px;
  min-width: 88px;
}
.timetable_span {
  font-weight: 700;

}
.status_body {
  padding-left: 30px;
}
.timetable_header {
  padding-left: 30px;
  align-items: center !important;
  .modal-title {
    font-weight: 600;
    padding-top: 10px;
  }
}
.opacity-unset:hover {
  opacity: unset !important;
}
.annotate_ipad {
  display: none !important;
}

.barge-noti-reservations {
  position: absolute;
  top: -10px;
  right: -10px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background: var(--color-tt-today);
  color: #fff;
  text-align: center;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal {
  background-color: #2125298a;
}
.right-sidebar{
  background-color:var(--color-white);
  position:absolute;
  height:100vh;
  top:0;
  right:0;
  z-index:2000;
  width:0%;
  transition:width 0.5s ease;
  display:flex;
}
.expandedRightSidebar{
  background-color:var(--color-white);
  width:580px;
  position:fixed;
}
/* css for ipad */
@media only screen and (min-width:480px) and (max-width:1000px){
  .expandedRightSidebar{
    width:580px;
  }
}

/* css for mobile */
@media only screen and (max-width:480px) {
  .expandedRightSidebar {
    width: 100%;
  }
}

@media screen and (max-width: 1340px) {
  .annotate_ipad {
    display: flex !important;
  }
  .annotate {
    display: none !important;
  }
}
@media screen and (min-width: 996px) and (max-width: 1112px) {
  .supplementary {
    display: none !important;
  }
}
</style>
