<template>
  <header class="header header-owner">
    <div class="icon_area" @click="changeSidebar">
      <button id="btn_menu" class="menu-trigger" :class="openSidebar ? 'active' : ''"
              @click="animateMenu"
      >
        <span />
        <span />
        <span />
      </button>
    </div>
    <div class="logo" :class="[slug ? `brand_${slug}` : '']" />
  </header>
</template>

<script>
export default {
  props: {
    handleSidebar: {
      type: Function,
      required: true
    },
    openSidebar: {
      type: Boolean,
      required: true
    },
  },
  data() {
    return {
      slug: document.getElementById('slug').value,
    }
  },
  methods: {
    changeSidebar() {
      this.handleSidebar();
    },

    animateMenu() {
      var element = document.getElementById('btn_menu');
      element.classList.toggle('active');
    }
  }
};
</script>
<style scoped>
  .icon_area {
    right: unset;
  }
@media screen and (max-height: 1366px) {
  .icon_area {
    left: 16px;
  }
}
</style>
