<template>
  <main class="owner_container bg_white">
    <div class="owner_main_area">
      <div class="wrapper-body_dashboard">
        <h2 class="mb_medium">アカウント管理</h2>
        <div>
          <div class="owner_title_table mb_medium">オーナーアカウント</div>
          <table class="sr_table">
            <thead>
              <tr>
                <th class="large" :class="checkdevice == 'Mobile' ? 'w_35' : ''">法人名</th>
              </tr>
            </thead>
            <tbody class="to_detail">
              <tr @click="detailOwner">
                <td class="down_line owner_color-blue">{{ owner.name }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="flex alignCenter between mb_small mt_large">
         <div class="count-item-container">
           <div class="store-account">
             店舗アカウント
           </div>
           <div class="shop_count_left"> 店舗追加可能数： {{ owner.count_left_shops }} 店舗</div>
         </div>
          <div v-if="owner.permission!=='HAVE_FULL_ACCESS'" class="btn_area control">
            <button v-if="owner.status == 'active' && owner.contract_end_at == null && owner.count_left_shops > 0" class="btn primary_border fz_medium btn_pointer" @click="newRestaurant">
              店舗の新規登録
            </button>
          </div>
        </div>

        <div v-if="checkdevice == 'PC' || checkdevice == 'Ipad'">
          <table class="sr_table">
            <thead>
              <tr>
                <th class="w_27">店舗名</th>
                <th class="">ステータス</th>
                <th class="" :class="checkdevice == 'PC' ? 'w_135px' : 'w_85px'" />
              </tr>
            </thead>
            <tbody class="to_detail">
              <tr v-for="(shopAdministrator, index) in shopAdministrators" :key="index">
                <td class="down_line owner_color-blue" @click="selectRow(shopAdministrator.id)">
                  {{ shopAdministrator.shop_name }}
                </td>
                <td class="" :class="shopAdministrator.status == 'inactive' || shopAdministrator.status == 'admin_cancel' ? 'text_color-reb' : ''"
                    @click="selectRow(shopAdministrator.id)"
                >
                  {{ checkStatus(shopAdministrator) }}
                </td>
                <td v-if="checkdevice == 'PC'" class="to_detail_btn">
                  <button v-if="shopAdministrator.status == 'active' || shopAdministrator.status == 'owner_cancel'"
                          class="btn primary btn_row btn_pointer"
                          @click="openSmart(shopAdministrator)"
                  >
                    予約管理画面へ
                  </button>
                  <div v-else class="btn btn_row" />
                </td>
                <td v-if="checkdevice == 'Ipad'" class="to_detail_btn">
                  <button v-if="shopAdministrator.status == 'active' || shopAdministrator.status == 'owner_cancel'"
                          class="btn primary btn_row btn_pointer"
                          @click="openSmart(shopAdministrator)"
                  >
                    店舗へ
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="checkdevice == 'Mobile'">
          <table class="sr_table">
            <thead>
              <tr>
                <th class="large">店舗名</th>
                <th class="w_25">ステータス</th>
                <th class="w_70px" />
              </tr>
            </thead>
            <tbody class="to_detail">
              <tr v-for="(shopAdministrator, index) in shopAdministrators" :key="index">
                <td class="down_line owner_color-blue" @click="selectRow(shopAdministrator.id)">
                  {{ shopAdministrator.shop_name }}
                </td>
                <td class="" :class="shopAdministrator.status == 'inactive' || shopAdministrator.status == 'admin_cancel' ? 'text_color-reb' : ''"
                    @click="selectRow(shopAdministrator.id)"
                >
                  {{ checkStatus(shopAdministrator) }}
                </td>
                <td class="to_detail_btn">
                  <button v-if="shopAdministrator.status == 'active' || shopAdministrator.status == 'owner_cancel'"
                          class="btn primary btn_row btn_pointer"
                          @click="openSmart(shopAdministrator)"
                  >
                    店舗へ
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-show="shopAdministrators.length === 0" class="text-center text_24">表示するデータはありません。</div>
      </div>
    </div>
  </main>
</template>

<script>
import moment from 'moment';
import { ShopAdministratorsService } from '../../../services/Owner/shop_administrators.service';
import { OwnersService } from '../../../services/Owner/owners.service';
import utils from '../../../common/util';
import { mapGetters } from 'vuex';
import { ChangeShopService } from '../../../services/Owner/change_shop.service';
export default {
  data() {
    return {
      shopAdministrators: [],
      checkdevice: '',
      owner: {},
      shopAdministrator: {},
      slug: ''
    };
  },
  computed: {
    ...mapGetters({
      restaurantDefault: 'getRestaurant'
    })
  },
  created() {
    window.addEventListener('resize', this.isMobile);
  },
  destroyed() {
    window.removeEventListener('resize', this.isMobile);
    window.removeEventListener('resize', this.isresize);
    this.unresize();
  },
  async mounted() {
    this.isMobile();
    this.getOwnerShop();
    this.getOwners();
  },
  updated() {
    this.isresize();
    window.addEventListener('resize', this.isresize);
  },
  methods: {
    ...utils,
    checkmobile() {
      let ua = navigator.userAgent.toLowerCase();
      if (ua.indexOf('android') > -1) {
        return true;
      } else {
        return false;
      }
    },
    isresize() {
      const wdth = window.screen.width;
      if (wdth < 480 && this.checkmobile()) {
        const height_container = document.getElementsByClassName('owner_container')[0].clientHeight;
        if ((window.innerHeight - 110) > height_container) {
          document.getElementById('body_wrapper_owner').style.minHeight = (window.innerHeight + 10) + 'px';
        } else {
          document.getElementById('body_wrapper_owner').style.minHeight = window.innerHeight + 'px';
        }
      }
    },
    unresize() {
      const wdth = window.screen.width;
      if (wdth < 480 && this.checkmobile()) {
        document.getElementById('body_wrapper_owner').style.height = '100%';
      }
    },
    isMobile() {
      if( screen.width > 1366) {
        this.checkdevice = 'PC';
      } else if(screen.width > 480 && screen.width <= 1366){
        this.checkdevice = 'Ipad';
      }
      else {
        this.checkdevice = 'Mobile';
      }
    },
    async getOwners() {
      try {
        const { response } = await OwnersService.index();
        this.owner = response.data;
        if (this.owner.media_name != null) { this.slug = this.owner.media_name };
      } catch (error) {
        console.log(error);
      }
    },
    async getOwnerShop() {
      try {
        const { response }  = await ShopAdministratorsService.index();
        this.shopAdministrators = response.data;
      }catch(error){
        console.log(error);
      }
    },
    checkStatus(shop) {
      let status;
      if(shop.status == 'active' || shop.status == 'owner_cancel'){
        status = '利用中';
      } else if (shop.status == 'inactive'){
        status = '解約済';
      } else if (shop.status == 'admin_cancel'){
        status = '利用停止中';
      }
      return status;
    },
    checkUpdateAt(shop) {
      let updateAt;

      if(shop.contract_update_at == null || (shop.contract_end_at == !null &&
         shop.contract_start_at == null &&
         shop.contract_update_at == !null)) {
        updateAt = '';
      } else if(['active', 'owner_cancel', 'admin_cancel', 'inactive'].includes(shop.status) ){
        updateAt = this.formatDateWithoutLeadingZeros(shop.contract_update_at);
      }
      return updateAt;
    },
    checkCancelAt(shop) {
      let cancelAt;
      if (shop.status != 'inactive' && shop.contract_end_at != null){
        cancelAt = moment(shop.contract_end_at, 'YYYY/MM/DD').format('YYYY/M/D') + 'まで利用可能';
      } else if (shop.status == 'inactive') {
        cancelAt = this.formatDateWithoutLeadingZeros(shop.contract_end_at);
      }
      return cancelAt;
    },
    selectRow(id) {
      return this.$router.push({ path: `/${this.slug}/owner/shop/${id}/detail`});
    },
    async openSmart(shop) {
      // 改定お知らせ
      await this.getRestaurantAdministrator(shop.id);
      await ChangeShopService.changeShop({ shop_id: shop.id});
      const linkShop = this.slug == '' ? '/shop_manager/time_table' : `/${this.slug}/shop_manager/time_table`

      window.location.href = linkShop;
    },
    newRestaurant() {
      return this.$router.push({ path: `/${this.slug}/owner/shop/create`});
    },
    detailOwner() {
      return this.$router.push({ path: `/${this.slug}/owner/detail`});
    },
    async getRestaurantAdministrator(id) {
      this.$loading(true);
      try {
        const { response } = await ShopAdministratorsService.get(id);
        this.shopAdministrator = response.data;
        this.$loading(false);
      } catch (error) {
        this.$loading(false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.store-account{
  font-size: 1.5em;
  font-weight: bold;
  color: black;
  margin-bottom: 2px;
}
.shop_count_left{
  font-size: 14px;
}
.control{
  width: 18%;
}
.primary_border {
  padding: 16px;
}
.mt_small {
  margin-top: 2px;
}
.fz_medium{
  font-size: 16px;
}
.to_detail_btn {
  text-overflow: unset !important;
}
.mt_large {
  margin-top: 40px;
}
.btn_area {
  height: 52px;
}
.shop_count {
  font-size: 14px;
  width: 15%;
  padding: 0 10px;
}
.mb_medium{
  color: #000000;
}
.count-item-container{
  display: flex;
  justify-content: space-between;
  width: 100%;
}
@media screen and (max-width: 740px) {
  .control{
    width: 50%;
  }
  .primary_border {
    padding: 8px;
  }
  .fz_medium{
  font-size: 12px;
  }
  .to_detail_btn {
    text-overflow: unset !important;
  }
  .mt_large {
    margin-top: 30px;
  }
  .btn_area {
    height: 32px;
  }
  .count-item-container{
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
@media screen and ( max-width: 1366px) and ( min-width: 720px){
  .control{
    width: 25%;
  }
  .primary_border {
    padding: 12px;
  }
  .mt_large {
    margin-top: 30px;
  }
  .btn_area {
    height: 44px;
  }

}


.min_width{
  min-width: 200px;
  background: red;
  display: flex;
  justify-content: flex-end;
}
@media screen and (max-width: 1366px) {
  .bg_white{
    background: white;
  }
}


</style>
