<template>
  <main class="owner_container bg_white">
    <div class="owner_main_area">
      <h1 class="title_owner">店舗詳細</h1>
      <div class="input_owner">
        <label class="owner_form_label">紹介画像</label>
        <div v-show="shopAdministrator.image" class="file_upload_image">
          <img class="image_shop" :src="shopAdministrator.image">
        </div>
        <div v-show="!shopAdministrator.image" class="file_upload_image no_image">
          <div class="file_upload_image_main">
            <div class="add_image_shop" />
          </div>
        </div>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">店舗名</label>
        <div class="owner_margin_inline_10 owner_line_down owner_color_black">{{ shopAdministrator.name }}</div>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">店舗名（カナ）</label>
        <div class="owner_margin_inline_10 owner_line_down owner_color_black">
          <p class="break-space">{{ shopAdministrator.name_kana }}</p>
        </div>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">電話番号</label>
        <div class="owner_margin_inline_10 owner_line_down owner_color_black">{{ shopAdministrator.tel }}</div>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">郵便番号</label>
        <div class="owner_margin_inline_10 owner_line_down owner_color_black">{{ shopAdministrator.zipcode }}</div>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">都道府県</label>
        <div class="owner_margin_inline_10 owner_line_down owner_color_black">{{ getPrefectureName(prefectures, shopAdministrator.prefecture_id) }}</div>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">市区町村番地</label>
        <div class="owner_margin_inline_10 owner_line_down owner_color_black">{{ shopAdministrator.address }}</div>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">アクセス</label>
        <div class="owner_margin_inline_10 owner_line_down owner_color_black">{{ shopAdministrator.access }}</div>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">店舗URL</label>
        <div class="owner_margin_inline_10 owner_line_down owner_color_black">{{ shopAdministrator.official_url }}</div>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">店舗紹介タイトル</label>
        <div class="owner_margin_inline_10 owner_line_down owner_color_black">{{ shopAdministrator.desc_header }}</div>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">店舗紹介本文</label>
        <div class="owner_margin_inline_10 owner_line_down owner_color_black">{{ shopAdministrator.desc_content }}</div>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">平均単価</label>
        <div class="owner_margin_inline_10 owner_line_down owner_color_black">{{ shopAdministrator.price }}</div>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">カテゴリー</label>
        <div class="owner_margin_inline_10 owner_line_down owner_color_black">{{ getTagName(tag, shopAdministrator.tag_id) }}</div>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">店舗ID</label>
        <div class="owner_margin_inline_10 owner_line_down owner_color_black">{{ shopAdministrator.login_id }}</div>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">店舗パスワード</label>
        <div class="text_wrap pasword_text owner_color_black ml_small">{{ password }}</div>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">Instagramのユーザーネーム</label>
        <div v-if="shopAdministrator.instagram_name" class="owner_margin_inline_10 owner_line_down owner_color_black">
          <a v-bind:href="link" target="_blank">https://www.instagram/{{ shopAdministrator.instagram_name }}</a>
        </div>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">登録日</label>
        <div class="owner_margin_inline_10 owner_line_down owner_color_black">{{ formatDate(shopAdministrator.created_at) }}</div>
      </div>
      <div class="input_owner">
        <label class="owner_form_label">ステータス</label>
        <div class="owner_margin_inline_10 owner_line_down" :class="changeTextColor() ? 'text_color-reb' : 'owner_color_black'">
          {{ status[shopAdministrator.status] }}
        </div>
      </div>
      <div class="btn_area_owner owner_btn_padding" :class="setButtonArea">
        <router-link
          v-show="shopAdministrator.status !== 'inactive'"
          :to="{ path: slug == null ? `/owner/shop/${shopAdministrator.id}/edit` : `/${this.slug}/owner/shop/${shopAdministrator.id}/edit` }"
          class="btn btn_owner primary"
        >
          編集する
        </router-link>
        <router-link
          :to="{ path: slug == null ? '/owner' : `/${this.slug}/owner` }"
          class="btn btn_owner primary_border ml_medium"
        >
          戻る
        </router-link>
        <template v-if="$router.history.current.params.slug !== 'aumo'">
          <router-link
            v-if="!shopAdministrator.oem_payment_type"
            v-show="['active', 'owner_cancel', 'admin_cancel'].includes(shopAdministrator.status) && shopAdministrator.contract_end_at == null"
            :to="{}"
            class="btn btn_owner delete ml_medium"
            @click.native="toDetele"
          >
            解約申請する
          </router-link>
          <router-link
            v-if="!shopAdministrator.oem_payment_type"
            v-show="['active', 'owner_cancel', 'admin_cancel'].includes(shopAdministrator.status) && shopAdministrator.contract_end_at != null"
            :to="{}"
            class="btn btn_owner delete ml_medium unsubscribe-cancel"
            @click.native="unsubscribeCancel"
          >
            解約申請の取消をする
          </router-link>
        </template>
      </div>
    </div>
  </main>
</template>

<script>
import { ShopAdministratorsService } from '../../../services/Owner/shop_administrators.service';
import { mapGetters } from 'vuex';
import utils from '../../../common/util';

export default {
  data() {
    return {
      tag: [],
      shopAdministrator: {},
      password: '********',
      status: {
        active: '利用中',
        admin_cancel: '利用停止中',
        owner_cancel: '利用中',
        inactive: '解約済',
      },
      dialog:false,
      slug: '',
      link: '',
    };
  },
  computed: {
    ...mapGetters({
      prefectures: 'getAllPrefectures',
    }),
    setButtonArea() {
      if (this.shopAdministrator.status === 'inactive') {
        return '';
      } else if (this.shopAdministrator.status === 'owner_cancel') {
        return 'two_items';
      } else {
        return 'three_items';
      }
    },
  },
  created() {
    this.getRestaurantAdministrator();
    this.getTag();
  },
  methods: {
    ...utils,
    getLink() {
      this.link = 'https://www.instagram.com/' + this.shopAdministrator.instagram_name;
    },
    changeTextColor() {
      return ['admin_cancel', 'inactive'].includes(this.shopAdministrator.status);
    },
    async getTag() {
      this.$loading(true);
      try {
        const { response } = await ShopAdministratorsService.load_tags();
        this.tag = response.data;
        this.$loading(false);
      } catch (error){
        console.log(error);
        this.$loading(false);
      }
    },
    async getRestaurantAdministrator() {
      this.$loading(true);
      try {
        const { response } = await ShopAdministratorsService.get(this.$router.history.current.params.id);
        this.shopAdministrator = response.data;
        this.slug = response.data.media_name;
        this.getLink();
        this.$loading(false);
      } catch (error) {
        this.$loading(false);
      }
    },

    showDate(date) {
      return date !== null ? this.formatDateWithoutLeadingZeros(date) : '';
    },

    async toDetele() {
      if (this.shopAdministrator.is_cancel) {
        this.popupDelete();
      } else {
        await this.$swal.fire({
          title: '削除ができません。',
          html: `<p>解約可能の期間が過ぎています。</p>
                <p>お手数ですが、翌月の1日に解約を実行してください。</p>`,
          cancelButtonText: '閉じる',
          showCloseButton: false,
          showConfirmButton: false,
          showCancelButton: true,
          customClass: {
            cancelButton: 'cancel-popup-owner',
            title: 'title-popup-owner',
            htmlContainer: 'html-popup-owner'
          }
        });
      }
    },

    popupCheckDelete(errorMessage) {
      this.$swal.fire({
        title: 'アラート',
        html: `<p>${errorMessage}</p>`,
        cancelButtonText: '閉じる',
        showCancelButton: true,
        showConfirmButton: false,
        customClass: {
          title: 'title-popup-owner',
          cancelButton: 'cancel-popup-owner',
          htmlContainer: 'html-popup-owner',
        },
      });
    },

    async unsubscribeCancel() {
      const  textContentPopup = `<p>解約申請の取消を行います。</p>
                              <p>よろしいですか。</p>`;

      const confirm = await this.$swal.fire({
        title: '確認',
        html: textContentPopup,
        confirmButtonText: '決定する',
        cancelButtonText: 'キャンセル',
        showCancelButton: true,
        customClass: {
          confirmButton: 'confirm-popup-owner',
          title: 'title-popup-owner',
          cancelButton: 'cancel-popup-owner',
          htmlContainer: 'html-popup-owner'
        }
      });

      try {
        if(confirm.isConfirmed) {
          await ShopAdministratorsService.unsubscribeCancel({ id: this.$router.history.current.params.id });
          window.location.reload();
        }
      } catch (error) {
        this.popupCheckDelete(error.response.result.message);
      }
    },

    async popupDelete() {
      const confirm = await this.$swal.fire({
        title: '確認',
        html: `<p>店舗アカウントの解約を申請します。</p>
               <p>よろしいですか？</p>`,
        showCancelButton: true,
        confirmButtonText: '決定する',
        cancelButtonText: 'キャンセル',
        customClass: {
          confirmButton: 'confirm-popup-owner',
          title: 'title-popup-owner',
          cancelButton: 'cancel-popup-owner',
          htmlContainer: 'html-popup-owner'
        }
      });

      if (confirm.isConfirmed) {
        try {
          await ShopAdministratorsService.cancel({ id: this.$router.history.current.params.id });
          window.location.reload();
        } catch(error) {
          this.popupCheckDelete(error.response.result.message);
        }
      }
    },
  }
};
</script>

<style scoped lang="scss">
  .pasword_text {
    letter-spacing: 5px;
    font-size: 20px;
  }

  .unsubscribe-cancel {
    color: #ac0000;
    background: #fff;
    border: 2px solid #ac0000;
  }
  .break-space {
    white-space: break-spaces !important;
  }
  @media screen and (max-width: 1366px) {
    .bg_white{
      background: white;
    }
  }
</style>
