<template>
  <div>
    <div style="cursor: pointer; margin-bottom: 5px; "
         @click="changeModalReservationCourse"
    >本日のコース予約一覧
    </div>
    <div style="cursor: pointer;margin-bottom: 5px;"
            @click="changePrintDialog"
    > 印刷用画面表示
    </div>
    <div style="cursor: pointer;"
         @click="changeModalReservation"
    > 予約ブロック
    </div>

    <div class="popover fade show bs-popover-bottom print-dialog popover-pos" v-if="showPrintDialog">
      <div class="btn-close cursor-pointer" @click="closePrintDialog"> <i class="fa fa-close"></i></div>
      <div class="arrow pull-right"></div>
      <div class="container pt-3 pb-2">
        <div class="row align-items-center justify-content-center">
          <div class="w-100 px-3 d-flex align-items-center mb-3">
            <div class="px-3">
              表示形式
            </div>
            <div class="px-3">
              <label for="reservation" class="mb-0">
              <input type="radio" name="display_format" v-model="display_format" value="reservation" id="reservation" />
              予約一覧
              </label>
            </div>
            <div class="px-3">
              <label for="timetable" class="mb-0">
                <input type="radio" name="display_format" v-model="display_format" value="timetable" id="timetable" />
                タイムテーブル
              </label>
            </div>
          </div>
          <div class="w-100 px-3 d-flex align-items-center mb-3">
            <div class="px-3">印刷時間 </div>
          <div class="col-md-3">
            <DatePicker
                input-class="form-control timepicker"
                v-model="localPrintTimeStart"
                @change="handlePrintStartTimeChange"
                value-type="format"
                format="HH:mm"
                type="time"
                :show-second="false"
                :minute-step="60"
                :editable="false"
                :clearable="false">
              <i slot="icon-calendar"></i>
            </DatePicker>
          </div>
          <div>〜</div>
          <div class="col-md-3">
            <DatePicker
                input-class="form-control timepicker"
                v-model="localPrintTimeEnd"
                @change="handlePrintEndTimeChange"
                value-type="format"
                format="HH:mm"
                type="time"
                :show-second="false"
                :minute-step="60"
                :editable="false"
                :clearable="false">
              <i slot="icon-calendar"></i>
            </DatePicker>
          </div>
          </div>

          <div class="w-100 justify-content-center d-flex px-3">
            <button class="btn btn-primary btn-primary-unfocus ml-2" style="padding: 6px 8px;" @click="handleToggle">印刷画面の表示</button>
          </div>
          <div v-if="printError" class="col-md-6 pt-1 pl-2 error">{{ printError }}</div>
        </div>
      </div>
    </div>
    <div class="backdrop" v-if="showPrintDialog" @click="changePrintDialog"></div>
  </div>

</template>

<script>
import DatePicker from 'vue2-datepicker';
import ReservationCourseModal from "./ReservationCourseModal.vue";
import {ref} from "vue";

export default {
  components:{ReservationCourseModal, DatePicker},
  data(){
    return{
      showPrintDialog:false,
      localPrintTimeStart: this.printTimeStart,
      localPrintTimeEnd: this.printTimeEnd,
      showModalReservationCourse: false,
      showModalReservationBlock: false,
      display_format: ref('reservation')

    }
  },
  props: {
    date: {
      type: Date,
    },
    printError: {
      type: String, default:null
    },
    printTimeStart: {
      type: String, default:null
    },
    printTimeEnd:{
      type: String, default:null
    }
  },
  mounted() {
    document.addEventListener('click', this.handleOutsideClick);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleOutsideClick);
  },
  methods:{
    handleOutsideClick(event) {
      const popover = this.$el.querySelector('.print-dialog');
      if (popover && !popover.contains(event.target)) {
        this.closePrintDialog();
      }
    },
    handlePrintStartTimeChange(value){
      this.localPrintTimeStart = value
      event.stopPropagation()
    },
    changeModalReservationCourse() {
      this.showPrintDialog = false
      this.$emit('change-modal-reservation-course')
    },
    changeModalReservation() {
      this.showPrintDialog = false
      this.$emit('change-modal-reservation')
    },
    handlePrintEndTimeChange(value){
     this.localPrintTimeEnd = value
      event.stopPropagation()
    },
    changePrintDialog() {
      this.showPrintDialog = true
      event.stopPropagation()
    },
    handleToggle(){
      if ( this.display_format === 'reservation') {
        this.activeReservationList()
      } else {
        this.activePrintMode()
      }
    },
    activeReservationList(){
      this.showPrintDialog = false
      this.$emit('active-reservation-list',this.localPrintTimeStart, this.localPrintTimeEnd, this.display_format)
    },
    activePrintMode() {
      this.showPrintDialog = false
      this.$emit('active-print-mode', this.localPrintTimeStart, this.localPrintTimeEnd, this.display_format)
    },
    closePrintDialog(){
      this.showPrintDialog=false
      event.stopPropagation()
    }
  }
};
</script>
<style scoped>
.popover-pos{
  top: 320px;
  width: 90%;
  left: unset;
  max-width: unset;
}
.arrow{
  left:245px;
}
.popover .btn-close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background: transparent;
  border: none;
  color: inherit;
}

</style>