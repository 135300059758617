<template>
<div class="top-btn-group d-flex flex-row align-items-center">
 <div class="ml-2 notice d-flex ml-auto align-items-center py-1">

  <div style="position: relative; max-height: 40px; top:-6px" @click="changeShowNotice">
    <img class="my-bell top-nav-images circle" src="../../../../../assets/images/icon_timetable/icon_bell.svg" alt="img">
    <span v-if="unreadNewTodayReservationNotices.length" class="barge-noti-reservations">{{unreadNewTodayReservationNotices.length}}</span>
  </div>
  <div class="popover fade show bs-popover-bottom" v-if="showNotice">
    <div class="arrow"></div>
    <ul class="popover-body list-group">
      <li class="list-group-item" v-for="n, i in newTodayReservationNotices.slice(0, noticeViewSize)" :key="i">
        <span class="badge badge-danger" v-if="!n.confirmed_at">未確認</span>
        <span class="badge badge-secondary" v-else>確認済</span>
        {{n.title}}
        <button class="btn btn-primary btn-sm m-0 confirm" v-if="!n.confirmed_at" @click="confirmNotice(n)">確認</button>
      </li>
      <div class="mt-1 mb-1 text-center" v-if="newTodayReservationNotices.length < 1">お知らせがありません。</div>
      <div class="text-center mt-1" v-if="noticeViewSize < newTodayReservationNotices.length">
        <button class="btn btn-primary btn-sm m-0" @click="viewMoreNotice">もっと見る</button>
      </div>
    </ul>
  </div>
  <div class="backdrop" v-if="showNotice" @click="hideNotice"></div>
  <div class="ml-2" style="position: relative; max-height: 40px;top:-6px"
          @click="changeShowMemo"
  >
    <img class="my-memo top-nav-images circle" src="../../../../../assets/images/icon_timetable/icon_memo1.svg" alt="img">
    <span v-if="memo.content" class="barge-noti-reservations"></span>
  </div>

  <div style="display: flex; border: 2px solid  #068DCE; border-radius: 8px; overflow: hidden; width: fit-content; margin-left: 10px;">
    <div class="cursor-pointer" style="background-color: #dddddd; padding: 4px 8px; display: flex; flex-direction: column; align-items: center; justify-content: center; width: 60px;" @click="navigateToUrl">
      <img class="my-new-icon top-nav-images" style="width: 24px; height: 24px;" src="../../../../../assets/images/icon_timetable/reservation_icon_off.svg" alt="img">
      <span class="timetable-toggle1">一覧</span>
    </div>

    <div class="cursor-pointer" style="background-color:  #068DCE; padding: 4px 8px; display: flex; flex-direction: column; align-items: center; justify-content: center; width: 60px; pointer-events: none;" @click="">
      <img class="my-new-icon top-nav-images" style="width: 24px; height: 24px;" src="../../../../../assets/images/icon_timetable/time_table_white.svg" alt="img">
      <span class="timetable-toggle2">タイムテーブル</span>
    </div>
  </div>
 </div>
</div>
</template>
<script >

import DatePicker from 'vue2-datepicker';
export default {
  props:{
    showNotice:{
      Type: Boolean
    },
    memo:{
      Type:Object
    },
    showModalMemo:{
      Type: Boolean
    },
    showPrintDialog: {
      Type: Boolean
    },
    printError:{
      Type: String
    },
    printTimeEnd:{
      Type: Date
    },
    printTimeStart:{
      Type: Date
    },
   notices:{
      Type: Array
   },
    showModalReservationCourse: {
      Type: Boolean
    }

  },
  components:{DatePicker},
  data(){
    return{
      noticeViewSize:5,
      localPrintTimeStart: this.printTimeStart,
      localPrintTimeEnd: this.printTimeEnd,
    }
  },
  computed:{
    newTodayReservationNotices() {
      return this.notices.filter(n => n.notice_type == 'new_today_reservation')
    },
    unreadNewTodayReservationNotices() {
      return this.notices.filter(n => n.notice_type == 'new_today_reservation' && !n.confirmed_at)
    },
  },
  methods:{
    modalReservationBlock() {
     this.$emit('modal-reservation-block')
    },
    viewMoreNotice() {
      this.noticeViewSize += 5
    },
    changeShowNotice(){
      this.$emit('change-show-notice')
    },
    confirmNotice(notice) {
      this.$emit('confirm-notice', notice);
    },
    hideNotice(){
      this.$emit('hide-notice')
    },
    changePrintDialog() {
      this.$emit('change-print-dialog')
    },
    changeModalReservationCourse() {
      this.$emit('change-modal-reservation-course')
    },
    activePrintMode() {
     this.$emit('active-print-mode')
    },
    changeShowMemo(){
      this.$emit('change-show-memo')
    },
    handlePrintStartTimeChange(value){
      this.$emit('change-print-start-time', value)
    },
    handlePrintEndTimeChange(value){
      this.$emit('change-print-end-time', value)
    },
    navigateToUrl() {
      const slug = document.getElementById('slug').value == '' ? '' : `/${document.getElementById('slug').value}`;
      const urlParams = new URLSearchParams(window.location.search);
      const date = urlParams.get('date');
      if (date) {
        localStorage.setItem('reservationDate', date);
      }
      window.location.href = `${slug}/shop_manager/reserves`;
    }
  }

}
</script>

<style lang="scss">
.top-nav-images{
  width:32px;

}
.mx-datepicker {
  width: auto;
}
.mx-btn-text {
  height: inherit;
}
</style>
<style lang="scss" scoped>

.btn-outline-primary {
  background-color: #fff;
  border: 2px solid var(--color-main) !important;
  color: var(--color-main);
  font-size: 20px;
  font-weight: 550;
  &:hover {
    opacity: 0.7;
  }
}
.btn-outline-green {
  color: var(--color-tt-course);
  border: 2px solid var(--color-tt-course);
  background: #fff;
  display: flex !important;
  align-items: center;
  &:hover {
    opacity: 0.7;
  }
}
.btn-outline-blue {
  color: var(--color-tt-today);
  border: none;
  background: #fff;
  display: flex !important;
  align-items: center;
  &:hover {
    opacity: 0.7;
  }
}
.btn-outline-purple {
  color: var(--color-tt-block);
  border: 2px solid var(--color-tt-block);
  background: #fff;
  display: flex !important;
  align-items: center;
  &:hover {
    opacity: 0.7;
  }
}
.btn-memo {
  color: var(--color-tt-message);
  border: 2px solid var(--color-tt-message);
  background: #fff;
  display: flex !important;
  align-items: center;
  &:hover {
    opacity: 0.7;
  }
}
.timetable {
  align-items: center;
  margin-bottom: 5px;
  .label {
    padding: 8px 0px;
    margin-right: 4px;
    min-width: 45px;
    width: 45px;
    font-size: 12px;
    text-align: center;
  }
}
.h-38 {
  height: 38px;
}

.supplementary {
  border-radius: 6px;
  border: 1px dashed black;
  font-size: 11px;
  line-height: 13px;
  background-color: #fff;
}

.square-number {
  background-color: var(--color-main);
  min-width: 16px;
  color: #FFFFFF;
  display: inline-block;
  text-align: center;
  font-weight: bold;
}

.notice {
  position: relative;
  height: 40px;
  > .btn {
    padding: 6px 12px;
    margin: 0;
    min-height: 38px;
    font-size: 13px;
    i.fa {
      font-size: 1.2rem;
    }
  }
  .popover {
    position: absolute;
    top: 44px;
    left: -287px;
    min-width: 490px;
    font-size: 12px;

    .arrow {
      left: 300px;
    }
    .list-group-item {
      border: none;
      border-bottom: 1px solid rgba(0, 0, 0, 0.125);
      padding: 0.5rem 0.5rem;
    }
    &.print-dialog {
      left: -157px;
    }
  }
  .popover-body {
    overflow-y: auto;
    max-height: 400px;
  }
  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
  }
  .print-dialog {
    font-size: 13px;
    .form-control {
      font-size: 14px;
    }
    .btn {
      margin: 0;
      font-size: 13px;
    }
  }
}

.print-btn-group {
  align-items: center;
  position: absolute;
  display: flex;
  right: 55px;
  pointer-events: auto;

  .btn {
    padding: 6px 8px;
    font-size: 14px !important;
    margin-left: 8px;
  }
}

.btn.confirm {
  float: right;
}

.btn-primary-confirm {
  background-color: #ffffff;
  border: 1px solid var(--color-main);
  color: #212529;
}
.btn-primary-confirm:hover {
  opacity: 0.7;
}
.modal-dialog {
  z-index: 1050;
  max-width: 560px;
}
.modal-dialog-box{
  max-width: 619px;
}
.btn-today {
  font-size: 16px !important;
}

.unread-notice {
  padding: 4px 12px;
  background: var(--color-error);
  border-radius: 4px;
  font-size: 12px;
  color: #fff;
  margin-top: 4px;
  line-height: 26px;
  i.fa {
    font-size: 1.2rem;
  }
}
.top_title {
  font-size: 20px;
  min-width: 88px;
}
.timetable_span {
  font-weight: 700;

}
.status_body {
  padding-left: 30px;
}
.timetable_header {
  padding-left: 30px;
  align-items: center !important;
  .modal-title {
    font-weight: 600;
    padding-top: 10px;
  }
}
.opacity-unset:hover {
  opacity: unset !important;
}
.annotate_ipad {
  display: none !important;
}

.barge-noti-reservations {
  position: absolute;
  top: 6px;
  right: 6px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background: var(--color-tt-today);
  color: #fff;
  text-align: center;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal {
  background-color: #2125298a;
}
.my-fork{
  background: var(--color-tt-course);
  padding: 2px;
  border-radius: 50%;
}
.my-block{
  background: var(--color-tt-block);
  padding: 2px;
  border-radius: 50%;
}
.my-message{
  background: var(--color-tt-message);
  padding: 2px;
  border-radius: 50%;
}
.my-primary{
  background: var(--color-main);
  padding: 2px;
  border-radius: 50%;
}

.my-memo{
  background: var(--color-tt-message);
  padding: 2px;
  border-radius: 50%;
}
@media screen and (max-width: 1340px) {
  .annotate_ipad {
    display: flex !important;
  }
  .annotate {
    display: none !important;
  }
}
@media screen and (min-width: 996px) and (max-width: 1112px) {
  .supplementary {
    display: none !important;
  }
}
.circle {
  display: inline-block;
  height: 52px;
  width: 52px;
  padding: 6px;
  background-color: #F1EFED;
  border-radius: 50%;
  top:-4px;
}

.icon-fix {
  position: relative;
  max-height: 40px;
  padding: 13px;
  top: -6px;
  margin-right: 10px;
}

.timetable-toggle1{
  display: block;
  text-align: center;
  font-size: 10px;
  color: #999999;
  margin-top: 4px;
  font-weight: bold;
}

.timetable-toggle2{
  display: inline;
  text-align: center;
  font-size: 6px;
  color: #FFFFFF;
  margin-top: 4px;
  font-weight: bold;
}
</style>
